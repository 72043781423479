import { FC } from "react";

import { Box } from "@mui/material";
import { WidgetProps } from "@rjsf/utils";

/**
 * Custom translation widget to nicely display translation language
 */
export const CustomTranslationWidget: FC<React.PropsWithChildren<WidgetProps & { sx?: any }>> =
  function ({ value, sx }) {
    return (
      <Box
        sx={{
          color: (theme) => theme.palette.primary.main,
          border: (theme) => `2px solid ${theme.palette.primary.main}`,
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "1.3em",
          textTransform: "uppercase",
          borderRadius: 2,
          ...sx,
        }}
      >
        {value}
      </Box>
    );
  };
