import { RefObject } from "react";

import { localStorageEffect } from "@keepeek/commons";
import Form, { IChangeEvent } from "@rjsf/core";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { JSONSchema7, JSONSchema7Definition } from "json-schema";
import { atom, atomFamily } from "recoil";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";

/**
 * Form configuration
 */
export type FormConfig = {
  jsonSchema: JSONSchema7 | JSONSchema7[] | JSONSchema7Definition | JSONSchema7Definition[];
  jsonUiSchema: UiSchema;
  jsonData?: any;
  onSave?: (e: any) => void;
  submitComponent?: React.ReactNode;
  onChange?: (e: IChangeEvent) => void;
  formRef?: RefObject<Form<any, RJSFSchema, any>>;
};

export const collapseAdminState = atomFamily<boolean, string>({
  key: "collapseAdminState",
  default: false,
  effects: (key) => [localStorageEffect(`CollapseAdminState-${key}`)],
});

export const formConfigAdminState = atomFamily<any, CONFIGURATION_SECTION_PATH>({
  key: "FormConfigAdminState",
  default: undefined,
});

export const dataChangeState = atom<boolean>({
  key: "DataChangeState",
  default: false,
});

export type ApiStoreType = {
  [key: string]: string;
};

export const apiStoreAtom = atom<ApiStoreType>({
  key: "ApiStoreAtom",
  default: {},
});
