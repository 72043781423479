import { JsonConfig } from "../../../lib/commonPagePropsUtil";
import logger from "../../../lib/logger-utils";
import { Template } from "../../../models/configuration/definitions/template";
import { Type, Widget } from "../../../models/configuration/definitions/widget";
import { getAssociationsWidgetConfiguration } from "../AssociationsWidget/utils";
import { getBasketsWidgetConfiguration } from "../BasketsWidget/utils";
import { getEditoWidgetConfiguration } from "../EditoWidget/utils";
import { getFooterWidgetConfiguration } from "../KFooter/utils";
import { getKHeaderWidgetConfiguration } from "../KHeaderWidget/utils";
import { getKImageWidgetConfiguration } from "../KImageWidget/utils";
import { getMenuConfiguration } from "../KMenuWidget/utils";
import { getMosaicConfiguration } from "../KMosaicWidget/utils";
import { getNewsWidgetConfiguration } from "../KNewsWidget/utils";
import { getReportConfiguration } from "../KReportWidget/utils";
import { getSearchWidgetConfiguration } from "../KSearchWidget/utils";
import { getSectionTitleWidgetConfiguration } from "../KSectionTitleWidget/utils";
import { getTabsWidgetConfiguration } from "../KTabsWidget/utils";
import { getKVideoWidgetConfiguration } from "../KVideoWidget/utils";
import { WidgetConfiguration, WidgetConfigurations } from "../models";
import { getSpacerWidgetConfiguration } from "../SpacerWidget/utils";
import { getWidgetKey } from "./getWidgetKey";
import { getWidgetsByTemplates } from "./getWidgetsByTemplates";

const widgetConfigMap: Record<
  Type,
  (configSections: JsonConfig[], widgetId: string) => WidgetConfiguration | undefined
> = {
  [Type.Mosaic]: getMosaicConfiguration,
  [Type.Search]: getSearchWidgetConfiguration,
  [Type.Footer]: getFooterWidgetConfiguration,
  [Type.Attachments]: () => ({ type: Type.Attachments }),
  [Type.Sheet]: () => ({ type: Type.Sheet }),
  [Type.Custom]: () => ({ type: Type.Custom }),
  [Type.Tabs]: getTabsWidgetConfiguration,
  [Type.Image]: getKImageWidgetConfiguration,
  [Type.Edito]: getEditoWidgetConfiguration,
  [Type.Header]: getKHeaderWidgetConfiguration,
  [Type.Associations]: getAssociationsWidgetConfiguration,
  [Type.Spacer]: getSpacerWidgetConfiguration,
  [Type.Video]: getKVideoWidgetConfiguration,
  [Type.News]: getNewsWidgetConfiguration,
  [Type.SectionTitle]: getSectionTitleWidgetConfiguration,
  [Type.Menu]: getMenuConfiguration,
  [Type.Report]: getReportConfiguration,
  [Type.Baskets]: getBasketsWidgetConfiguration,
};

export const getWidgetConfiguration = ({
  widget,
  configSections,
}: {
  widget: Widget;
  configSections: JsonConfig[];
}): WidgetConfiguration | undefined => {
  const configFunction = widgetConfigMap[widget.type];

  if (configFunction) {
    return configFunction(configSections, widget.id);
  }

  logger.error(`[getWidgetConfiguration] Widget of type ${widget.type} not implemented`);
  return undefined;
};

export function getWidgetConfigurationsByTemplates({
  configSections,
  templates,
}: {
  configSections?: JsonConfig[];
  templates?: Template[];
}): WidgetConfigurations {
  const ret: WidgetConfigurations = {};

  if (!configSections || !templates) {
    return ret;
  }

  const widgets = getWidgetsByTemplates(templates);

  for (const widget of widgets) {
    const widgetKey = getWidgetKey(widget);
    const widgetConfig = getWidgetConfiguration({ widget, configSections });

    if (widgetKey && widgetConfig) {
      ret[widgetKey] = widgetConfig;
    }
  }

  return ret;
}
