import React, { FC } from "react";

import { KButton } from "@keepeek/refront-components";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ResourceType } from "../../utils";
import ImageResource from "../ImageResource";
import UnknownResource from "../UnknownResource";
import VideoResource from "../VideoResource";

const ResourceRenderer: FC<
  React.PropsWithChildren<{
    resourceType: ResourceType;
    resourceUrl: string;
    onResourceRemove: () => void;
    hideResourceRemoveButton?: boolean;
  }>
> = function ({ resourceType, resourceUrl, onResourceRemove, hideResourceRemoveButton = false }) {
  if (!resourceUrl) {
    return null;
  }
  switch (resourceType) {
    case ResourceType.LOGO:
    case ResourceType.IMAGE:
      return (
        <Wrapper
          resourceUrl={resourceUrl}
          onResourceRemove={onResourceRemove}
          hideResourceRemoveButton={hideResourceRemoveButton}
        >
          <ImageResource resourceUrl={resourceUrl} />
        </Wrapper>
      );
    case ResourceType.VIDEO:
      return (
        <Wrapper
          resourceUrl={resourceUrl}
          onResourceRemove={onResourceRemove}
          hideResourceRemoveButton={hideResourceRemoveButton}
        >
          <VideoResource resourceUrl={resourceUrl} />
        </Wrapper>
      );
    default:
      return (
        <Wrapper
          resourceUrl={resourceUrl}
          onResourceRemove={onResourceRemove}
          hideResourceRemoveButton={hideResourceRemoveButton}
        >
          <UnknownResource resourceUrl={resourceUrl} />
        </Wrapper>
      );
  }
};
export default ResourceRenderer;

const Wrapper: FC<
  React.PropsWithChildren<{
    resourceUrl: string;
    onResourceRemove: () => void;
    hideResourceRemoveButton: boolean;
  }>
> = function ({ children, resourceUrl, onResourceRemove, hideResourceRemoveButton }) {
  const { t } = useTranslation();
  if (!resourceUrl) {
    return null;
  }
  return (
    <>
      <Box>{children}</Box>
      {!hideResourceRemoveButton && (
        <KButton variant="outlined" onClick={onResourceRemove}>
          {t("admin:resource.url.remove")}
        </KButton>
      )}
    </>
  );
};
