import { FunctionOverrideKey } from "@keepeek/commons";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import logger from "../../../lib/logger-utils";
import { getCustomerOverrideFunction } from "../../../lib/overrides";
import { Override } from "../../../models/configuration/override";
import { useConfiguration } from "../../config/hooks";

export const useInitOverride = () => {
  const overrides = useConfiguration<Override[]>(CONFIGURATION_SECTION_PATH.OVERRIDE);
  if (overrides?.length !== 0 && overrides?.length !== undefined) {
    logger.debug(`Json Config Section "override": ${JSON.stringify(overrides)}`);
    const sendOverridesToCustomer = getCustomerOverrideFunction(
      FunctionOverrideKey.OverrideJsonConfig,
      { jsonConfigSection: overrides },
    );
    if (sendOverridesToCustomer) {
      logger.debug(`Json Config Section "override" is injected`);
    }
  }
};
