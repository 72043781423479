import React, { FC, useCallback } from "react";

import {
  FIRST_LOGIN_TYPE,
  FIRST_LOGIN_TYPE_QUERY_PARAM,
  isPassedGetDamState,
  KCConsumerStatus,
  useFirstLoginType,
} from "@keepeek/commons";
import { useRouter } from "next/router";
import { useRecoilValue } from "recoil";

import { useAuthProvider } from "../../providers/auth/hooks/useAuthProvider";
import { useAuthRedirectUri } from "../../providers/auth/hooks/useAuthRedirectUri";
import LoadingAuth from "../auth/LoadingAuth";
import { PagesPath } from "./utils";

const AuthProvider: FC<React.PropsWithChildren<unknown>> = function ({ children }) {
  // init Keycloak configuration
  const { pathname, replace, query } = useRouter();

  const { redirectUri, authorizedQueryParams } = useAuthRedirectUri();

  const isPassedGetDam = useRecoilValue(isPassedGetDamState);
  const { type } = useFirstLoginType({ isPassedGetDam, query });

  const firstLoginCallback = useCallback(() => {
    const queryParams = new URLSearchParams(authorizedQueryParams);
    const firstLoginParam =
      queryParams.get(FIRST_LOGIN_TYPE_QUERY_PARAM) || isPassedGetDam || FIRST_LOGIN_TYPE.ERROR;

    replace(`${PagesPath.FIRST_LOGIN}?${FIRST_LOGIN_TYPE_QUERY_PARAM}=${firstLoginParam}`);
  }, [authorizedQueryParams, isPassedGetDam, replace]);

  const { loading, status } = useAuthProvider({
    pathname,
    type,
    unauthorizedCallback: () => replace(redirectUri),
    firstLoginCallback,
  });

  if (loading || status !== KCConsumerStatus.Authorized) {
    return <LoadingAuth forceShow />;
  }

  return <>{children}</>;
};

export default AuthProvider;
