import { useEffect, useMemo } from "react";

import { IDidomiObject, IDidomiSDKProps } from "@didomi/react";
import { FunctionOverrideKey } from "@keepeek/commons";
import { useTheme } from "@mui/material";
import { colord } from "colord";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { getCustomerOverrideFunction } from "../../../lib/overrides";
import { Global } from "../../../models/configuration/global";
import { useConfiguration } from "../../config/hooks";
import { usePublicRuntimeConfig } from "../../config/hooks/usePublicRuntimeConfig";
import { didomiCWTState, didomiNoticeVisible, didomiState, needDidomiState } from "./atoms";
import { DIDOMI_API_KEY, DidomiNotice } from "./utils";

type UseDidomi = {
  CWT?: any;
  didomi?: IDidomiObject;
  noticeVisible: boolean;
  needDidomi: boolean;
  isCustomNoticeId: boolean;
} & IDidomiSDKProps;

export const useDidomi = (): UseDidomi => {
  // Init
  const theme = useTheme();
  const { t } = useTranslation();
  // Recoil
  const [needDidomi, setNeedDidomi] = useRecoilState(needDidomiState);
  const [didomi, setDidomi] = useRecoilState(didomiState);
  const [CWT, setCWT] = useRecoilState(didomiCWTState);
  const [noticeVisible, setNoticeVisible] = useRecoilState(didomiNoticeVisible);
  const global = useConfiguration<Global>(CONFIGURATION_SECTION_PATH.GLOBAL);
  const { config: runtimeConfig } = usePublicRuntimeConfig();

  // Evaluate the need of Didomi
  useEffect(() => {
    setNeedDidomi(() => {
      const needDidomiFromCustomerOverride = getCustomerOverrideFunction(
        FunctionOverrideKey.NeedDidomi,
        {
          googleAnalyticsId: global?.googleAnalyticsId,
          sentryDeactivated: global?.sentry?.deactivated,
          didomiApiKey: runtimeConfig?.didomi?.apiKey,
          didomiApiNoticeId: runtimeConfig?.didomi?.noticeId,
        },
      );

      if (needDidomiFromCustomerOverride !== undefined) {
        return needDidomiFromCustomerOverride;
      }

      // If the api key is not ours, we assume that the ReFront need it
      if (runtimeConfig?.didomi?.apiKey !== DIDOMI_API_KEY) {
        return true;
      }
      // If there's a notice ID, we assume the ReFront need it
      if (runtimeConfig?.didomi?.noticeId) {
        return true;
      }
      // If sentry is not deactivated
      if (global?.sentry?.deactivated !== undefined && !global.sentry.deactivated) {
        return true;
      }
      // If we have a google analytics code
      if (global?.googleAnalyticsId) {
        return true;
      }
      return false;
    });
  }, [
    global?.googleAnalyticsId,
    global?.sentry?.deactivated,
    runtimeConfig?.didomi?.apiKey,
    runtimeConfig?.didomi?.noticeId,
    setNeedDidomi,
  ]);

  const noticeIdRules = (): IDidomiSDKProps["noticeId"] => {
    const sentryActivated = !(global?.sentry?.deactivated === true);
    const gaActivated = !!global?.googleAnalyticsId;
    const envNoticeId = runtimeConfig?.didomi.noticeId;
    const customNoticeId = global?.didomi?.noticeId;

    // take the one from admin before all
    if (customNoticeId) {
      return customNoticeId;
    }
    // then the one from env
    if (envNoticeId) {
      return envNoticeId;
    }
    // Do the rest with a lil' bit of logic
    if (sentryActivated && gaActivated) {
      return DidomiNotice.SentryAndGoogleAnalytics;
    }
    if (sentryActivated) {
      return DidomiNotice.Sentry;
    }
    if (gaActivated) {
      return DidomiNotice.GoogleAnalytics;
    }
  };

  // Map functions
  // https://github.com/didomi/react
  const onReady = setDidomi;
  const onConsentChanged = setCWT;
  const onNoticeShown = () => setNoticeVisible(true);
  const onNoticeHidden = () => setNoticeVisible(false);

  // Configure Didomi
  // https://developers.didomi.io/cmp/web-sdk/getting-started
  const gdprAppliesGlobally: IDidomiSDKProps["gdprAppliesGlobally"] = true;
  const iabVersion: IDidomiSDKProps["iabVersion"] = 2;
  const apiKey: IDidomiSDKProps["apiKey"] = needDidomi
    ? runtimeConfig?.didomi?.apiKey ?? DIDOMI_API_KEY
    : undefined;
  const noticeId: IDidomiSDKProps["noticeId"] = noticeIdRules();

  const isCustomNoticeId = !!(runtimeConfig?.didomi.noticeId || global?.didomi?.noticeId);

  // Do not define a config if we're using a custom notice id
  // This way the CP can configure everything from Didomi dashboard
  const config: IDidomiSDKProps["config"] = useMemo(() => {
    return isCustomNoticeId
      ? undefined
      : {
          // https://developers.didomi.io/cmp/web-sdk/consent-notice/customize-theme
          theme: {
            color: colord(theme.palette.primary.main).alpha(1).toHex(),
            linkColor: colord(theme.palette.primary.main).alpha(1).toHex(),
            font: theme.typography.fontFamily,
          },
          // https://developers.didomi.io/cmp/web-sdk/consent-notice/preferences
          preferences: {
            content: {
              title: {
                en: t("app.title"),
                fr: t("app.title"),
              },
            },
          },
        };
  }, [isCustomNoticeId, t, theme.palette.primary.main, theme.typography.fontFamily]);

  return {
    didomi,
    CWT,
    onReady,
    onConsentChanged,
    onNoticeShown,
    onNoticeHidden,
    gdprAppliesGlobally,
    iabVersion,
    noticeVisible,
    apiKey,
    noticeId,
    needDidomi,
    isCustomNoticeId,
    config,
  };
};
