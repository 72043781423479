import { useCallback, useEffect } from "react";

import { useAuthLogout } from "@keepeek/commons";
import { KButton, NotificationDuration, useNotificationsCenter } from "@keepeek/refront-components";
import { useTranslation } from "react-i18next";

import { usePublicRuntimeConfig } from "../../config/hooks/usePublicRuntimeConfig";
import { getWaitMsBeforeDisplayAlert, isAlertMessageEnabled } from "../utils";

/**
 * Displays an alert message if the waiting time for authentication exceeds a certain number of seconds. The user can reconnect via this alert message.
 */
export const useAuthLoadingAlert = () => {
  const { config, loading } = usePublicRuntimeConfig();
  const { push, dismiss } = useNotificationsCenter();
  const { logout } = useAuthLogout();
  const { t } = useTranslation();
  const handleLogout = useCallback(
    (notificationId: string) => {
      dismiss(notificationId);
      logout();
    },
    [dismiss, logout],
  );

  useEffect(() => {
    const waitMsBeforeDisplayAlert = getWaitMsBeforeDisplayAlert(
      loading,
      config?.keycloak.waitMsBeforeDisplayAlert,
    );
    let delaysDetectedTimer;
    let notificationId;
    if (isAlertMessageEnabled(waitMsBeforeDisplayAlert)) {
      delaysDetectedTimer = setTimeout(() => {
        notificationId = push({
          content: t("loading-auth.delays-detected.reconnect.content"),
          actions: (
            <KButton onClick={() => handleLogout(notificationId)} size="small" variant="contained">
              {t("loading-auth.delays-detected.reconnect.button")}
            </KButton>
          ),
          duration: NotificationDuration.Persist,
        });
      }, waitMsBeforeDisplayAlert);
    }
    return () => {
      if (isAlertMessageEnabled(waitMsBeforeDisplayAlert)) {
        if (delaysDetectedTimer) {
          clearTimeout(delaysDetectedTimer);
        }
        if (notificationId) {
          dismiss(notificationId);
        }
      }
    };
  }, [config?.keycloak.waitMsBeforeDisplayAlert, dismiss, handleLogout, loading, push, t]);
};
