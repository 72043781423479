import React, { FC } from "react";

import { FieldProps } from "@rjsf/utils";
import { JSONSchema7Definition } from "json-schema";
import { pick } from "lodash";

import { BackgroundType } from "../../../../models/configuration/global";

const CustomGdprBackground: FC<React.PropsWithChildren<FieldProps>> = function (props: FieldProps) {
  const { ObjectField } = props.registry.fields;

  const backgroundType = props.formData.type;

  const imageVideoSchema = {
    ...props.schema,
    properties: pick(props.schema.properties, "type", "url"),
  };

  const colorSchema = {
    ...props.schema,
    properties: pick(props.schema.properties, "type", "color"),
  };

  const inheritSchema = {
    ...props.schema,
    properties: pick(props.schema.properties, "type"),
  };

  if (backgroundType === BackgroundType.Image || backgroundType === BackgroundType.Video) {
    return (
      <ObjectField
        {...props}
        schema={imageVideoSchema as { [key: string]: JSONSchema7Definition }}
      />
    );
  } else if (backgroundType === BackgroundType.Color) {
    return (
      <ObjectField {...props} schema={colorSchema as { [key: string]: JSONSchema7Definition }} />
    );
  } else {
    return (
      <ObjectField {...props} schema={inheritSchema as { [key: string]: JSONSchema7Definition }} />
    );
  }
};

export default CustomGdprBackground;
