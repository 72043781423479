export interface Labels {
    description?: string;
    id?:          string;
    key:          string;
    namespace:    Namespace;
    translation:  TranslationSchema[];
}

export enum Namespace {
    Admin = "admin",
    Common = "common",
    RefrontComponents = "refront-components",
}

export interface TranslationSchema {
    empty?:      boolean;
    language:    Language;
    ressources?: any;
    value?:      string;
}

export enum Language {
    De = "de",
    En = "en",
    Es = "es",
    Fr = "fr",
    Hu = "hu",
    It = "it",
    Nl = "nl",
    Pl = "pl",
    Pt = "pt",
    Ro = "ro",
}
