import React, { useState } from "react";

import { Translation } from "@keepeek/commons";
import {
  Alert,
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Field } from "@rjsf/utils";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { SetterOrUpdater } from "recoil";

import { useUILocals } from "../../../../providers/config/hooks/useUILocals";
import CustomSelectResource from "../CustomSelectResource";
import CustomWysiwyg from "../CustomWysiwyg";

import "flag-icons/css/flag-icons.min.css";

const languages = [
  { language: "fr", label: "Français", flagCode: "fr" },
  { language: "en", label: "English", flagCode: "gb-eng" },
  { language: "de", label: "Deutsch", flagCode: "de" },
  { language: "it", label: "Italiano", flagCode: "it" },
  { language: "es", label: "Español", flagCode: "es" },
  { language: "nl", label: "Nederlands", flagCode: "nl" },
  { language: "hu", label: "Magyar", flagCode: "hu" },
  { language: "pt", label: "Português", flagCode: "pt" },
  { language: "ro", label: "Română", flagCode: "ro" },
  { language: "pl", label: "Polski", flagCode: "pl" },
];

type SupportedLanguage = {
  language: string;
  label: string | undefined;
  flagCode: string | undefined;
  items: any;
};

type UseTranslation = {
  supportedLanguages: SupportedLanguage[];
  currentLanguage: string;
  setCurrentLanguage: SetterOrUpdater<string>;
  items: any;
  setItems: (value: any) => void;
};

export const useMultiLingue = (onChange: (value: any[]) => void, value?: any): UseTranslation => {
  // Setup
  const uiLocals = useUILocals();
  const [init, setInit] = useState(false);
  const [supportedLanguages, setSupportedLanguages] = useState<SupportedLanguage[]>([]);
  const [currentLanguage, setCurrentLanguage] = useState("fr");

  if (!init) {
    const filteredLanguages = languages.filter((language) =>
      uiLocals.find((uiLocal) => uiLocal === language.language),
    );
    setSupportedLanguages(
      filteredLanguages.map((language) => {
        const translation = value?.find((trad: Translation) => language.language === trad.language);
        return {
          language: language.language,
          label: language?.label,
          flagCode: language?.flagCode,
          items: translation,
        };
      }),
    );
    setInit(true);
  }

  const onChangeWidgetValue = (result: SupportedLanguage[]) => {
    setSupportedLanguages(result);
    onChange(result.filter((item) => item.items !== undefined).map((item) => item.items));
  };

  const setItems = (value: any) => {
    const result = supportedLanguages.map((item) =>
      item.language === currentLanguage ? { ...item, items: value } : item,
    );
    onChangeWidgetValue(result);
  };

  const current = supportedLanguages.find((language) => language.language === currentLanguage);
  const items = current?.items;

  return {
    supportedLanguages,
    currentLanguage,
    setCurrentLanguage,
    items,
    setItems,
  };
};

/**
 * Custom field to handle fields for multiple languages
 * Example 1:
 *     "text": {
 *       "ui:field": "customMultilingualField",
 *       "items": {
 *         "value": {
 *           "ui:widget": "wysiwyg"
 *         }
 *       }
 *     }
 *
 * Example 2:
 *       "alt": {
 *         "ui:field": "customMultilingualField",
 *         "items": {
 *           "empty": {
 *             "ui:widget": "hidden"
 *           },
 *           "value": {
 *             "ui:widget": "textarea",
 *             "ui:options": {
 *               "rows": 1
 *             }
 *           }
 *         }
 *       }
 *
 * Example 3:
 *      "url": {
 *         "ui:field": "customMultilingualField",
 *         "items": {
 *           "resource": {
 *             "ui:field": "resourceSelect",
 *             "ui:options": {
 *               "type": "image"
 *             }
 *           }
 *         }
 */
const CustomMultilingualField: Field = function (props) {
  const { schema, uiSchema, options, registry, onChange, formData, idSchema } = props;
  const { t } = useTranslation("admin");
  const { supportedLanguages, currentLanguage, setCurrentLanguage, items, setItems } =
    useMultiLingue(onChange, formData);

  if (uiSchema === undefined || uiSchema["items"] === undefined) {
    return null;
  }
  const uiSchemaContent = uiSchema["items"];

  const hideEmptyField =
    uiSchemaContent["empty"] && uiSchemaContent["empty"]["ui:widget"] === "hidden";

  let childrenForm;
  if (
    uiSchemaContent["value"] &&
    (uiSchemaContent["value"]["ui:widget"] === "wysiwyg" ||
      uiSchemaContent["value"]["ui:widget"] === "textarea")
  ) {
    childrenForm = (
      <Box>
        <Box hidden={hideEmptyField}>
          {uiSchemaContent["empty"] &&
            uiSchemaContent["empty"]["ui:field"] === "customEmptyLabelField" &&
            items?.empty && (
              <Alert severity="info" style={{ marginBottom: "10px" }}>
                {t("labels.empty.admin.label")}
              </Alert>
            )}
          {uiSchemaContent["empty"] &&
            uiSchemaContent["empty"]["ui:field"] !== "customEmptyLabelField" &&
            uiSchemaContent["empty"]["ui:field"] !== "hidden" && (
              <FormControlLabel
                sx={{ marginBottom: ".5rem" }}
                control={
                  <Checkbox
                    checked={items?.empty ?? false}
                    onChange={(e) => {
                      setItems({ ...items, language: currentLanguage, empty: e.target.checked });
                    }}
                  />
                }
                label={t("labels.empty.label") as string}
              />
            )}
        </Box>
        <Box sx={{ minHeight: "100px" }}>
          {uiSchemaContent["value"] && uiSchemaContent["value"]["ui:widget"] === "textarea" && (
            <TextField
              multiline
              rows={uiSchemaContent["value"]["ui:options"]?.rows ?? 3}
              style={{ width: "100%" }}
              label={t("labels.value.label") as string}
              value={items?.value ?? ""}
              onChange={(event) => {
                setItems({ ...items, language: currentLanguage, value: event.target.value });
              }}
            />
          )}
          {(!uiSchemaContent["value"] || uiSchemaContent["value"]["ui:widget"] === "wysiwyg") && (
            <CustomWysiwyg
              id={`CustomMultilingualField-CustomWysiwyg-${currentLanguage}`}
              name={`CustomWysiwyg-${currentLanguage}`}
              options={options}
              onBlur={() => {}}
              onFocus={() => {}}
              registry={registry}
              label={t("labels.value.label") as string}
              schema={schema}
              value={items?.value ?? ""}
              language={currentLanguage}
              onChange={(value) => {
                setItems({ ...items, language: currentLanguage, value: value });
              }}
              activeBase64image={false}
              minHeight={uiSchemaContent["value"]["ui:options"]?.minHeight}
            />
          )}
        </Box>
      </Box>
    );
  } else if (uiSchemaContent["resource"]) {
    childrenForm = (
      <CustomSelectResource
        id={`CustomMultilingualField-CustomSelectResource-${currentLanguage}`}
        name={`CustomSelectResource-${currentLanguage}`}
        idSchema={idSchema}
        schema={schema}
        uiSchema={uiSchemaContent["resource"]}
        formData={items?.resource ?? {}}
        onBlur={() => {}}
        onFocus={() => {}}
        registry={registry}
        readonly={false}
        disabled={false}
        onChange={(resource) => {
          if (!resource || resource.url === "") {
            setItems(undefined);
          } else {
            setItems({ language: currentLanguage, resource: resource });
          }
        }}
      />
    );
  } else if (uiSchemaContent["links"]) {
    if (
      uiSchemaContent["links"]["items"]["ui:field"] === "customMenuLinksField" ||
      uiSchemaContent["links"]["items"]["ui:field"] === "customFooterLinksField"
    ) {
      const { ArrayField } = registry.fields;
      childrenForm = (
        <ArrayField
          {...props}
          onChange={(formData) => {
            const arrayWithoutCurrentLocale = props.formData.filter(
              (f) => f.locale !== currentLanguage,
            );
            props.onChange([
              ...arrayWithoutCurrentLocale,
              {
                locale: currentLanguage,
                links: formData,
              },
            ]);
          }}
          formData={props.formData.find((f) => f.locale === currentLanguage)?.links}
          schema={props?.schema?.items?.["properties"]?.links}
          uiSchema={props?.uiSchema?.items?.links}
        />
      );
    }
  } else {
    childrenForm = (
      <TextField
        style={{ width: "100%" }}
        label={t("labels.value.label") as string}
        value={items?.value ?? ""}
        onChange={(event) => {
          setItems({ ...items, language: currentLanguage, value: event.target.value });
        }}
      />
    );
  }

  return (
    <Container component={Paper} sx={{ padding: 1 }} maxWidth={false}>
      <Typography variant="h6">{schema.title ? t(schema.title) : ""}</Typography>

      <FormControl sx={{ width: "100%" }}>
        <InputLabel
          id="LabelWithLangSelector-SelectLabel"
          className={clsx("LabelWithLangSelector-SelectLabel")}
        ></InputLabel>
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={currentLanguage}
          exclusive
          onChange={(_, value: string) => {
            if (value) {
              setCurrentLanguage(value);
            }
          }}
          sx={{ paddingBottom: "10px", flexWrap: "wrap" }}
        >
          {supportedLanguages &&
            supportedLanguages.map((lang) => (
              <ToggleButton
                key={lang.language}
                value={lang.language}
                sx={{ borderLeft: (theme) => `1px solid ${theme.palette.grey[300]} !important` }}
              >
                <span className={`fi fi-${lang.flagCode}`}></span>
                <span style={{ paddingLeft: "10px" }}>{lang.label}</span>
              </ToggleButton>
            ))}
        </ToggleButtonGroup>
        {childrenForm}
      </FormControl>
    </Container>
  );
};

export default CustomMultilingualField;
