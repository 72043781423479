import React, { FC } from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

import { LinkType } from "../../../../../models/configuration/components/footer";

const LinkTypeSelector: FC<
  React.PropsWithChildren<{
    type: LinkType;
    frontEdit: boolean;
    onChange: (data: LinkType) => void;
    allowedLinkTypes: LinkType[];
  }>
> = function ({ type, allowedLinkTypes, frontEdit, onChange }) {
  const { t } = useTranslation("admin");
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel>{t("locale.links.linkType.label")}</InputLabel>
      <Select<LinkType>
        label={t("locale.links.linkType.label")}
        value={type}
        disabled={frontEdit && type === LinkType.Didomi}
        onChange={(event) => onChange(event.target.value as LinkType)}
      >
        {allowedLinkTypes.map((linkType) => (
          <MenuItem
            selected={linkType?.toString() === type}
            key={linkType.toString()}
            value={linkType.toString()}
            disabled={frontEdit && linkType === LinkType.Didomi}
          >
            {linkType === LinkType.Mail ? "EMAIL" : linkType.toString()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LinkTypeSelector;
