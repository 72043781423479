import { useEffect } from "react";

import { shareManagerConfiguration } from "@keepeek/commons";
import { useRecoilValue, useSetRecoilState } from "recoil";

import {
  configSectionComponentDownloadManagerSelector,
  configSectionShareManagerSelector,
} from "../selectors";

export const useInitShareManagerConfiguration = () => {
  const setShareManagerConfiguration = useSetRecoilState(shareManagerConfiguration);
  const getConfigSectionComponentBusinessFilter = useRecoilValue(configSectionShareManagerSelector);
  const configDownloadManager = useRecoilValue(configSectionComponentDownloadManagerSelector);
  useEffect(() => {
    setShareManagerConfiguration({
      unitaryPublicShare: {
        active: getConfigSectionComponentBusinessFilter?.unitaryPublicShare?.active,
      },
      enableAskDownload: configDownloadManager?.enableAskDownload,
    });
  }, [
    getConfigSectionComponentBusinessFilter?.unitaryPublicShare?.active,
    setShareManagerConfiguration,
  ]);
};
