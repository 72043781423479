import React, { FC } from "react";

import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import useFrontEditContentEditable from "../../providers/frontEdit/hooks/useFrontEditContentEditable";
import { EditName } from "../../providers/frontEdit/models";
import EditableSanitizeHtml from "./EditableSanitizeHtml";

const FrontEditGeneralSettingsEdition: FC<React.PropsWithChildren<unknown>> = function () {
  const { sectionsEditable } = useFrontEditContentEditable(EditName.THEME);
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5" component="div">
        {t("app.title.label")}
      </Typography>
      <Divider />
      <Box sx={{ margin: (theme) => theme.spacing(1) }}>
        <EditableSanitizeHtml labelKey={"app.title"} />
      </Box>
      {sectionsEditable.map((se) => se.editableContents.map((ec) => ec))}
    </>
  );
};

export default FrontEditGeneralSettingsEdition;
