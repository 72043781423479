import React from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Box, useTheme } from "@mui/material";
import clsx from "clsx";

import { TabsWidget } from "../../../../../models/configuration/components/tabsWidget";
import { useCurrentLocale } from "../../../../../providers/i18n/hooks";

const WidgetCardSelectorTabs = function ({
  testId = "WidgetCardSelectorTabs",
  className,
  tabs,
  id,
}: TabsWidget & BaseComponentProps) {
  const theme = useTheme();
  const locale = useCurrentLocale();
  return (
    <Box
      className={clsx("WidgetCardSelectorTabs", className)}
      data-testid={testId}
      title={id}
      style={{
        padding: theme.spacing(1),
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        flexDirection: "row",
      }}
    >
      {tabs.map((t, i) => (
        <Box
          style={{
            background: theme.palette.primary.main,
            padding: theme.spacing(1),
            color: "white",
            fontWeight: "bold",
            borderRadius: theme.shape.borderRadius,
          }}
          key={i}
        >
          {t.translation?.find((tr) => tr.language === locale)?.value ?? ""}
        </Box>
      ))}
    </Box>
  );
};

export default WidgetCardSelectorTabs;
