import { Customer } from "@keepeek/refront-customers";
// @ts-ignore : webpack alias. See "next.config.js"
import { config } from "CustomerConfig";

import { CONFIGURATION_SECTION_PATH } from "../../components/layout/adminMenu";
import { JsonConfig } from "../commonPagePropsUtil";

/**
 * Returns the client ID to use when loading overrides and configuration.
 *
 * Server side only.
 **/
export function getCustomerId(): string {
  if (!process.env.NEXT_PUBLIC_CUSTOMER_ID) {
    throw new Error(
      "Customer ID is undefined. check .env and/or .env.local files. This function cannot be used client side.",
    );
  }
  return process.env.NEXT_PUBLIC_CUSTOMER_ID;
}

/**
 * Returns the configuration for the instance customer.
 *
 * The result of this function must be serializable because it'll be included in getServerSideProps result.
 */
export function getCustomerConfig(): Customer {
  return config;
}

/**
 * Returns a specific customer section configuration.
 * @param jsonConfig customer sections configuration
 * @param configSectionName name of customer section to return
 * @return JsonConfig[] or null
 */
export const getAdminConfigSectionData = <T = any>(
  jsonConfig: JsonConfig[] | null,
  configSectionName?: CONFIGURATION_SECTION_PATH,
): T | null =>
  (jsonConfig?.find(({ configSection }) => configSection === configSectionName)?.jsonData as T) ??
  null;
