import cloneDeep from "lodash/cloneDeep";

import { TreeLeafsProps } from "./TreeLeafs";
import { TreeEditOption } from "./types";

/**
 * Update items to minimize number of renders
 * @param items items to update
 * @param pathEdit path to update, representing nested array indexes ([0, 2] mean parent[0].children[2])
 * @param onChange trigger change callback
 * @param updateFn function to perform an update on items
 * @returns updated items
 */
export function updateItems<T>(
  items: TreeEditOption<T>[],
  pathEdit: TreeLeafsProps<T>["pathEdit"],
  updateFn: (items: TreeEditOption<T>[]) => TreeEditOption<T>[],
  onChange?: (items: TreeEditOption<T>[]) => void,
): TreeEditOption<T>[] {
  let result;
  if (pathEdit.length > 0) {
    // Updating childs
    let deepLevel = 0;
    let tab = items;
    // Clone first level item to render all childs
    tab[pathEdit[0]] = cloneDeep(tab[pathEdit[0]]);

    for (const path of pathEdit) {
      const parentItem = tab[path];
      if (parentItem && parentItem.children) {
        tab = parentItem.children;
        deepLevel++;
        if (deepLevel === pathEdit.length) {
          // Call update function on path to update
          parentItem.children = updateFn(parentItem.children);
        }
      }
    }

    result = items;
  } else {
    result = updateFn(items);
  }
  // Build a new array to trigger a top level render
  result = [...result];

  // Trigger component callback
  onChange && onChange(result);
  return result;
}
