import React, { ComponentType, Dispatch, SetStateAction, useRef } from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Box, IconProps } from "@mui/material";
import clsx from "clsx";
import type { Identifier, XYCoord } from "dnd-core";
import cloneDeep from "lodash/cloneDeep";
import { ConnectableElement, useDrag, useDrop } from "react-dnd";

import { TreeLeafsProps } from "./TreeLeafs";
import { genericMemo, TreeEditOption } from "./types";
import { updateItems } from "./util";

import { TreeEditProps } from ".";

export type TreeItemProps<T> = {
  onDragStart?: TreeEditProps<T>["onDragStart"];
  onDragEnd?: TreeEditProps<T>["onDragEnd"];
  KIcon: ComponentType<{} & IconProps>;
  index: number;
  item: TreeEditOption<T>;
  pathEdit: TreeLeafsProps<T>["pathEdit"];
  setNewItems: Dispatch<SetStateAction<TreeEditOption<T>[]>>;
} & BaseComponentProps;

/**
 * Add an index for drag and drop
 */
type TreeEditOptionWithIndex<T> = {
  index: number;
} & TreeEditOption<T>;

/**
 * Tree item with drag and drop capabilities
 * Code from https://react-dnd.github.io/react-dnd/examples/sortable/simple
 */
export const TreeItem = genericMemo(function TreeItem<T>({
  id,
  className,
  sx,
  testId = "TreeItem",
  children,
  onDragStart,
  onDragEnd,
  KIcon,
  index,
  item,
  pathEdit,
  setNewItems,
}: React.PropsWithChildren<TreeItemProps<T>>) {
  const ref = useRef<HTMLDivElement>(null);
  const context = `tree${pathEdit.map((path) => `-${path}`)}`;
  const [{ handlerId }, drop] = useDrop<
    TreeEditOptionWithIndex<T>,
    void,
    { handlerId: Identifier | null }
  >({
    accept: context,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: TreeEditOptionWithIndex<T>, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      setNewItems((items: TreeEditOption<T>[]) => {
        const newItems = updateItems(items, pathEdit, (items) => {
          // Clone here to avoid troubles with dev mode executing twice updateItems function
          const itemsClone = cloneDeep(items);
          itemsClone.splice(hoverIndex, 0, itemsClone.splice(dragIndex, 1)[0]);
          return itemsClone;
        });
        return newItems;
      });

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: context,
    item: () => {
      return { id: item.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drop(preview(ref as ConnectableElement));

  return (
    <Box
      className={clsx("TreeItem", className)}
      id={id}
      data-testid={testId}
      sx={{ display: "flex", alignItems: "center", opacity, mb: 0.5, ...sx }}
      ref={ref}
      data-handler-id={handlerId}
      onDragStart={() => {
        // This will be triggered for tree hierarchy
        onDragStart && onDragStart(item);
      }}
      onDragEnd={() => {
        onDragEnd && onDragEnd(item);
      }}
    >
      <Box
        ref={(node) => drag(node as ConnectableElement)}
        sx={{ m: 1, ml: 0, cursor: "move", alignSelf: "flex-start" }}
      >
        <KIcon fontSize="small" baseClassName="fas" className="fa-grip-vertical" />
      </Box>

      <Box sx={{ flex: 1 }}>{children}</Box>
    </Box>
  );
});
