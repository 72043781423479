import { useCallback } from "react";

import { createResourceFile } from "@keepeek/api-client";
import { useCurrentUser } from "@keepeek/commons";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { getAxiosClientInstance } from "../../../lib/axios/axios-utils";
import logger from "../../../lib/logger-utils";
import { Extension, getContentType } from "../utils";
import { useFileResources } from "./useFileResources";

export const useFileResourceCreate = (): {
  createResource: (file: Blob, type: string, id: string) => Promise<string | null | undefined>;
} => {
  const { resources, totalCount, setResources } = useFileResources();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { loadingAssignedGroups, isTech, isAdministrator } = useCurrentUser();

  const createResource = useCallback(
    async (file: File, type: string, id: string): Promise<string | undefined | null> => {
      try {
        if (!isTech && !isAdministrator && !loadingAssignedGroups) {
          logger.error("No rights to create a resource");
          return null;
        }

        if (!id) {
          logger.info("Can't create a resource without id");
          return;
        }

        if (!type) {
          logger.info("Can't create a resource without type");
          return;
        }
        let contentType = file.type;
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        if (contentType === null || contentType === "") {
          // with some navigators file.type is empty so we force content-type
          contentType = getContentType(extension.toLowerCase() as Extension);
        }
        const result = await createResourceFile(getAxiosClientInstance(), {
          path: id,
          type,
          file,
          axiosRequestConfig: { headers: { "Content-Type": contentType } },
        });

        const url: string = result.headers.location;
        const newResources = [
          ...(resources ? resources : []),
          { id, url, fileName: url.substring(url.lastIndexOf("/") + 1) },
        ];
        setResources({ resources: newResources, totalCount });

        return url;
      } catch (error) {
        if (error.response && error.response.status === 415) {
          enqueueSnackbar(t("admin:resource.invalid-type.error"), {
            variant: "warning",
          });
        } else {
          enqueueSnackbar(t("admin:resource.upload.error"), {
            variant: "error",
          });
        }
      }
    },
    [
      enqueueSnackbar,
      isAdministrator,
      isTech,
      loadingAssignedGroups,
      resources,
      setResources,
      t,
      totalCount,
    ],
  );

  return { createResource };
};
