import { isClientSide } from "@keepeek/commons";
import * as Sentry from "@sentry/nextjs";
import isString from "lodash/isString";
import getConfig from "next/config";
import P, { LoggerOptions } from "pino";

import { ServerRuntimeConfig } from "./config/serverRuntimeConfigUtils";

const { serverRuntimeConfig }: { serverRuntimeConfig: ServerRuntimeConfig } = getConfig();

enum Level {
  FATAL = "fatal",
  ERROR = "error",
  WARN = "warn",
  INFO = "info",
  DEBUG = "debug",
  TRACE = "trace",
}

/**
 * Log hook to allow using two syntax:
 * - logger.info("My %s hook", "log");
 * - logger.info("My hook for", "logs");
 * Allowed wilcard are listed in https://github.com/pinojs/quick-format-unescaped/blob/master/index.js
 *
 * This hook does not affect error logging due to Pino putting error object in first position:
 * logger.error("My error", e) will be translated to method.apply(this, e, "My error");
 */
function logMethodHook(args, method) {
  if (args.length === 2 && typeof args[0] === "string" && args[0].indexOf("%") === -1) {
    args[0] = `${args[0]} %j`;
  }
  method.apply(this, args);
}

function getPinoConfig(): P.LoggerOptions | P.DestinationStream {
  const enabled =
    serverRuntimeConfig?.log?.enabled !== "false" && serverRuntimeConfig.log?.enabled !== false;
  const prettyPrint =
    serverRuntimeConfig?.log?.prettyPrint !== false &&
    serverRuntimeConfig?.log?.prettyPrint !== "false";
  const level = serverRuntimeConfig?.log?.level;
  const refrontVersion = process.env.NEXT_PUBLIC_CORE_VERSION;
  return {
    enabled,
    prettyPrint,
    level,
    timestamp: () => `,"time":"${new Date(Date.now()).toISOString()}"`,
    hooks: { logMethod: logMethodHook }, // see https://github.com/pinojs/pino/blob/master/docs/api.md#interpolationvalues-any
    mixin() {
      return {
        version: refrontVersion,
      };
    },
  } as LoggerOptions;
}

function browserLog(level, args) {
  switch (level) {
    case Level.DEBUG:
      console.debug(...args);
      break;
    case Level.ERROR:
    case Level.FATAL:
      let sentryTitle: string | undefined = undefined;
      let sentryContext: any = undefined;
      if (isString(args?.[0])) {
        const [title, ...rest] = args;
        sentryTitle = title as string;
        sentryContext = rest;
      }
      Sentry.captureException(new Error(sentryTitle), (scope) =>
        scope.setExtra("logger context", sentryContext || args),
      );
      console.error(...args);
      break;
    case Level.INFO:
      console.info(...args);
      break;
    case Level.TRACE:
      console.trace(...args);
      break;
    case Level.WARN:
      console.warn(...args);
      break;
  }
}

let pino;

function serverLog(level: Level, args) {
  if (!pino) {
    pino = pinoWrap(P(getPinoConfig()));
  }
  switch (level) {
    case Level.DEBUG:
      pino.debug(...args);
      break;
    case Level.ERROR:
      pino.error(...args);
      break;
    case Level.FATAL:
      pino.fatal(...args);
      break;
    case Level.INFO:
      pino.info(...args);
      break;
    case Level.TRACE:
      pino.trace(...args);
      break;
    case Level.WARN:
      pino.warn(...args);
      break;
  }
}

/**
 * Pino wrapper to manage error object in second position in logger (see https://github.com/pinojs/pino/issues/673#issuecomment-506979971)
 */
function pinoWrap(logger) {
  const { error, child } = logger;

  function errorRearranger(...args) {
    if (typeof args[0] === "string" && args.length > 1) {
      for (let i = 1; i < args.length; i++) {
        const arg = args[i];
        if (arg instanceof Error) {
          const [err] = args.splice(i, 1);
          args.unshift(err);
        }
      }
    }
    return error.apply(this, args);
  }

  function childModifier(...args) {
    const c = child.apply(this, args);
    c.error = errorRearranger;
    c.child = childModifier;
    return c;
  }

  logger.error = errorRearranger;
  logger.child = childModifier;
  return logger;
}

function isomorphicLog(level: Level, args) {
  if (isClientSide()) {
    browserLog(level, args);
  } else {
    serverLog(level, args);
  }
}

const logger = {
  fatal: (...args) => isomorphicLog(Level.FATAL, args),
  error: (...args) => isomorphicLog(Level.ERROR, args),
  warn: (...args) => isomorphicLog(Level.WARN, args),
  info: (...args) => isomorphicLog(Level.INFO, args),
  debug: (...args) => isomorphicLog(Level.DEBUG, args),
  trace: (...args) => isomorphicLog(Level.TRACE, args),
};

export default logger;
