import React, { FC } from "react";

import { Autocomplete, Chip, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

export type MailLinkData = {
  recipients?: string[];
  subject?: string;
  body?: string;
};
const MailLink: FC<
  React.PropsWithChildren<
    {
      onChange: (data: MailLinkData) => void;
    } & MailLinkData
  >
> = function ({ onChange, recipients, subject, body }) {
  const { t } = useTranslation("admin");
  const handleChange = (data: MailLinkData) => onChange(data);
  return (
    <>
      <Autocomplete<string, true, false, true>
        multiple
        onChange={(_, value) =>
          handleChange({
            body,
            subject,
            recipients: value,
          })
        }
        options={[]}
        defaultValue={recipients}
        freeSolo
        autoSelect
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
          ))
        }
        onInputChange={(e, newInputValue) => {
          if (
            e.target instanceof HTMLElement &&
            (newInputValue.endsWith(",") ||
              newInputValue.endsWith(";") ||
              newInputValue.endsWith(" "))
          ) {
            (e.target as HTMLElement).blur();
            (e.target as HTMLElement).focus();
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("locale.links.link.mail.recipient")}
            placeholder="test@test.com;test2@test.com;test3@test.com"
            margin="normal"
          />
        )}
      />
      <TextField
        label={t("locale.links.link.mail.subject")}
        value={subject}
        onChange={(event) =>
          handleChange({
            body,
            subject: event.target.value,
            recipients,
          })
        }
        fullWidth
        margin="normal"
      />
      <TextField
        label={t("locale.links.link.mail.body")}
        value={body}
        onChange={(event) =>
          handleChange({
            body: event.target.value,
            subject,
            recipients,
          })
        }
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
    </>
  );
};

export default MailLink;
