import { atom, RecoilState } from "recoil";
/**
 * Atom that keep the state of front edit
 * @author JBO
 *
 * @type {RecoilState<boolean>}
 */
export const frontEditState: RecoilState<boolean> = atom({
  key: "FrontEditState",
  default: false,
});

// Keep a state of how much the front edit has been closed
// this is used for cache invalidation in multiple selectors
// It is maintained by the number of time we close the front edit
export const frontEditHasBeenClosedXTimesState = atom<number>({
  key: "FrontEditHasBeenClosedXTimesState",
  default: 0,
});
