import { useEffect } from "react";

import { init } from "@socialgouv/matomo-next";
import { useRecoilState } from "recoil";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { useConfiguration } from "../../config/hooks";
import { matomoInitAtom } from "../atoms";

export const useMatomo = () => {
  const [matomoInit, setMatomoInit] = useRecoilState(matomoInitAtom);

  const globalConf: any = useConfiguration(CONFIGURATION_SECTION_PATH.GLOBAL);

  const url = globalConf?.matomo?.matomoUrl || undefined;
  const siteId = globalConf?.matomo?.siteId || undefined;

  useEffect(() => {
    if (!matomoInit && url && siteId) {
      init({ url: url, siteId: siteId });
      setMatomoInit(true);
    }
  }, [matomoInit, setMatomoInit, siteId, url]);
};
