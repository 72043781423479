import React from "react";

import { TextField } from "@mui/material";
import { Widget, WidgetProps } from "@rjsf/utils";

const CustomNumberWidget: Widget = function ({
  id,
  placeholder,
  required,
  readonly,
  disabled,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  options,
  rawErrors = [],
}: WidgetProps) {
  const _onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
    onChange(value === "" ? options.emptyValue : value);
  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onBlur(id, value);
  const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
    onFocus(id, value);

  const _onWheel = (event) => {
    if (options.noWheel) {
      // we want to disable mouse "wheel" on input
      event.target.blur();
    }
  };

  return (
    <TextField
      id={id}
      placeholder={placeholder}
      label={label}
      autoFocus={autofocus}
      required={required}
      disabled={disabled || readonly}
      type={"number"}
      value={value || value === 0 ? value : ""}
      error={rawErrors.length > 0}
      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
      onWheel={_onWheel}
    />
  );
};
export default CustomNumberWidget;
