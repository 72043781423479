import { KpkApiHitSearchType } from "@keepeek/api-client";
import { BusinessFilter, BusinessFilterExtraParams, useReset } from "@keepeek/commons";
import { useRouter } from "next/router";
import { useRecoilValue } from "recoil";

import { PagesPath } from "../../../containers/App/utils";
import { backContextState } from "../../backContext/atoms";
import {
  activeBusinessFiltersFromURLSelector,
  activeRawURLFiltersFromURLSelector,
} from "../selectors/activeFilters";
import { useLBF } from "./useLBF";

export type UseActiveFilters = {
  activeFilters: BusinessFilter[];
  rawURLFilters: { lbf: string };
  setActiveFilters: (filters: BusinessFilter[], extraParams?: BusinessFilterExtraParams) => void;
  isFolderSearch: boolean;
};
/**
 * Update the filters with active filter connector (currently: LBF)
 * Receive the activeFilters at the same time for convenience
 * @author JBO
 */
export const useActiveFiltersFromURL = (): UseActiveFilters => {
  const router = useRouter();
  const context = useRecoilValue(backContextState);
  const { setLBF } = useLBF();
  const resetDataview = useReset();
  const activeFilters = useRecoilValue(activeBusinessFiltersFromURLSelector);
  const rawURLFilters = useRecoilValue(activeRawURLFiltersFromURLSelector);

  /**
   * Update active filters trough current active filters connector
   * At the moment, we're using LBF
   * @author JBO
   *
   * @param {BusinessFilter[]} filters
   */
  const setActiveFilters = (filters: BusinessFilter[], extraParams?: BusinessFilterExtraParams) => {
    let pathnameToPush: string = PagesPath.SEARCH_PAGE;
    // Compute path name to keep current context when rebounce action
    if (
      context &&
      router.pathname.includes(PagesPath.ELEMENT_PAGE) &&
      context.sourcePage?.pathname
    ) {
      pathnameToPush = context.sourcePage?.pathname;
    } else if (router.pathname.includes(PagesPath.BASKETS)) {
      pathnameToPush = router.pathname;
    }
    setLBF(filters, pathnameToPush, extraParams);
    resetDataview();
  };

  return {
    activeFilters,
    rawURLFilters,
    setActiveFilters,
    isFolderSearch:
      activeFilters &&
      activeFilters.length !== 0 &&
      activeFilters[0].filter &&
      activeFilters[0].filter.type === KpkApiHitSearchType.Folder.toString(),
  };
};
