import { localStorageEffect } from "@keepeek/commons";
import { atom, atomFamily } from "recoil";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { InitConfigApi } from "../utils";

export const initConfigApiAtom = atom<InitConfigApi | undefined>({
  key: "InitConfigApiAtom",
  default: undefined,
});

export const initConfigRefresherAtom = atom<number>({
  key: "InitConfigRefresherAtom",
  default: 0,
});

export const configSectionPeristenceAtom = atomFamily<any, CONFIGURATION_SECTION_PATH>({
  key: "ConfigSectionPeristenceAtom",
  default: undefined,
  effects: (key) => [localStorageEffect(`SelectedElements-${key}`)],
});
