import React, { FC } from "react";

import { Alert, Box, LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

import { LoadingStatus } from "../../utils";

const LoadingInformation: FC<
  React.PropsWithChildren<{
    status: LoadingStatus;
  }>
> = function ({ status }) {
  const { t } = useTranslation();
  switch (status) {
    case LoadingStatus.LOADED:
      return (
        <Wrapper>
          <Alert severity="success">{t("admin:resource.upload.loading.success.label")}</Alert>
        </Wrapper>
      );
    case LoadingStatus.LOADING:
      return (
        <Wrapper>
          <Alert severity="info">
            {t("admin:resource.upload.loading.info.label")}
            <LinearProgress />
          </Alert>
        </Wrapper>
      );
    case LoadingStatus.ERROR:
      return (
        <Wrapper>
          <Alert severity="warning">{t("admin:resource.upload.loading.error.label")}</Alert>
        </Wrapper>
      );
    default:
      return null;
  }
};
export default LoadingInformation;

const Wrapper: FC<React.PropsWithChildren<{ children }>> = function ({ children }) {
  return (
    <Box
      sx={{
        marginBottom: 2,
      }}
    >
      {children}
    </Box>
  );
};
