import React, { FC } from "react";

import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { CONFIGURATION_SECTION_PATH } from "../../../../../components/layout/adminMenu";
import { Type } from "../../../../../models/configuration/definitions/widget";
import { JsonConfig } from "../../../../commonPagePropsUtil";
import {
  convertWidgetConfigSectionToWidgetTitle,
  convertWidgetConfigSectionToWidgetType,
} from "../utils";
import WidgetCardSelector, { WidgetCardSelectorType } from "../WidgetCardSelector";

type WidgetsBySectionProps = {
  onSelect: ({ type, id }: { type: Type; id: string }) => void;
} & JsonConfig;

const WidgetsBySection: FC<React.PropsWithChildren<WidgetsBySectionProps>> = function ({
  configSection,
  jsonData,
  onSelect,
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  if (
    convertWidgetConfigSectionToWidgetType(configSection as CONFIGURATION_SECTION_PATH) ===
    undefined
  ) {
    return null;
  }

  const widgets: WidgetCardSelectorType[] = (jsonData as any[]).map((jd) => ({
    id: jd.id,
    type: convertWidgetConfigSectionToWidgetType(
      configSection as CONFIGURATION_SECTION_PATH,
    ) as Type,
    ui: jd,
  }));

  return (
    <Box style={{ marginBottom: theme.spacing(2) }}>
      <Typography variant="overline" style={{ marginBottom: theme.spacing(1) }}>
        {convertWidgetConfigSectionToWidgetTitle(configSection as CONFIGURATION_SECTION_PATH, t)}
      </Typography>
      <Box
        style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: theme.spacing(1) }}
      >
        {widgets.map((w) => (
          <WidgetCardSelector
            key={w.id}
            {...w}
            onClick={() =>
              onSelect({
                type: convertWidgetConfigSectionToWidgetType(
                  configSection as CONFIGURATION_SECTION_PATH,
                ) as Type,
                id: w.id,
              })
            }
          />
        ))}
      </Box>
    </Box>
  );
};

export default WidgetsBySection;
