import { FC } from "react";

import { I18nextProvider } from "react-i18next";

import useI18nLanguageSwitch from "./hooks";

const I18nProvider: FC<React.PropsWithChildren<unknown>> = function ({ children }) {
  const { instance } = useI18nLanguageSwitch();

  // Wait for i18n instance to be initialized before displaying any content
  // Otherwise label keys may appear on pages and some components need i18n language
  // to be initialized.
  if (!instance) {
    return null;
  }
  return <I18nextProvider i18n={instance}>{children}</I18nextProvider>;
};

export default I18nProvider;
