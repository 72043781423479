import React from "react";

import { SanitizeHtml } from "@keepeek/refront-components";
import MonacoEditor from "@monaco-editor/react";
import { Box, Typography } from "@mui/material";
import { WidgetProps, Widget } from "@rjsf/utils";
import { useTranslation } from "react-i18next";

const CustomEditor: Widget = function ({ onChange, value, label }: WidgetProps) {
  const { t } = useTranslation();
  const handleChange = (e: any) => {
    onChange(e);
  };

  return (
    <Box data-testid="CustomEditor">
      <Typography variant="subtitle1">
        <SanitizeHtml html={t(label)} />
      </Typography>

      <MonacoEditor
        onChange={handleChange}
        value={value}
        width="100%"
        height="80vh"
        language="json"
      />
    </Box>
  );
};

export default CustomEditor;
