import React, { FC } from "react";

import { Box } from "@mui/material";

const VideoResource: FC<
  React.PropsWithChildren<{
    resourceUrl: string;
  }>
> = function ({ resourceUrl }) {
  return (
    <Box
      // Need a key here to reload video component on video url change (can happen during a change from the frontedit)
      key={resourceUrl}
      component="video"
      autoPlay={true}
      controls={false}
      loop={true}
      poster={""}
      muted
      sx={{
        maxWidth: 200,
        maxHeight: 200,
        border: "1px solid #eeeeee",
        borderRadius: (theme) => theme.shape.borderRadius,
      }}
    >
      <source src={resourceUrl} />
    </Box>
  );
};
export default VideoResource;
