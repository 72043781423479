import React from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Box, Chip, useTheme } from "@mui/material";
import clsx from "clsx";

import { KMenuWidget } from "../../../../../models/configuration/components/kMenuWidget";

const WidgetCardSelectorMenu = function ({
  testId = "WidgetCardSelectorMenu",
  backgroundColor,
  color,
  borderColor,
  menuColorOnHover,
  linksByLocale,
  container,
  className,
  id,
}: KMenuWidget & BaseComponentProps) {
  const theme = useTheme();
  return (
    <Box
      className={clsx("WidgetCardSelectorMenu", className)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexDirection: "column",
        width: "100%",
      }}
      data-testid={testId}
      title={id}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          height: theme.spacing(5),
          width: "80%",
          borderRadius: "0 0 .25rem .25rem",
          flexGrow: 1,
          marginBottom: theme.spacing(2),
        }}
      />
      <Box
        sx={{
          backgroundColor: backgroundColor ?? theme.palette.primary.main,
          borderTop: `1px solid ${borderColor ?? theme.palette.primary.main}`,
          borderBottom: `1px solid ${borderColor ?? theme.palette.primary.main}`,
          borderLeft: container ? `1px solid ${borderColor ?? theme.palette.primary.main}` : "none",
          borderRight: container
            ? `1px solid ${borderColor ?? theme.palette.primary.main}`
            : "none",
          width: container ? `calc(100% - ${theme.spacing(2)})` : "100%",
          height: "50px",
        }}
      >
        {!!linksByLocale && (
          <Chip
            size="small"
            sx={{
              marginLeft: theme.spacing(0.5),
              marginRight: theme.spacing(0.5),
              color: color,
              backgroundColor: menuColorOnHover,
              height: "48px",
            }}
            label={`link${(linksByLocale?.length ?? 0) > 1 ? "s" : ""}: ${linksByLocale?.length}`}
          />
        )}
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          height: theme.spacing(5),
          width: "80%",
          borderRadius: ".25rem .25rem 0 0",
          flexGrow: 1,
          marginTop: theme.spacing(2),
        }}
      />
    </Box>
  );
};

export default WidgetCardSelectorMenu;
