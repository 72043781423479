import React, { FC } from "react";

import { Box } from "@mui/material";

const ImageResource: FC<
  React.PropsWithChildren<{
    resourceUrl: string;
  }>
> = function ({ resourceUrl }) {
  return (
    <Box
      component="img"
      sx={{
        maxWidth: 200,
        maxHeight: 200,
        border: "1px solid #eeeeee",
        borderRadius: (theme) => theme.shape.borderRadius,
      }}
      src={resourceUrl}
      loading="lazy"
    />
  );
};
export default ImageResource;
