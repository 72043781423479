import React from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Box, useTheme } from "@mui/material";
import clsx from "clsx";

import { KReportWidget } from "../../../../../models/configuration/components/kReportWidget";

const WidgetCardSelectorReport = function ({
  testId = "WidgetCardSelectorReport",
  className,
  backgroundColor,
  id,
}: KReportWidget & BaseComponentProps) {
  const theme = useTheme();
  return (
    <Box
      className={clsx("WidgetCardSelectorReport", className)}
      data-testid={testId}
      title={id}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: theme.spacing(1),
        flexShrink: 1,
        backgroundColor: backgroundColor,
        padding: theme.spacing(1),
      }}
    >
      <Box
        style={{
          backgroundColor: theme.palette.grey[200],
          minHeight: theme.spacing(3),
          width: "100%",
        }}
      ></Box>
      <Box
        style={{
          backgroundColor: theme.palette.primary.main,
          width: `calc(100% - ${theme.spacing(3)})`,
          minHeight: theme.spacing(3),
        }}
      />
      <Box
        style={{
          backgroundColor: theme.palette.primary.main,
          width: `calc(100% - ${theme.spacing(3)})`,
          minHeight: theme.spacing(3),
        }}
      />
      <Box
        style={{
          backgroundColor: theme.palette.primary.main,
          width: `calc(100% - ${theme.spacing(3)})`,
          minHeight: theme.spacing(3),
        }}
      />
    </Box>
  );
};

export default WidgetCardSelectorReport;
