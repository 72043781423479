import React from "react";

import { BaseComponentProps, SanitizeHtml } from "@keepeek/refront-components";
import { Box, useTheme } from "@mui/material";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";

import {
  LinkType,
  Mosaic,
  MosaicElement,
  Size,
} from "../../../../../models/configuration/components/mosaic";
import { useCurrentLocale } from "../../../../../providers/i18n/hooks";

const WidgetCardSelectorMosaic = function ({
  testId = "WidgetCardSelectorMosaic",
  className,
  backgroundColor,
  title,
  content,
  folderIds,
  centeredMosaics,
  carousel,
  size,
  mosaicElements,
  id,
}: Mosaic & BaseComponentProps) {
  const theme = useTheme();
  const locale = useCurrentLocale();
  const localizedTitle = title || (!!content && content.find((t) => t.language === locale)?.value);
  const sizePadding = (): number => {
    switch (size) {
      case Size.S:
        return 1;
      case Size.M:
        return 1.25;
      case Size.L:
        return 1.5;
      default:
        return 1.75;
    }
  };
  const elements = !!mosaicElements && mosaicElements.length > 0 ? mosaicElements : folderIds;

  return (
    <Box
      className={clsx("WidgetCardSelectorMosaic", className)}
      data-testid={testId}
      title={id}
      style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
    >
      {!!localizedTitle && <SanitizeHtml html={localizedTitle} />}
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: centeredMosaics ? "center" : "flex-start",
          alignItems: "center",
          gap: theme.spacing(0.5),
          flexShrink: 1,
          backgroundColor,
          padding: theme.spacing(1),
          flexWrap: carousel ? "nowrap" : "wrap",
        }}
      >
        {elements?.map((element) => (
          <Box
            key={generateElementKey(element)}
            style={{
              padding: theme.spacing(sizePadding()),
              backgroundColor: theme.palette.primary.main,
              borderRadius: theme.shape.borderRadius,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

const generateElementKey = (element: MosaicElement | number | undefined): string => {
  let idToReturn;
  if (typeof element === "number") {
    idToReturn = `${element}`;
  } else if (element) {
    switch (element.linkType) {
      case LinkType.Folder:
        idToReturn = `${element.folderId}`;
        break;
      default:
        idToReturn = `${element.id}`;
        break;
    }
  }
  if (!idToReturn) {
    idToReturn = uuidv4();
  }
  return idToReturn;
};

export default WidgetCardSelectorMosaic;
