import { KpkApiSheetField } from "@keepeek/api-client";
import { getKpkMetaFieldLabelByFieldName } from "@keepeek/commons";
import { getI18n } from "react-i18next";

import { BusinessFilterFilter } from "../../../../models/configuration/components/businessFilter";

export const getOptionLabel = (
  businessFilterFilter: Partial<BusinessFilterFilter>,
  currentLocale: string | undefined,
  sheetFields: KpkApiSheetField[] | undefined,
): string => {
  if (businessFilterFilter.title) {
    const currentLocalTitle = businessFilterFilter.title?.find(
      (t) => t.language.toLowerCase() === currentLocale,
    )?.value;
    return currentLocalTitle || "";
  } else {
    const filterType = businessFilterFilter.type
      ? ` (${getI18n().t(
          `rjsf.business-filter.type.short.${businessFilterFilter.type?.toLowerCase()}`,
          {
            ns: "admin",
          },
        )})`
      : "";
    return `${getKpkMetaFieldLabelByFieldName(
      sheetFields,
      businessFilterFilter.name || "",
    )}${filterType}`;
  }
};
