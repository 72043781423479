import { useEffect } from "react";

import { isEqual } from "lodash";
import { useRecoilState } from "recoil";

import { HeaderMetas, headerMetasState } from "../atoms/headerMetas";

export const useHeaderMetas = (props?: HeaderMetas): HeaderMetas => {
  const [metas, setMetas] = useRecoilState(headerMetasState);
  useEffect(() => {
    if (props && !isEqual(metas, props)) {
      setMetas(props);
    }
  }, [metas, props, setMetas]);

  return metas;
};
