import React, { FC } from "react";

import { DidomiSDK } from "@didomi/react";

import { useInitConfig } from "../../providers/config/hooks/init";
import { useDidomi } from "../../providers/tools/didomi/hooks";

const Didomi: FC<React.PropsWithChildren<unknown>> = function () {
  const { initConfig } = useInitConfig();
  const { needDidomi, ...rest } = useDidomi();

  // initConfig is mandatory to load didomi
  return <>{initConfig && needDidomi && <DidomiSDK {...rest} />}</>;
};

export default Didomi;
