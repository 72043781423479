import React, { FC } from "react";

import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const UnknownResource: FC<
  React.PropsWithChildren<{
    resourceUrl: string;
  }>
> = function ({ resourceUrl }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        gap: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginBottom: 2,
        overflowWrap: "break-word",
        wordBreak: "break-word",
      }}
    >
      <Typography variant="subtitle1" component="div">
        <a target="_blank" href={resourceUrl} rel="noreferrer">
          <b>{t("admin:resource.url.label")}</b>
          {resourceUrl}
        </a>
      </Typography>
    </Box>
  );
};
export default UnknownResource;
