import { selector } from "recoil";

import { configSectionComponentDataViewSelector } from "../../config/selectors";

/**
 * Selector that pull the dataView admin config section and return the value of openBusinessFilters
 * It is used by the atom that contain the opening state
 * @author JBO
 */
export const businessFilterPanelIsOpenByConfigSelector = selector<boolean>({
  key: "BusinessFilterPanelIsOpenByConfigSelector",
  get: ({ get }) => {
    const config = get(configSectionComponentDataViewSelector);
    return config?.openBusinessFilters ?? false;
  },
});
