import { KpkApiSheetField, KpkApiSheetFieldType } from "@keepeek/api-client";
import { MediaField } from "@keepeek/commons";

import { Type } from "../../../../models/configuration/components/businessFilter";

export const getDisplayTypeOptions = (
  fieldId: string,
  sheetFields: KpkApiSheetField[] | undefined,
): Type[] => {
  if (Object.values(MediaField).find((m) => m === fieldId)) {
    return [Type.Facet];
  } else if (sheetFields?.find((s) => s.id === fieldId)) {
    const sheetField = sheetFields?.find((s) => s.id === fieldId);
    switch (sheetField?.type) {
      case KpkApiSheetFieldType.CheckBox:
      case KpkApiSheetFieldType.Datetime:
        return [Type.Advanced];
      case KpkApiSheetFieldType.SimpleList:
        return [Type.Facet];
      case KpkApiSheetFieldType.Date:
        return [Type.Advanced, Type.Facet];
      case KpkApiSheetFieldType.Email:
        return [Type.Advanced, Type.Facet];
      case KpkApiSheetFieldType.TextArea:
        return [Type.Facet, Type.Advanced];
      case KpkApiSheetFieldType.Text:
        return [Type.Facet, Type.Advanced];
      case KpkApiSheetFieldType.URL:
        return [Type.Advanced, Type.Facet];
      case KpkApiSheetFieldType.Thesaurus:
        return [Type.Facet, Type.FacetMulti];
      case KpkApiSheetFieldType.MultipleList:
        return [Type.Facet, Type.FacetMulti];
      default:
        return [];
    }
  } else {
    return [];
  }
};
