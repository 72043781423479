import { removeUndefinedDeep } from "@keepeek/commons";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { JsonConfig } from "../../../lib/commonPagePropsUtil";
import { getAdminConfigSectionData } from "../../../lib/config/customer-config-utils";
import logger from "../../../lib/logger-utils";
import { KSectionTitleWidget } from "../../../models/configuration/components/kSectionTitleWidget";
import { Type } from "../../../models/configuration/definitions/widget";

import { KSectionTitleWidgetProps } from ".";

export function getSectionTitleWidgetConfiguration(
  customerSectionConfiguration: JsonConfig[],
  widgetId: string,
): KSectionTitleWidgetProps | undefined {
  const sectionTitleWidgets = getAdminConfigSectionData<KSectionTitleWidget[]>(
    customerSectionConfiguration,
    CONFIGURATION_SECTION_PATH.COMPONENTS_SECTION_TITLE_WIDGET,
  );

  if (sectionTitleWidgets?.length === undefined || sectionTitleWidgets.length === 0) {
    logger.debug(`getSectionTitleWidgetConfiguration - sectionTitleWidgets is empty or undefined`);
    return;
  }

  const sectionTitleWidget: KSectionTitleWidget | undefined = sectionTitleWidgets?.find(
    (m) => m.id === widgetId,
  );
  if (!sectionTitleWidget) {
    logger.debug(
      `getSectionTitleWidgetConfiguration - can't find news column widget id ${widgetId}`,
    );
    return;
  }

  // For SSR, do not return any object undefined cause it can't be used in JSON
  const config: KSectionTitleWidgetProps = {
    widgetId: sectionTitleWidget?.id,
    backgroundColor: sectionTitleWidget?.backgroundColor,
    borderRadius: sectionTitleWidget?.borderRadius,
    content: sectionTitleWidget?.content,
    chip: sectionTitleWidget?.chip,
    type: Type.SectionTitle,
  };
  // Include only when defined
  return removeUndefinedDeep(config);
}
