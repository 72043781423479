import { FetcherMode } from "@keepeek/commons";
import { selectorFamily } from "recoil";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { Inherit, Share } from "../../../models/configuration/pages/share";
import {
  Inherit as InheritElement,
  ShareElement,
} from "../../../models/configuration/pages/shareElement";
import { configSectionDataByPathSelector } from "../../config/selectors";

export type ConfigSectionFetcher = {
  pageSearchSection: CONFIGURATION_SECTION_PATH;
  pageElementSection: CONFIGURATION_SECTION_PATH;
};

export const configSectionFetcherSelector = selectorFamily<ConfigSectionFetcher, FetcherMode>({
  key: "ConfigSectionFetcherSelector",
  get:
    (mode) =>
    ({ get }) => {
      switch (mode) {
        case FetcherMode.SHARE:
          // Check for inherit flag in configuration
          const pageShareSection: Share | undefined = get(
            configSectionDataByPathSelector(CONFIGURATION_SECTION_PATH.PAGES_SHARE),
          );
          const pageShareElementSection: ShareElement | undefined = get(
            configSectionDataByPathSelector(CONFIGURATION_SECTION_PATH.PAGES_SHARE_ELEMENT),
          );

          return {
            pageSearchSection:
              pageShareSection?.inherit === Inherit.Custom
                ? CONFIGURATION_SECTION_PATH.PAGES_SHARE
                : CONFIGURATION_SECTION_PATH.PAGES_SEARCH,
            pageElementSection:
              pageShareElementSection?.inherit === InheritElement.Custom
                ? CONFIGURATION_SECTION_PATH.PAGES_SHARE_ELEMENT
                : CONFIGURATION_SECTION_PATH.PAGES_ELEMENT,
          };
        default:
          return {
            pageSearchSection: CONFIGURATION_SECTION_PATH.PAGES_SEARCH,
            pageElementSection: CONFIGURATION_SECTION_PATH.PAGES_ELEMENT,
          };
      }
    },
});
