import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { JsonConfig } from "../../../lib/commonPagePropsUtil";
import { getAdminConfigSectionData } from "../../../lib/config/customer-config-utils";
import logger from "../../../lib/logger-utils";
import { KReportWidget } from "../../../models/configuration/components/kReportWidget";
import { Type } from "../../../models/configuration/definitions/widget";

import { ReportWidgetProps } from ".";

export function getReportConfiguration(
  customerSectionConfiguration: JsonConfig[],
  widgetId: string,
): ReportWidgetProps | undefined {
  const reports = getAdminConfigSectionData<KReportWidget[]>(
    customerSectionConfiguration,
    CONFIGURATION_SECTION_PATH.COMPONENTS_REPORT_WIDGET,
  );

  if (reports?.length === undefined || reports.length === 0) {
    logger.debug(`getReportConfiguration - reports is empty or undefined`);
    return;
  }

  const report = reports?.find((m) => m.id === widgetId);
  if (!report) {
    logger.debug(`getReportConfiguration - can't find report widget id ${widgetId}`);
    return;
  }

  return {
    widgetId: report.id,
    backgroundColor: report.backgroundColor,
    reportField: report.reportField,
    fieldValue: report.fieldValue,
    type: Type.Report,
  };
}
