import useFrontEditActions, { UseFrontEditActions } from "./useFrontEditActions";
import useFrontEditContext, { UseFrontEditContext } from "./useFrontEditContext";
import useFrontEditLabelWrapper, { UseFrontEditLabelWrapper } from "./useFrontEditLabelWrapper";

type UseFrontEdit = {} & UseFrontEditContext & UseFrontEditActions & UseFrontEditLabelWrapper;

export default function useFrontEdit(): UseFrontEdit {
  const frontEditContext = useFrontEditContext();
  const frontEditActions = useFrontEditActions();
  const frontEditLabelWrapper = useFrontEditLabelWrapper();

  return {
    ...frontEditContext,
    ...frontEditActions,
    ...frontEditLabelWrapper,
  };
}
