import { sanitizeUrl } from "@braintree/sanitize-url";
import { UiSchema } from "@rjsf/utils";
import { JSONSchema7Definition } from "json-schema";
import jsonpath from "jsonpath";
import { cloneDeep } from "lodash";
import LZString from "lz-string";
import sanitizeHtml from "sanitize-html";

export enum ApiEncodeType {
  HTML = "html",
  URL = "url",
}

/**
 * Encode JSON data properties defined has "api-encode" in the "UI" schema.
 * @param schema JSON schema to filter
 * @param uiSchema UI Schema to use (will contain fields to filter according to user rights)
 * @param jsonData JSON data
 * @return an encoded JSON data version
 */
export const apiEncode = (
  schema: JSONSchema7Definition,
  uiSchema: UiSchema,
  jsonData: any,
): any => {
  return jsonApplyApiEncode(schema, uiSchema, jsonData, true);
};

/**
 * Decode JSON data properties defined has "api-encode" in the "UI" schema.
 * @param schema JSON schema to filter
 * @param uiSchema UI Schema to use (will contain fields to filter according to user rights)
 * @param jsonData JSON data
 * @returns a decoded JSON data version
 */
export const apiDecode = (
  schema: JSONSchema7Definition,
  uiSchema: UiSchema,
  jsonData: any,
): any => {
  return jsonApplyApiEncode(schema, uiSchema, jsonData, false);
};

const jsonApplyApiEncode = (
  schema: JSONSchema7Definition,
  uiSchema: UiSchema,
  jsonData: any,
  encode: boolean,
): any => {
  const jsonClone = cloneDeep(jsonData);
  const paths = getJsonPathApiEncode(schema, uiSchema, jsonClone, "$");

  paths.forEach(({ type, path }) =>
    jsonpath.apply(jsonClone, path, (value) => {
      if (encode) {
        return LZString.compressToEncodedURIComponent(sanitize(type, value));
      } else {
        return sanitize(type, LZString.decompressFromEncodedURIComponent(value) || "");
      }
    }),
  );

  return jsonClone;
};

const getJsonPathApiEncode = (
  schema: JSONSchema7Definition,
  uiSchema: UiSchema,
  jsonData: any,
  path: any,
): Array<{ type: ApiEncodeType; path: string }> => {
  const paths: Array<{ type: ApiEncodeType; path: string }> = [];
  Object.keys(schema).forEach((key) => {
    if (key === "properties" && schema["type"] === "object") {
      Object.keys(schema["properties"]).forEach((property) => {
        const p = `${path}.${property}`;
        if (uiSchema && property in uiSchema && uiSchema[property]["api:encode"]) {
          paths.push({ type: uiSchema[property]["api:encode"], path: p });
        } else {
          paths.push(
            ...getJsonPathApiEncode(
              schema["properties"][property],
              uiSchema && property in uiSchema ? uiSchema[property] : {},
              jsonData,
              p,
            ),
          );
        }
      });
    } else if (key === "items" && schema["type"] === "array") {
      paths.push(
        ...getJsonPathApiEncode(schema["items"], uiSchema["items"], jsonData, `${path}[*]`),
      );
    }
  });
  return paths;
};

export const sanitize = (type: ApiEncodeType, value: string): string => {
  switch (type) {
    case ApiEncodeType.HTML:
      return sanitizeHtml(value, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(["ins"]),
        allowedAttributes: false,
      });
    case ApiEncodeType.URL:
      return sanitizeUrl(value);
  }
};
