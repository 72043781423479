import React, { FC } from "react";

import { KCConsumerStatus } from "@keepeek/commons";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAuthLoadingAlert } from "../../providers/auth/hooks/useAuthLoadingAlert";
import LogoLoadingAuth from "./LogoLoadingAuth";

const LoadingAuth: FC<React.PropsWithChildren<{ forceShow?: boolean; forceHide?: boolean }>> =
  function ({ children, forceShow: forceShowProps, forceHide: forceHideProps }) {
    useAuthLoadingAlert();
    const { t } = useTranslation();
    const forceShow = forceShowProps;
    const forceHide = forceHideProps;

    // Avoid FOUC
    const loadingText: string =
      t("layout.loadingAuth.loading.label") !== "layout.loadingAuth.loading.label"
        ? t("layout.loadingAuth.loading.label")
        : "";

    return (
      <>
        {!((forceHide && !forceShow) || (status !== KCConsumerStatus.Loading && !forceShow)) && (
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              position: "fixed",
              backgroundColor: (theme) => theme.palette.primary.main,
              left: 0,
              top: 0,
              overflow: "hidden",
              zIndex: (theme) => theme.zIndex.modal + 1,
              width: "100vw",
              display: "flex",
            }}
            className="LoadingAuth"
            data-testid="LoadingAuth"
          >
            <Box
              sx={{
                width: "100vw",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: (theme) => theme.spacing(2),
                display: "flex",
                position: "relative",
                backgroundColor: (theme) => theme.palette.background.default,
              }}
              className="LoadingAuth-content"
              data-testid="LoadingAuth-content"
            >
              <LogoLoadingAuth alt={loadingText} />
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                className="LoadingAuth-loadingText"
                data-testid="LoadingAuth-loadingText"
              >
                {loadingText}
              </Typography>
            </Box>
          </Box>
        )}
        {children}
      </>
    );
  };

export default LoadingAuth;
