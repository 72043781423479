import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

const PREFIX = "EditableSanitizeHtml";

export const classes = {
  editionIcon: `${PREFIX}-editionIcon`,
};

const StyledEditableSanitizeHtml = styled(Box)(() => ({
  [`& .${classes.editionIcon}`]: {
    display: "none",
  },

  "&:hover": {
    border: "1px solid #116ddf",
    borderRadius: "5px",
    padding: "2px",
    cursor: "pointer",
    width: "fit-content",

    [`& .${classes.editionIcon}`]: {
      display: "block",
      position: "relative",
      right: "-11px",
      top: "-10px",
      borderRadius: "25px",
      width: "25px",
      height: "25px",
      textAlign: "center",
    },
  },
}));

export default StyledEditableSanitizeHtml;
