import React, { useEffect, useState } from "react";

import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Widget, WidgetProps } from "@rjsf/utils";
import { useRouter } from "next/router";

import { replaceItemAtIndex } from "../../../../providers/frontEdit/utils";

const CustomLanguageSelectorWidget: Widget = function (props: WidgetProps) {
  const { locales: customerLocales } = useRouter();
  const [adminLocales, setAdminLocales] = useState<{ language: string; active: boolean }[]>([]);

  useEffect(() => {
    const configuredLocalesInAdmin = customerLocales?.map((customerLocale) => {
      if (props.value.find((v) => v === customerLocale)) {
        return { language: customerLocale, active: true };
      } else {
        return { language: customerLocale, active: false };
      }
    });
    if (configuredLocalesInAdmin) {
      setAdminLocales(configuredLocalesInAdmin);
    }
  }, [customerLocales, props.value]);

  const handleLocalesChange = (newLocale: { language: string; active: boolean }) => {
    const index = adminLocales.findIndex(
      (adminLocale) => adminLocale.language === newLocale.language,
    );
    const updatedAdminLocales = replaceItemAtIndex(adminLocales, index, {
      language: newLocale.language,
      active: !newLocale.active,
    });
    setAdminLocales(updatedAdminLocales);
    props.onChange(
      updatedAdminLocales
        .map((adminL) => {
          if (adminL.active) return adminL.language;
        })
        .filter((locale) => locale !== undefined),
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography sx={{ color: (theme) => theme.palette.grey[600] }}>{props.label}</Typography>
      {adminLocales?.map((adminL) => {
        return (
          <FormControlLabel
            key={adminL.language}
            control={
              <Checkbox
                key={adminL.language}
                checked={adminL.active}
                onChange={(_e) => handleLocalesChange(adminL)}
              />
            }
            label={adminL.language}
          />
        );
      })}
    </Box>
  );
};

export default CustomLanguageSelectorWidget;
