import { FC, useEffect } from "react";

import { isClientSide } from "@keepeek/commons";

import { getAxiosClientInstance, initAxios } from "../../lib/axios/axios-utils";
import { usePublicRuntimeConfig } from "../../providers/config/hooks/usePublicRuntimeConfig";
import LoadingAuth from "../auth/LoadingAuth";

const AxiosProvider: FC<React.PropsWithChildren<unknown>> = function ({ children }) {
  const { config, loading } = usePublicRuntimeConfig();
  useEffect(() => {
    if (isClientSide() && !loading) {
      initAxios(getAxiosClientInstance(), config?.apiRetryOnError, config?.keycloak.clientId);
    }
  }, [config?.apiRetryOnError, config?.keycloak.clientId, loading]);
  if (isClientSide() && loading) {
    return <LoadingAuth forceShow />;
  } else {
    return <>{children}</>;
  }
};

export default AxiosProvider;
