import React, { FC } from "react";

import { BaseComponentProps } from "@keepeek/commons";
import { CircularProgress } from "@mui/material";
import clsx from "clsx";

import { CONFIGURATION_SECTION_PATH } from "../../components/layout/adminMenu";
import { Theme } from "../../models/configuration/theme";
import { useConfiguration } from "../../providers/config/hooks";
import { useInitConfig } from "../../providers/config/hooks/init";

type LogoLoadingAuthProps = {
  alt: string;
} & BaseComponentProps;

const LogoLoadingAuth: FC<React.PropsWithChildren<LogoLoadingAuthProps>> = function ({
  alt,
  className,
  testId = "LogoLoadingAuth",
  ...rest
}) {
  const { loading } = useInitConfig();
  const themeConfig = useConfiguration<Theme>(CONFIGURATION_SECTION_PATH.THEME);

  // Don't show any logo if we does not know wich to print
  if (loading) {
    return null;
  }

  if (themeConfig?.logoLoadingAuth?.url) {
    return (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        className={clsx("LogoLoadingAuth", className)}
        src={themeConfig.logoLoadingAuth.url}
        alt={alt}
        data-testid={testId}
        style={{ maxWidth: "100%", maxHeight: "100vh" }}
        {...rest}
      />
    );
  }
  return (
    <CircularProgress
      title={alt}
      className={clsx("LogoLoadingAuth LogoLoadingAuth-default", className)}
      data-testid={testId}
      {...rest}
    />
  );
};

export default LogoLoadingAuth;
