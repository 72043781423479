import { SetterOrUpdater, useRecoilRefresher_UNSTABLE, useRecoilStateLoadable } from "recoil";

import { resourcesQuerySelector } from "../selector";
import { Resource } from "../types";

export const useFileResources = (): {
  resources: Resource[] | undefined;
  totalCount: number | undefined;
  setResources: SetterOrUpdater<{
    resources: Resource[] | undefined;
    totalCount: number | undefined;
  }>;
  refresh: () => void;
} => {
  const [resourceQuery, setResources] = useRecoilStateLoadable(resourcesQuerySelector);
  const refresh = useRecoilRefresher_UNSTABLE(resourcesQuerySelector);

  const {
    resources,
    totalCount,
  }: { resources: Resource[] | undefined; totalCount: number | undefined } =
    resourceQuery.state === "hasValue"
      ? resourceQuery.contents
      : { resources: undefined, totalCount: undefined };

  return { resources, totalCount, setResources, refresh };
};
