import {
  getCurrentUser,
  getUserAssignedGroups,
  KpkApiAssignedGroup,
  KpkApiUi,
  KpkApiUser,
} from "@keepeek/api-client";
import { isClientSide } from "@keepeek/commons";
import { getI18n } from "react-i18next";
import { SetterOrUpdater } from "recoil";

import { adminMenu, AdminMenuHref } from "../../../components/layout/adminMenu";
import { getAxiosServerInstance } from "../../../lib/axios/axios-utils";
import { ApiStoreType } from "./atoms";

/**
 * Find copy config section information from config section
 * @param configSection config section
 * @returns copy config section information or undefined
 */
export function findCopyConfigSection(configSection?: string):
  | {
      copyConfigSection: string;
      copyConfigSectionLabelKey: string;
    }
  | undefined {
  // Find current config section to get import config section if defined
  const menu = findAdminMenu(configSection);

  if (menu && menu.copyConfigSection) {
    // Get import menu to get copy from page label
    const importMenu = findAdminMenu(menu.copyConfigSection);
    if (importMenu) {
      return {
        copyConfigSection: menu.copyConfigSection,
        copyConfigSectionLabelKey: importMenu.labelKey,
      };
    }
  }
}

/**
 * Find administration menu from config section
 * @param configSection config section
 * @returns adminitration menu or undefined
 */
export function findAdminMenu(configSection?: string): AdminMenuHref | undefined {
  for (const menu of adminMenu) {
    if (menu.hasChild) {
      const childMenu = menu.childs.find((child) => child.href === configSection);
      if (childMenu) {
        return childMenu;
      }
    } else {
      if (menu.href === configSection) {
        return menu;
      }
    }
  }
}

export const getUserInformation = async (
  jwtToken?: string,
): Promise<{ user: KpkApiUser; userGroups: KpkApiAssignedGroup[] | undefined }> => {
  if (isClientSide()) {
    throw new Error(
      'please don\'t use this client side function => instead use the "useCurrentUser" hook',
    );
  }
  const user = await getCurrentUser(
    getAxiosServerInstance({
      jwtToken,
    }),
  );
  const assignedGroups = await getUserAssignedGroups(
    getAxiosServerInstance({
      jwtToken,
    }),
    { userId: user.data.id },
  );
  return { user: user.data, userGroups: assignedGroups.data._embedded?.assignedGroup };
};

export const getConfigSectionLabel = (row: string): string => {
  let menuLabel: string | undefined;
  let sectionLabel: string | undefined;

  for (const menu of adminMenu) {
    if (menu.hasChild) {
      for (const child of menu.childs) {
        if (child.href === row) {
          menuLabel = getI18n().t(menu.labelKey) as string;
          sectionLabel = getI18n().t(child.labelKey) as string;
        }
      }
    } else {
      if (menu.href === row) {
        sectionLabel = getI18n().t(menu.labelKey) as string;
      }
    }
  }

  return `${menuLabel ? `${menuLabel} / ` : ""}${sectionLabel}`;
};

export const getDefaultValueForApiStoreData = (
  key: string,
  currentUi?: KpkApiUi | undefined,
): string => {
  if (key.includes("gdprUpdateDate") && currentUi?.gdprUpdateDate) {
    return currentUi?.gdprUpdateDate?.toString() ?? "";
  } else if (key.includes("gdprCurrentResources")) {
    return currentUi?.gdprCurrentResources ?? "";
  }
  return "";
};

export const resetApiStoreData = (
  setApiStore: (apiStore: ApiStoreType) => void,
  refresh: () => void,
): void => {
  refresh();
  setApiStore({});
};

export const addNewValueInApiStoreMap = (
  key: string,
  value: string,
  setApiStore: SetterOrUpdater<ApiStoreType>,
  setDataChange: (change: boolean) => void,
) => {
  setApiStore((store) => ({ ...store, [key]: value }));
  setDataChange(true);
};
