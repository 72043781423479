import { memo } from "react";

export type TreeEditOption<T> = {
  id: string;
  label: string;
  children?: TreeEditOption<T>[];
  data?: T;
};

export const genericMemo: <T>(component: T) => T = memo;
