export interface BusinessFilter {
    filters?: BusinessFilterFilter[];
}

export interface BusinessFilterFilter {
    hideFacetOccurrences?:      boolean;
    name?:                      string;
    orderDirectionFacetValues?: OrderDirectionFacetValues;
    orderTypeFacetValues?:      OrderTypeFacetValues;
    type?:                      Type;
    filters?:                   FilterFilter[];
    title?:                     Title[];
}

export interface FilterFilter {
    hideFacetOccurrences?:      boolean;
    name?:                      string;
    orderDirectionFacetValues?: OrderDirectionFacetValues;
    orderTypeFacetValues?:      OrderTypeFacetValues;
    type?:                      Type;
}

export enum OrderDirectionFacetValues {
    Asc = "ASC",
    Desc = "DESC",
}

export enum OrderTypeFacetValues {
    Alphabetic = "alphabetic",
    Occurrences = "occurrences",
}

export enum Type {
    Advanced = "advanced",
    Facet = "facet",
    FacetMulti = "facet-multi",
}

export interface Title {
    empty?:      boolean;
    language:    Language;
    ressources?: any;
    value?:      string;
}

export enum Language {
    De = "de",
    En = "en",
    Es = "es",
    Fr = "fr",
    Hu = "hu",
    It = "it",
    Nl = "nl",
    Pl = "pl",
    Pt = "pt",
    Ro = "ro",
}
