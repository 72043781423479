export enum Extension {
  TTF = "ttf",
  WOFF = "woff",
  OTF = "otf",
}

export enum FontContentType {
  TTF = "font/ttf",
  WOFF = "application/font-woff",
  OTF = "font/otf",
}

export const getContentType = (extension: Extension): FontContentType => {
  return FontContentType[extension.toUpperCase()];
};
