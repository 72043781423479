import React, { FC } from "react";

import { KModal, KModalContentSection, KModalHeader } from "@keepeek/refront-components";
import { useTranslation } from "react-i18next";

import { Type } from "../../../../../models/configuration/definitions/widget";
import { useInitConfig } from "../../../../../providers/config/hooks/init";
import { JsonConfig } from "../../../../commonPagePropsUtil";
import { dummyWidgetAttachments, dummyWidgetSheet } from "../utils";
import NoConfigurationWidgetSelector from "./NoConfigurationWidgetSelector";
import WidgetsBySection from "./WidgetsBySection";

type ModalWidgetSelectorProps = {
  open: boolean;
  onClose: () => void;
  onSelect: ({ type, id }: { type: Type; id: string }) => void;
};

const ModalWidgetSelector: FC<React.PropsWithChildren<ModalWidgetSelectorProps>> = function ({
  open,
  onClose,
  onSelect,
}) {
  const { t } = useTranslation();
  // Get whole config
  const { initConfig } = useInitConfig();
  // Filter by widgets by sections only
  const widgetsBySections: JsonConfig[] =
    initConfig?.config?.jsonConfig?.filter((jc) => jc.configSection.includes("components/")) ?? [];

  const hasConfiguredWidgets = widgetsBySections.length > 0;
  // Inject widgets without configuration after the check
  widgetsBySections.push(dummyWidgetSheet);
  widgetsBySections.push(dummyWidgetAttachments);

  if (!open) {
    return null;
  }

  return (
    <KModal
      onClose={onClose}
      open={open}
      header={
        <KModalHeader
          onClose={onClose}
          title={t("admin:uiWidget.custoWidgetSelector.actionSelect.label") as string}
        />
      }
    >
      {!hasConfiguredWidgets && <NoConfigurationWidgetSelector />}
      {hasConfiguredWidgets && (
        <KModalContentSection
          title={
            t("admin:uiWidget.custoWidgetSelector.modalWidgetSelector.content.title") as string
          }
          description={
            t(
              "admin:uiWidget.custoWidgetSelector.modalWidgetSelector.content.description",
            ) as string
          }
        >
          {widgetsBySections.map((wbs) => (
            <WidgetsBySection key={wbs.configSection} {...wbs} onSelect={onSelect} />
          ))}
        </KModalContentSection>
      )}
    </KModal>
  );
};

export default ModalWidgetSelector;
