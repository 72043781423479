import { BackendModule } from "i18next";

import logger from "../../../lib/logger-utils";
import { getAxiosClientInstance } from "../../axios/axios-utils";

const backend: BackendModule = {
  type: "backend",
  init() {},
  async read(language, namespace, callback) {
    try {
      const translationsResponse = await getAxiosClientInstance().get(
        `/api/locales/${language}/${namespace}`,
        { baseURL: location.origin },
      );
      if (translationsResponse.status === 200) {
        const translations = await translationsResponse.data;
        callback(null, translations);
      } else {
        logger.error(
          `Failed to get customer translations for namespace ${namespace} (language ${language})`,
        );
        callback(
          new Error(`Could'nt fetch translations : ${translationsResponse.statusText}`),
          false,
        );
      }
    } catch (e) {
      logger.error(
        `Failed to get customer translations for namespace ${namespace} (language ${language})`,
        e,
      );
      callback(e, false);
    }
  },
  // only used in backends acting as cache layer
  save(_language, _namespace, _data) {},
  create(_languages, _namespace, _key, _fallbackValue) {},
};

export default backend;
