import React, { forwardRef, useEffect, useRef } from "react";

import { Box } from "@mui/material";
import { Instance, VirtualElement } from "@popperjs/core";
import { Field, FieldProps } from "@rjsf/utils";

import { KIcon } from "../../../../../components/common/KIcon";
import { BusinessFilterFilter } from "../../../../../models/configuration/components/businessFilter";
import { StyledPopper } from "./index.styled";
import {
  getRJSFSchemaFilters,
  getRJSFSchemaSectionTitle,
  getRJSFUISchemaFilters,
  getRJSFUISchemaSectionTitle,
  isSectionTitle,
} from "./utils";

export const ManageFilterDetail: Field<BusinessFilterFilter> = forwardRef<
  HTMLElement,
  FieldProps<BusinessFilterFilter>
>(function ManageFilterDetail(props: FieldProps<BusinessFilterFilter>, ref) {
  const arrowRef = useRef();
  const popperRef = useRef<Instance>(null);
  const { SchemaField } = props.registry.fields;
  const businessFilterFilter = props.formData;
  const schema = isSectionTitle(businessFilterFilter)
    ? getRJSFSchemaSectionTitle(props.schema)
    : getRJSFSchemaFilters(props.schema);
  const uiSchema = isSectionTitle(businessFilterFilter)
    ? getRJSFUISchemaSectionTitle()
    : getRJSFUISchemaFilters(businessFilterFilter);

  // because of this BUG on the Popper MUI component: https://github.com/mui/material-ui/issues/33413
  // we cannot value "modifiers" directly to the constructor because this produces the behavior
  // next: when we modify the value of a select contained in the popper, there is automatically a "scroll to top"
  // which is triggered... :-(
  // As a workaround, we value the modifications in an independent rendering loop:
  useEffect(() => {
    if (!popperRef?.current) {
      return;
    }
    popperRef.current.setOptions((options) => ({
      ...options,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, isSectionTitle(businessFilterFilter) ? 60 : 33],
          },
        },
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            // add extra padding to avoid popper to go over validate "button" at bottom, and title of the popup:
            padding: { top: 220, bottom: 80 },
          },
        },
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef.current,
          },
        },
      ],
    }));
  }, [businessFilterFilter]);

  return (
    <StyledPopper
      businessFilterFilter={businessFilterFilter}
      placement="right"
      disablePortal={true}
      // due to an initial rendering problem
      // which does not position the popper
      // correctly the first time, we are forced
      // to keep the popper in the DOM and hide it with "display:none :
      open={true}
      anchorEl={ref as unknown as VirtualElement}
      popperRef={popperRef}
    >
      <Box className="icon" ref={arrowRef}>
        <KIcon baseClassName="fas" className="fa-circle-chevron-left" />
      </Box>

      <Box
        sx={{
          p: 2,
          border: (theme) => `1px solid ${theme.palette.primary.light}`,
          borderRadius: "3px",
        }}
      >
        {businessFilterFilter && <SchemaField {...props} uiSchema={uiSchema} schema={schema} />}
      </Box>
    </StyledPopper>
  );
});
