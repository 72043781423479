import { IDidomiObject } from "@didomi/react";
import { atom } from "recoil";

export const needDidomiState = atom<boolean>({
  key: "NeedDidomiState",
  default: false,
});

export const didomiState = atom<IDidomiObject | undefined>({
  key: "DidomiState",
  default: undefined,
});

// Didomi CWT (Consent Web Token) is typed "any"
export const didomiCWTState = atom<any | undefined>({
  key: "DidomiCWTState",
  default: undefined,
});

export const didomiNoticeVisible = atom<boolean>({
  key: "DidomiNoticeVisible",
  default: false,
});
