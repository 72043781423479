import React, { FC, useState } from "react";

import { KButton } from "@keepeek/refront-components";
import { Box, Chip, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import clsx from "clsx";

import { KIcon } from "../../../../components/common/KIcon";
import { Widget } from "../../../../models/configuration/definitions/widget";

const PREFIX = "ClassNamesWidgetSelector";

const classes = {
  root: `${PREFIX}-root`,
  chips: `${PREFIX}-chips`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.chips}`]: {
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
}));

type ClassNamesWidgetSelectorProps = {
  classNames: Widget["classNames"];
  onChange: (classNames: string | undefined) => void;
  label: string;
};

const ClassNamesWidgetSelector: FC<React.PropsWithChildren<ClassNamesWidgetSelectorProps>> =
  function ({ classNames, onChange, label }) {
    const [classEdit, setClassEdit] = useState(false);

    return (
      <StyledBox className={clsx("ClassNamesWidgetSelector", classes.root)}>
        {!classEdit && (
          <Box>
            <KButton startIcon={<KIcon>edit</KIcon>} onClick={() => setClassEdit(true)}>
              {label}
            </KButton>
          </Box>
        )}
        {classEdit && (
          <Box>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={classNames}
              onChange={({ target }) => onChange(target.value)}
              label={label}
            />
          </Box>
        )}
        {(classNames?.length ?? 0) > 0 && (
          <Box className={clsx("ClassNamesWidgetSelector-chips", classes.chips)}>
            {classNames?.split(" ").map((c, i) => (
              <Chip size="small" key={`${c}${i}`} label={c} />
            ))}
          </Box>
        )}
      </StyledBox>
    );
  };

export default ClassNamesWidgetSelector;
