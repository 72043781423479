import { KeycloakConfig } from "keycloak-js";
import { selector } from "recoil";

import { publicRuntimeConfigSelector } from "../../config/selectors/publicRuntimeConfig";

export const keycloakConfigSelector = selector<KeycloakConfig | undefined>({
  key: "keycloakConfigSelector",
  get: ({ get }) => {
    const publicRuntimeConfig = get(publicRuntimeConfigSelector);
    return {
      url: process.env.NEXT_PUBLIC_KEYCLOAK_URL,
      realm: publicRuntimeConfig.keycloak.realm || "",
      clientId: publicRuntimeConfig.keycloak.clientId || "",
    };
  },
});
