import React, { useEffect, useState } from "react";

import { KButton } from "@keepeek/refront-components";
import { Box, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Widget, WidgetProps } from "@rjsf/utils";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { KIcon } from "../../../../components/common/KIcon";

const PREFIX = "CustomWidgetUrlText";

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  input: `${PREFIX}-input`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    background: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },

  [`& .${classes.content}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },

  [`& .${classes.input}`]: {
    marginTop: theme.spacing(2),
  },
}));

/**
 *
 * Custom widget to display an non editable url.
 * Use for a deprecated field
 *
 */
const CustomWidgetUrlText: Widget = function ({ onChange, value, label }: WidgetProps) {
  const { t } = useTranslation();
  const [url, setUrl] = useState<string>(value);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (value !== undefined) {
      setUrl(value);
    }
  }, [onChange, value]);

  if (!url) {
    return <></>;
  }

  return (
    <StyledBox className={clsx("CustomWidgetUrlText", classes.root)}>
      <Box className={clsx("CustomWidgetUrlText-content", classes.content)}>
        <Box>
          <Typography variant="subtitle2">
            {label}: {value}
          </Typography>
          <Typography variant="body2">
            {t("admin:uiWidget.customWidgetUrlText.description.label")}
          </Typography>
        </Box>
        {!show && (
          <KButton
            variant="text"
            size="small"
            onClick={() => setShow(true)}
            title={t("admin:uiWidget.customWidgetUrlText.change.label") as string}
          >
            <KIcon>edit</KIcon>
          </KButton>
        )}
      </Box>
      {show && (
        <TextField
          label={label}
          value={url}
          onChange={({ target }) => onChange(target.value)}
          variant="outlined"
          fullWidth
          className={clsx("CustomWidgetUrlText-input", classes.input)}
        />
      )}
    </StyledBox>
  );
};

export default CustomWidgetUrlText;
