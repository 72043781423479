import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { JsonConfig } from "../../../lib/commonPagePropsUtil";
import { getAdminConfigSectionData } from "../../../lib/config/customer-config-utils";
import logger from "../../../lib/logger-utils";
import { TabsWidget } from "../../../models/configuration/components/tabsWidget";
import { Template } from "../../../models/configuration/definitions/template";
import { Type } from "../../../models/configuration/definitions/widget";
import { getWidgetConfigurationsByTemplates } from "../utils/getWidgetConfigurations";

import { KTabsWidgetProps } from ".";

export function getTabsWidgetConfiguration(
  configSections: JsonConfig[],
  widgetId: string,
): KTabsWidgetProps | undefined {
  const tabsWidgets = getAdminConfigSectionData<TabsWidget[]>(
    configSections,
    CONFIGURATION_SECTION_PATH.COMPONENTS_TABS_WIDGET,
  );

  if (tabsWidgets?.length === undefined || tabsWidgets.length === 0) {
    logger.debug(`getTabsWidgetConfiguration - tabsWidgets is empty or undefined`);
    return undefined;
  }

  const tabsWidget = tabsWidgets?.find((m) => m.id === widgetId);
  if (!tabsWidget) {
    logger.debug(`getTabsWidgetConfiguration - can't find tabs widget id ${widgetId}`);
    return undefined;
  }
  const ret: KTabsWidgetProps = {
    tabs: [],
    elementId: null, // will be valued after if needed (by injectElementIdInConfigurationIfNeeded function)
    type: Type.Tabs,
  };
  const tabsConfiguration = tabsWidget.tabs.map(({ templates }) =>
    getWidgetConfigurationsByTemplates({ configSections, templates }),
  );

  for (let i = 0; i < tabsWidget.tabs.length; i++) {
    ret.tabs.push({
      translations: tabsWidget.tabs[i].translation || [],
      widgetsConfiguration: tabsConfiguration[i],
      templatesConfiguration: tabsWidget.tabs[i].templates as any as Template[],
      textOnHover: tabsWidget.tabs[i].textOnHover || [],
    });
  }

  return ret;
}
