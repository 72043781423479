import { selectorFamily } from "recoil";

import {
  CurrentContentEdit,
  frontEditCurrentContentEditsState,
} from "../atoms/frontEditCurrentContentEdits";

export const getCurrentContentEditsSelector = selectorFamily<
  CurrentContentEdit | undefined,
  { endpoint?: string; data?: any }
>({
  key: "getCurrentContentEditsSelector",
  get:
    ({ endpoint }) =>
    ({ get }) => {
      return get(frontEditCurrentContentEditsState).find(
        (currentContentEdit) => currentContentEdit.endpoint === endpoint,
      );
    },
});
