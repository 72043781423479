import React, { FC } from "react";

import { KButton } from "@keepeek/refront-components";
import { Box } from "@mui/material";
import { ArrayFieldTemplateItemType } from "@rjsf/utils";
import { useDrag, useDrop, ConnectableElement } from "react-dnd";

import { KIcon } from "../../../../components/common/KIcon";

export const dragType = "ArrayFieldTemplatesItem";

const CustomArrayFieldTemplateItem: FC<
  React.PropsWithChildren<{
    item: ArrayFieldTemplateItemType;
    setHovered: (index: number | undefined) => void;
    hovered: boolean;
    name: string;
  }>
> = function ({ item, hovered, setHovered, name }) {
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: dragType + name,
      item,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (itemReceived, monitor) => {
        const { index } = itemReceived;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          // This reset the object to his original position if we drop it elsewhere
          item.onReorderClick(item.index, index)();
        }
      },
    }),
    [item],
  );

  const [, drop] = useDrop(
    () => ({
      accept: dragType + name,
      drop(dragged: ArrayFieldTemplateItemType) {
        const target = item;
        // On drop reorder the table
        item.onReorderClick(dragged.index, target.index)();
        setHovered(undefined);
      },
      hover(_dragged: ArrayFieldTemplateItemType) {
        const target = item;
        // On hover, set the hovered state
        setHovered(target.index);
      },
    }),
    [item],
  );

  const opacity = isDragging ? 0.2 : 1;

  return (
    <Box
      id={item.key}
      ref={(node) => {
        drop(node as ConnectableElement);
        preview(node as ConnectableElement);
      }}
      className="CustomArrayFieldTemplateItem"
      sx={{
        width: "100%",
        border: (theme) =>
          hovered
            ? `3px solid ${theme.palette.primary.main}`
            : `1px solid ${theme.palette.divider}`,
        display: "flex",
        transition: "all ease 250ms",
        opacity,
        "&:hover .CustomArrayFieldTemplateItem-Actions-Remove": {
          opacity: 1,
        },
      }}
    >
      <Box sx={{ padding: 2, flexGrow: 1, width: "100%" }}>{item.children}</Box>
      {(item.hasRemove || item.hasMoveDown || item.hasMoveUp) && (
        <Box
          className="CustomArrayFieldTemplateItem-Actions"
          ref={(node) => drag(node as ConnectableElement)}
          sx={{
            backgroundColor: (theme) => theme.palette.divider,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "stretch",
            position: "relative",
          }}
        >
          {item.hasMoveUp ? (
            <KButton
              className="CustomArrayFieldTemplateItem-Actions-MoveUp"
              size="small"
              onClick={item.onReorderClick(item.index, item.index - 1)}
              variant="outlined"
              sx={{ minWidth: 0 }}
            >
              <KIcon
                baseClassName="fas"
                className="fa-arrow-up"
                sx={{ fontSize: (theme) => theme.spacing(2) }}
              />
            </KButton>
          ) : (
            <Box />
          )}
          {(item.hasMoveDown || item.hasMoveUp) && (
            <Box
              ref={(node) => drag(node as ConnectableElement)}
              sx={{
                flexGrow: 1,
                cursor: "grab",
                minWidth: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "6px 8px",
              }}
            >
              <KIcon
                className="CustomArrayFieldTemplateItem-Actions-Move fa-grip"
                baseClassName="fas"
                sx={{ fontSize: (theme) => theme.spacing(2) }}
              />
            </Box>
          )}
          {item.hasMoveDown ? (
            <KButton
              className="CustomArrayFieldTemplateItem-Actions-MoveDown"
              size="small"
              onClick={item.onReorderClick(item.index, item.index + 1)}
              variant="outlined"
              sx={{ minWidth: 0 }}
            >
              <KIcon
                baseClassName="fas"
                className="fa-arrow-down"
                sx={{ fontSize: (theme) => theme.spacing(2) }}
              />
            </KButton>
          ) : (
            <Box />
          )}
          {item.hasRemove && (
            <Box
              className="CustomArrayFieldTemplateItem-Actions-Remove"
              sx={{
                marginTop: 2,
                opacity: 0,
                transition: "opacity ease 150ms",
              }}
            >
              <KButton
                sx={{ minWidth: 0, width: "100%" }}
                size="small"
                onClick={item.onDropIndexClick(item.index)}
                color="warning"
                variant="contained"
              >
                <KIcon
                  baseClassName="fas"
                  className="fa-trash"
                  sx={{
                    fontSize: (theme) => theme.spacing(2),
                  }}
                />
              </KButton>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CustomArrayFieldTemplateItem;
