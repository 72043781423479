import React, { useEffect, useState } from "react";

import { TextField } from "@mui/material";
import { Widget, WidgetProps } from "@rjsf/utils";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";

const CustomFieldId: Widget = function ({ value, label, onChange }: WidgetProps) {
  // Keep a separate state to have the UUID only rendered once
  const [id, setId] = useState<string>(value);

  useEffect(() => {
    if (value !== undefined) {
      setId(value);
    } else {
      // Assign an ID in case it's a new component
      const id = uuidv4();
      setId(id);
      // Use a setTimeout to trigger the update
      // to avoid triggering a change
      // when the form isn't ready to receive a state update
      // [produce a crash without it]
      setTimeout(() => {
        onChange(id);
      }, 100);
    }
  }, [onChange, value]);

  return (
    <TextField
      label={label}
      value={id}
      disabled
      variant="outlined"
      fullWidth
      className={clsx("CustomWidgetId-input")}
      sx={{
        display: "none",
      }}
    />
  );
};

export default CustomFieldId;
