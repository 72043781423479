import { Type, WidgetSchema } from "../../../models/configuration/definitions/template";
import { TemplateSchema } from "../../../models/configuration/pages/search";

export const getWidgetFromTemplateByType = ({
  template = [],
  widgetType,
}: {
  template: TemplateSchema[] | undefined;
  widgetType: Type;
}): WidgetSchema[] => {
  const widgets: WidgetSchema[] = [];

  template.forEach((t) =>
    t.columns?.forEach((c) =>
      c.widgets?.forEach((w) => {
        if (w.type === widgetType) {
          widgets.push(w);
        }
      }),
    ),
  );

  return widgets;
};

export const getFirstWidgetFromTemplateByType = ({
  template,
  widgetType,
}: {
  template: TemplateSchema[] | undefined;
  widgetType: Type;
}): WidgetSchema | undefined => {
  return getWidgetFromTemplateByType({ template, widgetType })?.[0];
};
