import { FC } from "react";

import { withSuspense } from "@keepeek/refront-components";

import { useStatsQueueListener } from "../../providers/stats/hooks/useStatsQueueListener";

/**
 * Component usefull for "suspense" to avoid flash of the whole app
 * @author JBO
 *
 * @returns {*}
 */
const StatsListener: FC = function () {
  // Stats queue listener, used to send stats with resolved count
  useStatsQueueListener();
  return null;
};
export default withSuspense(StatsListener);
