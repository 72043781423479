import { useCallback, useEffect } from "react";

import { businessFiltersConfiguration } from "@keepeek/commons";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { configSectionComponentBusinessFilterSelector } from "../../config/selectors";
import { businessFiltersConfigurationAdaptor } from "../../config/utils";
import { frontEditCurrentContentEditsState } from "../atoms/frontEditCurrentContentEdits";

/**
 * Hook allowing to synchronize the state of the front edit
 * with the atoms of the modified components.
 * Also handles the restoration of the initial state.
 */
export default function useFrontEditSync(): void {
  const frontEditCurrentContentEdits = useRecoilValue(frontEditCurrentContentEditsState);
  const setBusinessFiltersConfiguration = useSetRecoilState(businessFiltersConfiguration);
  const configSectionComponentBusinessFilter = useRecoilValue(
    configSectionComponentBusinessFilterSelector,
  );

  const resetBusinessFilterInitialState = useCallback(
    () =>
      setBusinessFiltersConfiguration(
        (configSectionComponentBusinessFilter &&
          configSectionComponentBusinessFilter.filters &&
          businessFiltersConfigurationAdaptor(configSectionComponentBusinessFilter.filters)) ||
          undefined,
      ),
    [configSectionComponentBusinessFilter, setBusinessFiltersConfiguration],
  );

  useEffect(() => {
    if (frontEditCurrentContentEdits.length === 0) {
      resetBusinessFilterInitialState();
      return;
    }
    frontEditCurrentContentEdits.forEach((content) => {
      switch (content.endpoint) {
        case CONFIGURATION_SECTION_PATH.COMPONENTS_BUSINESS_FILTER:
          setBusinessFiltersConfiguration(content.data.filters);
          break;
      }
    });
  }, [
    frontEditCurrentContentEdits,
    resetBusinessFilterInitialState,
    setBusinessFiltersConfiguration,
  ]);
}
