import { API_NOT_ALLOWED, isClientSide } from "@keepeek/commons";

import { Message } from "../../lib/commonPagePropsUtil";
import logger from "../../lib/logger-utils";

export function getMessagesToDisplay(queryParamErrorCode: string): Message[] {
  const ret: Message[] = [];

  if (queryParamErrorCode) {
    switch (queryParamErrorCode) {
      case API_NOT_ALLOWED:
        ret.push({
          autoHideDurationMs: 60000,
          type: "error",
          i18nKey: "error.api.notAllowed",
        });
        break;
      default:
        logger.debug(
          `queryParamErrorCode "${queryParamErrorCode}" is not implemented for messages to display`,
        );
        break;
    }
  }
  return ret;
}

/**
 * Google Analytics web vitals tracking
 */
export function reportWebVitals({ id, name, label, value }) {
  if (isClientSide() && typeof (window as any).gtag !== "undefined") {
    (window as any).gtag("event", name, {
      event_category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
      value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
      event_label: id, // id unique to current page load
      non_interaction: true, // avoids affecting bounce rate.
    });
  }
}

export enum PagesPath {
  ELEMENT_PAGE = "/element",
  SHARE_ELEMENT_PAGE = "/share/[tokenId]/element/[sharedMediaId]",
  HOME_PAGE = "/home",
  SEARCH_PAGE = "/search",
  SHARE_SEARCH_PAGE = "/share/[tokenId]",
  LANDING_PAGE = "/",
  REDIRECT = "/redirect",
  ERROR_404 = "/404",
  ADMIN = "/admin",
  ONBOARDING = "/admin/onboarding",
  FIRST_LOGIN = "/first-login",
  BASKETS = "/baskets",
  BASKET_ELEMENT_PAGE = "/baskets/[basketId]",
}

export enum PageName {
  HOME_PAGE = "HOME",
  SEARCH_PAGE = "SEARCH",
  SHARE_SEARCH_PAGE = "SHARE_SEARCH",
  LANDING_PAGE = "LANDING",
  ELEMENT_PAGE = "ELEMENT",
  SHARE_ELEMENT_PAGE = "SHARE_ELEMENT_PAGE",
  ADMIN = "ADMIN",
  FIRST_LOGIN = "FIRST_LOGIN",
  BASKETS = "BASKETS",
  BASKET_ELEMENT_PAGE = "BASKET_ELEMENT_PAGE",
}
