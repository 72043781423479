import { removeUndefinedDeep } from "@keepeek/commons";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { JsonConfig } from "../../../lib/commonPagePropsUtil";
import { getAdminConfigSectionData } from "../../../lib/config/customer-config-utils";
import logger from "../../../lib/logger-utils";
import { SpacerWidget } from "../../../models/configuration/components/spacerWidget";
import { Type } from "../../../models/configuration/definitions/widget";

import { SpacerWidgetProps } from ".";

export function getSpacerWidgetConfiguration(
  customerSectionConfiguration: JsonConfig[],
  widgetId: SpacerWidget["id"],
): SpacerWidgetProps | undefined {
  const spacerWidgets = getAdminConfigSectionData<SpacerWidget[]>(
    customerSectionConfiguration,
    CONFIGURATION_SECTION_PATH.COMPONENTS_SPACER_WIDGET,
  );

  if (spacerWidgets?.length === undefined || spacerWidgets.length === 0) {
    logger.debug(`getSpacerWidgetConfiguration - spacerWidgets is empty or undefined`);
    return;
  }

  const spacerWidget: SpacerWidget | undefined = spacerWidgets?.find((sw) => sw.id === widgetId);

  if (!spacerWidget) {
    logger.debug(`getSpacersWidgetConfiguration - can't find spacerWidgets id ${widgetId}`);
    return;
  }

  // For SSR, do not return any object undefined cause it can't be used in JSON
  const config: SpacerWidgetProps = {
    widgetId: spacerWidget.id,
    ...spacerWidget,
    type: Type.Spacer,
  };

  // Simply spread spacerWidget then remove id from it
  delete config.id;

  return removeUndefinedDeep(config);
}
