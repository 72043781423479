import React, { useEffect, useState } from "react";

import { KpkApiFormType } from "@keepeek/api-client";
import { formsQuerySelector } from "@keepeek/commons";
import {
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import { Widget, WidgetProps } from "@rjsf/utils";
import sortBy from "lodash/sortBy";
import uniqueId from "lodash/uniqueId";
import { useTranslation } from "react-i18next";
import { useRecoilValueLoadable } from "recoil";

const CustomFormsSelectWidget: Widget = function ({
  label,
  onChange,
  required,
  value,
}: WidgetProps) {
  const formsQuery = useRecoilValueLoadable(formsQuerySelector);
  const [formType, setFormType] = useState<KpkApiFormType | undefined>();
  const forms =
    formsQuery.state === "hasValue"
      ? sortBy(
          formsQuery.contents?.filter((f) => {
            if (formType) {
              return f.type === formType;
            } else {
              return true;
            }
          }),
          ["title"],
        )
      : undefined;
  const formTypeList = Object.values(KpkApiFormType).filter(
    (type) => type !== KpkApiFormType.RICH_MEDIA,
  );
  // during the initial load, allows to determine the type of form from the form
  useEffect(() => {
    const form = forms?.find((f) => f.id === value);
    if (form && formType !== form.type) {
      setFormType(form.type);
    }
  }, [formType, forms, value]);
  const loading = formsQuery.state === "loading";
  const { t } = useTranslation();

  if (loading) {
    return <LinearProgress />;
  }
  const selectFormTypeHtmlId = uniqueId("custom-form-select-type");
  const selectFormIdHtmlId = uniqueId("custom-form-select-id");
  const formTypeLabel = `${t("admin:formsSelectWidget.formType.label")}${required && "*"}`;
  const formNameLabel = `${t("admin:formsSelectWidget.formName.label")}${required && "*"}`;
  return (
    <Grid container spacing={2}>
      {label && (
        <Grid item xs={12}>
          <FormLabel>{label}</FormLabel>
        </Grid>
      )}
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id={selectFormTypeHtmlId}>{formTypeLabel}</InputLabel>
          <Select
            value={formType || ""}
            onChange={({ target }) => setFormType(target.value as KpkApiFormType)}
            label={formTypeLabel}
            id={selectFormTypeHtmlId}
          >
            {formTypeList.map((ft) => (
              <MenuItem key={ft} value={ft}>
                {t(`admin:formsSelectWidget.formType.enum.${ft.toUpperCase()}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {formType && forms && forms.length > 0 && (
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id={selectFormIdHtmlId}>{formNameLabel}</InputLabel>
            <Select
              value={value || ""}
              onChange={({ target }) => onChange(target.value)}
              label={formNameLabel}
              id={selectFormIdHtmlId}
            >
              {forms.map((form) => (
                <MenuItem key={form.id} value={form.id}>
                  {form.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

export default CustomFormsSelectWidget;
