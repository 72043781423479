import React from "react";

import { KButton, KModalContentSection } from "@keepeek/refront-components";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import {
  WidgetAdminLabelKeys,
  CONFIGURATION_SECTION_PATH,
} from "../../../../../components/layout/adminMenu";
import { Type } from "../../../../../models/configuration/definitions/widget";
import { WidgetCardSelectorType } from "../WidgetCardSelector";

type firstWidget = { url: string; title: string } & WidgetCardSelectorType;

// This Component take care to print a empty state
// If we have no widget configured yet we will show this content
const NoConfigurationWidgetSelector = function () {
  const { t } = useTranslation();
  const router = useRouter();
  const widgetHeader: firstWidget = {
    url: `/admin/${CONFIGURATION_SECTION_PATH.COMPONENTS_K_HEADER_WIDGET}`,
    type: Type.Header,
    id: "header",
    title: t(WidgetAdminLabelKeys.Header),
  };

  const widgetFooter: firstWidget = {
    url: `/admin/${CONFIGURATION_SECTION_PATH.COMPONENTS_FOOTER_WIDGET}`,
    type: Type.Footer,
    id: "footer",
    title: t(WidgetAdminLabelKeys.Footer),
  };

  const widgetSearch: firstWidget = {
    url: `/admin/${CONFIGURATION_SECTION_PATH.PAGES_SEARCH}`,
    type: Type.Search,
    id: "search",
    title: t(WidgetAdminLabelKeys.Search),
  };

  const widgetEdito: firstWidget = {
    url: `/admin/${CONFIGURATION_SECTION_PATH.COMPONENTS_EDITO_WIDGET}`,
    type: Type.Edito,
    id: "edito",
    title: t(WidgetAdminLabelKeys.Edito),
  };

  const firstWidgets: firstWidget[] = [widgetHeader, widgetFooter, widgetSearch, widgetEdito];
  return (
    <KModalContentSection
      title={t("admin:uiWidget.custoWidgetSelector.modalWidgetSelector.noContent.title")}
      description={t(
        "admin:uiWidget.custoWidgetSelector.modalWidgetSelector.noContent.description",
      )}
    >
      {firstWidgets.map((w) => (
        <KButton
          variant="outlined"
          style={{ marginRight: "1rem" }}
          key={w.id}
          title={w.title}
          onClick={() => router.push(w.url)}
        >
          {w.title}
        </KButton>
      ))}
    </KModalContentSection>
  );
};

export default NoConfigurationWidgetSelector;
