import { removeUndefinedDeep } from "@keepeek/commons";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { JsonConfig } from "../../../lib/commonPagePropsUtil";
import { getAdminConfigSectionData } from "../../../lib/config/customer-config-utils";
import logger from "../../../lib/logger-utils";
import { BasketsWidget } from "../../../models/configuration/components/basketsWidget";
import { Type } from "../../../models/configuration/definitions/widget";

import { BasketsWidgetProps } from ".";

export const getBasketsWidgetConfiguration = (
  customerSectionConfiguration: JsonConfig[],
  widgetId: string,
): BasketsWidgetProps | undefined => {
  const basketsWidgets = getAdminConfigSectionData<BasketsWidget[]>(
    customerSectionConfiguration,
    CONFIGURATION_SECTION_PATH.COMPONENTS_BASKETS_WIDGET,
  );

  if (basketsWidgets?.length === undefined || basketsWidgets.length === 0) {
    logger.debug(`getEditoWidgetConfiguration - basketsWidgets is empty or undefined`);
    return;
  }

  const basketsWidget = basketsWidgets?.find((e) => e.id === widgetId);

  if (!basketsWidget) {
    logger.debug(`getEditoWidgetConfiguration - can't find baskets widget id ${widgetId}`);
    return;
  }

  // For SSR, do not return any object undefined cause it can't be used in JSON
  const configuration: BasketsWidgetProps = {
    widgetId: basketsWidget.id,
    type: Type.Baskets,
    create: basketsWidget.create,
    order: basketsWidget.order,
    paginate: basketsWidget.paginate,
    search: basketsWidget.search,
    backgroundColor: basketsWidget.backgroundColor,
    title: basketsWidget.title,
    tip: basketsWidget.tip,
  };

  return removeUndefinedDeep(configuration);
};
