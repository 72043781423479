export interface KNewsWidget {
    backgroundColor?:      string;
    backgroundTextColor?:  string;
    backgroundTextEffect?: BackgroundTextEffect;
    borderColor?:          string;
    borderRadius?:         string;
    button?:               Button;
    chip?:                 Chip;
    content?:              TranslationSchema[];
    displayMode?:          DisplayMode;
    id:                    string;
    image?:                Image;
    imagePosition?:        ImagePosition;
    imageWidthAdaptation?: boolean;
    marginBottom?:         string;
    marginTop?:            string;
    padding?:              string;
    url?:                  URL;
    verticalTextAlign?:    VerticalTextAlign;
}

export enum BackgroundTextEffect {
    BottomEffect = "BOTTOM_EFFECT",
    NoBackground = "NO_BACKGROUND",
    TopEffect = "TOP_EFFECT",
    Unicolor = "UNICOLOR",
}

export interface Button {
    backgroundColor?: string;
    borderColor?:     string;
    borderRadius?:    string;
    hoverColor?:      string;
    icon?:            string;
    iconPosition?:    IconPosition;
    label?:           TranslationSchema[];
    labelColor?:      string;
    position?:        Position;
    size?:            string;
}

export enum IconPosition {
    Left = "LEFT",
    Right = "RIGHT",
}

export interface TranslationSchema {
    empty?:      boolean;
    language:    Language;
    ressources?: any;
    value?:      string;
}

export enum Language {
    De = "de",
    En = "en",
    Es = "es",
    Fr = "fr",
    Hu = "hu",
    It = "it",
    Nl = "nl",
    Pl = "pl",
    Pt = "pt",
    Ro = "ro",
}

export enum Position {
    Center = "center",
    Left = "left",
    Right = "right",
}

export interface Chip {
    backgroundColorOutlined?: string;
    borderRadius?:            string;
    color?:                   string;
    label?:                   TranslationSchema[];
    labelColor?:              string;
    position?:                Position;
    style?:                   Style;
}

export enum Style {
    Filled = "filled",
    Outlined = "outlined",
}

export enum DisplayMode {
    Article = "ARTICLE",
    Image = "IMAGE",
}

export interface Image {
    alt?:          TranslationSchema[];
    animation?:    Animation;
    borderRadius?: string;
    minHeight?:    string;
    url?:          TranslationResourceSchema[];
}

export interface Animation {
    animationType?: AnimationType;
}

export enum AnimationType {
    NoAnimation = "NO_ANIMATION",
    ZoomOnHover = "ZOOM_ON_HOVER",
}

export interface TranslationResourceSchema {
    language:  Language;
    resource?: Resource;
    value?:    string;
}

export interface Resource {
    id?:  string;
    url?: string;
}

export enum ImagePosition {
    ImageBottom = "IMAGE_BOTTOM",
    ImageLeft = "IMAGE_LEFT",
    ImageRight = "IMAGE_RIGHT",
    ImageTop = "IMAGE_TOP",
}

export interface URL {
    folderId?:    number;
    href?:        string;
    linkType?:    LinkType;
    targetBlank?: boolean;
    title?:       string;
}

export enum LinkType {
    Didomi = "DIDOMI",
    Folder = "FOLDER",
    Mail = "MAIL",
    URL = "URL",
}

export enum VerticalTextAlign {
    Bottom = "BOTTOM",
    Center = "CENTER",
    Top = "TOP",
}
