import { ReactNode, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { PageName } from "../../../containers/App/utils";
import { Template } from "../../../models/configuration/definitions/template";
import { Element } from "../../../models/configuration/pages/element";
import { Home } from "../../../models/configuration/pages/home";
import { Search } from "../../../models/configuration/pages/search";
import {
  getDefaultBasketFooterTemplates,
  getDefaultBasketHeaderTemplates,
} from "../../basket/utils";
import { useConfiguration } from "../../config/hooks";
import { isTemplatesConfiguredWithWidget } from "../../config/utils";
import { CurrentEditKey } from "../atoms/frontEditCurrentEditKeys";
import { EditName } from "../models";
import { useFrontEditContentList } from "./useFrontEditContentList";
import useFrontEditWidgetList from "./useFrontEditWidgetList";

export type SectionEditable = {
  title?: string;
  editableContents: ReactNode[];
};

type UseFrontEditContentEditable = {
  sectionsEditable: SectionEditable[];
};

const mapBetweenContentPageKeyAndCurrentEditKeys = (key: PageName | EditName): CurrentEditKey[] => {
  switch (key) {
    case PageName.LANDING_PAGE:
      return [
        {
          configSection: CONFIGURATION_SECTION_PATH.LAYOUT_LANDING,
        },
        {
          configSection: CONFIGURATION_SECTION_PATH.PAGES_LANDING,
        },
      ];
    case EditName.THEME:
      return [
        { configSection: CONFIGURATION_SECTION_PATH.GLOBAL },
        { configSection: CONFIGURATION_SECTION_PATH.THEME },
      ];
    default:
      return [];
  }
};

export default function useFrontEditContentEditable(
  key: PageName | EditName,
): UseFrontEditContentEditable {
  const { t } = useTranslation();
  const homeConfiguration = useConfiguration<Home>(CONFIGURATION_SECTION_PATH.PAGES_HOME);
  const searchConfiguration = useConfiguration<Search>(CONFIGURATION_SECTION_PATH.PAGES_SEARCH);
  const elementConfiguration = useConfiguration<Element>(CONFIGURATION_SECTION_PATH.PAGES_ELEMENT);
  const basketsConfiguration = useConfiguration<Element>(CONFIGURATION_SECTION_PATH.PAGES_BASKETS);
  const basketDetailConfiguration = useConfiguration<Element>(
    CONFIGURATION_SECTION_PATH.PAGES_BASKET_ELEMENT,
  );

  // Widgets are already loaded so we can use getter fonctions without keys system
  const { getWidgetsFromTemplate, getWidgetsFromTemplates } = useFrontEditWidgetList();

  // Use keys system here because we need to load content from API where widgets does not need it
  const { contentFromAdminSections } = useFrontEditContentList({
    keys: mapBetweenContentPageKeyAndCurrentEditKeys(key),
  });

  const sectionsEditable: SectionEditable[] = useMemo(() => {
    switch (key) {
      case PageName.HOME_PAGE:
        const homePageTemplates = homeConfiguration?.templates as Template[];
        return [
          {
            title: t("front-edit.section.widget.label") as string,
            editableContents: getWidgetsFromTemplate(homePageTemplates),
          },
        ];
      case PageName.SEARCH_PAGE:
        const searchPageTemplates: Template[][] = [
          searchConfiguration?.templates as Template[],
          searchConfiguration?.templatesFooter as Template[],
        ];
        return [
          {
            title: t("front-edit.section.widget.label") as string,
            editableContents: getWidgetsFromTemplates(searchPageTemplates),
          },
        ];
      case PageName.ELEMENT_PAGE:
        const elementPageTemplates: Template[][] = [
          elementConfiguration?.templates as Template[],
          elementConfiguration?.templatesFooter as Template[],
          elementConfiguration?.templatesLayout as Template[],
        ];
        return [
          {
            title: t("front-edit.section.widget.label") as string,
            editableContents: getWidgetsFromTemplates(elementPageTemplates),
          },
        ];
      case PageName.BASKET_ELEMENT_PAGE:
        const basketDetailPageTemplates: Template[][] = [
          isTemplatesConfiguredWithWidget(basketDetailConfiguration?.templates as Template[])
            ? (basketDetailConfiguration?.templates as Template[])
            : [getDefaultBasketHeaderTemplates(searchConfiguration)],
          isTemplatesConfiguredWithWidget(basketDetailConfiguration?.templatesFooter as Template[])
            ? (basketDetailConfiguration?.templatesFooter as Template[])
            : [getDefaultBasketFooterTemplates(searchConfiguration)],
        ];
        return [
          {
            title: t("front-edit.section.widget.label") as string,
            editableContents: getWidgetsFromTemplates(basketDetailPageTemplates),
          },
        ];
      case PageName.BASKETS:
        const basketsPageTemplates: Template[][] = [
          isTemplatesConfiguredWithWidget(basketsConfiguration?.templates as Template[])
            ? (basketsConfiguration?.templates as Template[])
            : [
                getDefaultBasketHeaderTemplates(searchConfiguration),
                getDefaultBasketFooterTemplates(searchConfiguration),
              ],
        ];
        return [
          {
            title: t("front-edit.section.widget.label") as string,
            editableContents: getWidgetsFromTemplates(basketsPageTemplates),
          },
        ];
      case PageName.LANDING_PAGE:
      case EditName.THEME:
        return contentFromAdminSections;
      default:
        return [];
    }
  }, [
    basketDetailConfiguration?.templates,
    basketDetailConfiguration?.templatesFooter,
    basketsConfiguration,
    contentFromAdminSections,
    elementConfiguration?.templates,
    elementConfiguration?.templatesFooter,
    elementConfiguration?.templatesLayout,
    getWidgetsFromTemplate,
    getWidgetsFromTemplates,
    homeConfiguration?.templates,
    key,
    searchConfiguration,
    t,
  ]);

  return {
    sectionsEditable,
  };
}
