import React, { FC } from "react";

import { Box, Container, Paper, TextField } from "@mui/material";
import { FieldProps } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { Link } from "../../../../models/configuration/components/footerWidget";
import { LinkType } from "../../../../models/configuration/components/kImageWidget";
import { frontEditState } from "../../../../providers/frontEdit/atoms/frontEdit";
import LinkTypeSelector from "../components/LinkTypeSelector";
import MailLink, { MailLinkData } from "../components/MailLink";
import UrlLink from "../components/UrlLink";
import { convertToMailToFormat, extractToMailLinkData } from "../utils/custom-links-field-utils";

const CustomFooterLinksField: FC<React.PropsWithChildren<FieldProps<Link>>> = function ({
  formData,
  onChange,
}) {
  const { t } = useTranslation("admin");
  const allowedLinkTypes = Object.values(LinkType).filter((type) => type !== LinkType.Folder);
  const type: Link["linkType"] =
    (formData?.linkType && (formData?.linkType as LinkType)) || LinkType.URL;
  const href: Link["href"] = formData?.href;
  const label: Link["label"] = formData?.label || "";
  const openInNewTab: Link["openInNewTab"] = formData?.openInNewTab;
  const mailLinkData: MailLinkData = extractToMailLinkData(href);
  const frontEdit = useRecoilValue(frontEditState);

  const handleChange = (data: Link) => onChange(data);

  return (
    <Container
      maxWidth="xl"
      sx={{
        padding: 4,
        margin: 0,
      }}
      component={Paper}
    >
      <Box>
        <TextField
          label={t("label")}
          value={label}
          onChange={(event) =>
            handleChange({
              ...formData,
              label: event.target.value,
            })
          }
          fullWidth
          margin="normal"
        />
        <LinkTypeSelector
          allowedLinkTypes={allowedLinkTypes}
          type={type}
          onChange={(data) =>
            handleChange({
              ...formData,
              label: formData?.label || "",
              linkType: data,
              href: data === LinkType.Mail || data === LinkType.URL ? "" : formData?.href,
            })
          }
          frontEdit={frontEdit}
        />
        {type === LinkType.URL && (
          <UrlLink
            onUrlChange={(data) =>
              handleChange({
                ...formData,
                label: formData?.label || "",
                href: data,
              })
            }
            onOpenInNewTabChange={(data) =>
              handleChange({
                ...formData,
                label: formData?.label || "",
                openInNewTab: data,
              })
            }
            openInNewTab={(openInNewTab || false) as boolean}
            url={href}
          />
        )}
        {type === LinkType.Mail && (
          <MailLink
            recipients={mailLinkData.recipients}
            body={mailLinkData.body}
            subject={mailLinkData.subject}
            onChange={(data) =>
              handleChange({
                ...formData,
                label: formData?.label || "",
                href: convertToMailToFormat(data),
              })
            }
          />
        )}
      </Box>
    </Container>
  );
};

export default CustomFooterLinksField;
