import React from "react";

import { FieldFolder } from "@keepeek/refront-components";
import { Container, FormControl, Paper, TextField, Typography } from "@mui/material";
import { Field } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { LinkType } from "../../../../models/configuration/components/kImageWidget";
import { MosaicElementSchema } from "../../../../models/configuration/components/mosaicElementSchema";
import { frontEditState } from "../../../../providers/frontEdit/atoms/frontEdit";
import LinkTypeSelector from "../components/LinkTypeSelector";
import MailLink, { MailLinkData } from "../components/MailLink";
import UrlLink from "../components/UrlLink";
import CustomSelectResource from "../CustomSelectResource";
import { convertToMailToFormat, extractToMailLinkData } from "../utils/custom-links-field-utils";

const CustomMosaicElementField: Field = function ({ schema, uiSchema, formData, onChange }, props) {
  const { t } = useTranslation("admin");
  const frontEdit = useRecoilValue(frontEditState);
  const allowedLinkTypes = [LinkType.Folder, LinkType.URL, LinkType.Mail];
  const targetBlank: boolean = formData?.targetBlank ?? false;
  const href: MosaicElementSchema["href"] = formData?.href ?? "";
  const folderId: MosaicElementSchema["folderId"] = formData?.folderId;
  const type: LinkType =
    (formData?.linkType && (formData?.linkType as LinkType)) ?? LinkType.Folder;
  const mailLinkData: MailLinkData = extractToMailLinkData(href);
  const { id, url, title } = formData;

  const handleChange = (data: any) => onChange(data);
  return (
    <Container
      maxWidth="xl"
      sx={{
        padding: 4,
        margin: 0,
      }}
      component={Paper}
    >
      <Typography variant="h6">
        {schema.title ? t(schema.title) : t("components.mosaicElement.url.label")}
      </Typography>
      <LinkTypeSelector
        allowedLinkTypes={allowedLinkTypes}
        type={type}
        onChange={(data) =>
          handleChange({
            ...formData,
            linkType: data,
            href: data === LinkType.Mail || data === LinkType.URL ? "" : formData.href,
          })
        }
        frontEdit={frontEdit}
      />
      {type === LinkType.URL && (
        <>
          <TextField
            label={t("components.mosaicElement.title.label")}
            value={title}
            onChange={(event) =>
              handleChange({
                ...formData,
                title: event.target.value,
              })
            }
            fullWidth
            margin="normal"
          />
          <CustomSelectResource
            schema={schema}
            formData={{ id, url }}
            onChange={(data) =>
              handleChange({
                ...formData,
                id: data.id,
                url: data.url,
              })
            }
            uiSchema={uiSchema}
            {...props}
          />
          <UrlLink
            onUrlChange={(data) =>
              handleChange({
                ...formData,
                href: data,
              })
            }
            onOpenInNewTabChange={(data) =>
              handleChange({
                ...formData,
                targetBlank: data,
              })
            }
            openInNewTab={targetBlank}
            url={href}
          />
        </>
      )}
      {type === LinkType.Mail && (
        <>
          <TextField
            label={t("components.mosaicElement.title.label")}
            value={title}
            onChange={(event) =>
              handleChange({
                ...formData,
                title: event.target.value,
              })
            }
            fullWidth
            margin="normal"
          />
          <CustomSelectResource
            schema={schema}
            formData={{ id, url }}
            onChange={(data) =>
              handleChange({
                ...formData,
                id: data.id,
                url: data.url,
              })
            }
            uiSchema={uiSchema}
            {...props}
          />
          <MailLink
            recipients={mailLinkData.recipients}
            body={mailLinkData.body}
            subject={mailLinkData.subject}
            onChange={(data) =>
              handleChange({
                ...formData,
                href: convertToMailToFormat(data),
              })
            }
          />
        </>
      )}
      {type === LinkType.Folder && (
        <FormControl fullWidth>
          <FieldFolder
            helperText={t("components.mosaicElement.folder.description")}
            title=""
            forceSelection={true}
            multiple={false}
            initialFolderIds={folderId ? [folderId] : []}
            onChange={(values) =>
              handleChange({
                ...formData,
                folderId: values && values.length > 0 ? values[0] : undefined,
              })
            }
          />
        </FormControl>
      )}
    </Container>
  );
};

export default CustomMosaicElementField;
