import {
  customerCommonOverrideAtom,
  FetcherMode,
  fetcherModeAtom,
  fetcherTokenIdAtom,
  getAxiosInstanceAtom,
  getLoggerAtom,
  REGISTRATION_FORM_URL,
  registrationFormUrlPathState,
  useShare,
} from "@keepeek/commons";
import { useRouter } from "next/router";
import { SetRecoilState } from "recoil";

import { getAxiosClientInstance } from "../../lib/axios/axios-utils";
import { getCustomerConfig } from "../../lib/config/customer-config-utils";
import logger from "../../lib/logger-utils";

export const useMandatoryInitialRecoilState = (): {
  setMandatoryInitializeRecoilState: (set: SetRecoilState) => void;
} => {
  const router = useRouter();
  const { isShare, tokenId } = useShare(router.pathname, router.query.tokenId);

  function setMandatoryInitializeRecoilState(set: SetRecoilState) {
    // Set logger mandatory information:
    set(getLoggerAtom, () => () => logger as unknown as Console);
    // Set axios mandatory information:
    set(getAxiosInstanceAtom, () => () => getAxiosClientInstance());
    // Set fetcher mandatory information:
    let mode = FetcherMode.LOGGED;
    if (isShare) {
      mode = FetcherMode.SHARE;
    }
    set(fetcherModeAtom, mode);
    set(fetcherTokenIdAtom, tokenId);
    set(customerCommonOverrideAtom, getCustomerConfig().overrides);

    // Set the registration Form URL
    const regex = /^https:\/\/.*-qualification-refront\.keepeek\.com/;
    if (regex.test(location.origin) || process.env.NODE_ENV === "development") {
      set(registrationFormUrlPathState, REGISTRATION_FORM_URL.DEV);
    } else {
      set(registrationFormUrlPathState, REGISTRATION_FORM_URL.PROD);
    }
  }

  return {
    setMandatoryInitializeRecoilState,
  };
};
