import React, { useEffect, useState } from "react";

import { KButton } from "@keepeek/refront-components";
import { Box, TextField, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { Widget, WidgetProps } from "@rjsf/utils";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { KIcon } from "../../../../components/common/KIcon";

const CustomWidgetId: Widget = function ({ onChange, value, label }: WidgetProps) {
  const { t } = useTranslation();
  // Keep a separate state to have the UUID only rendered once
  const [id, setId] = useState<string>(value);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (value !== undefined) {
      setId(value);
    } else {
      // Assign an ID in case it's a new component
      const id = uuidv4();
      setId(id);
      // Use a setTimeout to trigger the update
      // to avoid triggering a change
      // when the form isn't ready to receive a state update
      // [produce a crash without it]
      setTimeout(() => {
        onChange(id);
      }, 100);
    }
  }, [onChange, value]);

  return (
    <Box
      className={clsx("CustomWidgetId")}
      sx={{
        background: (theme: Theme) => theme.palette.grey[100],
        borderRadius: 1,
        padding: 1,
      }}
    >
      <Box
        className={clsx("CustomWidgetId-content")}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box>
          <Typography variant="subtitle2">
            {t("admin:uiWidget.custoWidgetId.title.label", { id })}
          </Typography>
          <Typography variant="body2">
            {t("admin:uiWidget.custoWidgetId.description.label", { id })}
          </Typography>
        </Box>
        {!show && (
          <KButton
            variant="text"
            size="small"
            onClick={() => setShow(true)}
            title={t("admin:uiWidget.custoWidgetId.change.label", { id }) as string}
          >
            <KIcon>edit</KIcon>
          </KButton>
        )}
      </Box>
      {show && (
        <TextField
          label={label}
          value={id}
          onChange={({ target }) => onChange(target.value)}
          variant="outlined"
          fullWidth
          className={clsx("CustomWidgetId-input")}
          sx={{
            marginTop: 2,
          }}
        />
      )}
    </Box>
  );
};

export default CustomWidgetId;
