import { useEffect, useState } from "react";

import { SanitizeHtml } from "@keepeek/refront-components";
import { Box, Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Widget, WidgetProps } from "@rjsf/utils";

const PREFIX = "CustomCheckbox";

const classes = {
  box: `${PREFIX}-box`,
  div: `${PREFIX}-div`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.box}`]: {
    marginLeft: theme.spacing(-1.4),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  [`& .${classes.div}`]: {
    fontSize: theme.typography.body1.fontSize,
  },
}));

const CustomCheckbox: Widget = function ({ label, value, id, onChange }: WidgetProps) {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (value !== undefined) {
      setChecked(value);
    }
  }, [value]);

  return (
    <StyledBox className={classes.box}>
      <Checkbox
        id={id}
        checked={checked}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      />
      <SanitizeHtml className={classes.div} html={label} />
    </StyledBox>
  );
};

export default CustomCheckbox;
