import { selector } from "recoil";

import { getAxiosClientInstance } from "../../../lib/axios/axios-utils";
import { PublicRuntimeConfig } from "../../../lib/config/publicRuntimeConfigUtils";

export const publicRuntimeConfigSelector = selector<PublicRuntimeConfig>({
  key: "PublicRuntimeConfigSelector",
  get: async () => {
    const { data } = await getAxiosClientInstance().get("/api/publicRuntimeConfig", {
      baseURL: location.origin,
    });
    return data as PublicRuntimeConfig;
  },
});
