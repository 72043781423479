import { atom } from "recoil";

export type HeaderMetas = {
  title?: string;
  description?: string;
  og?: {
    title?: string;
    type?: string;
    url?: string;
    image?: string;
    description?: string;
    siteName?: string;
  };
};

export const headerMetasState = atom<HeaderMetas>({
  key: "HeaderMetasState",
  default: { title: undefined, description: undefined, og: undefined },
});
