import React, { useState } from "react";

import { KButton } from "@keepeek/refront-components";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Field, FieldProps } from "@rjsf/utils";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { Type, Widget } from "../../../../models/configuration/definitions/widget";
import { useInitConfig } from "../../../../providers/config/hooks/init";
import { JsonConfig } from "../../../commonPagePropsUtil";
import ClassNamesWidgetSelector from "./ClassNamesWidgetSelector";
import ModalWidgetSelector from "./ModalWidgetSelector";
import WidgetsBySection from "./ModalWidgetSelector/WidgetsBySection";
import {
  convertWidgetConfigSectionToWidgetType,
  dummyWidgetAttachments,
  dummyWidgetSheet,
  dummyWidgetCustom,
} from "./utils";

const PREFIX = "CustomWidgetSelector";

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  contentTitle: `${PREFIX}-contentTitle`,
  contentAction: `${PREFIX}-contentAction`,
};

const StyledCustomWidgetSelector = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    background: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },

  [`& .${classes.content}`]: {
    display: "flex",
    gap: theme.spacing(2),
    flexWrap: "wrap",
  },

  [`& .${classes.contentTitle}`]: {
    flexGrow: 1,
    minWidth: "10rem",
  },

  [`& .${classes.contentAction}`]: {
    flexShrink: 0,
  },
}));

const CustomWidgetSelector: Field = function ({ onChange, formData, schema }: FieldProps) {
  const { type, id, classNames }: Widget = formData;

  const [openSelector, setOpenSelector] = useState(false);

  const handleChange = (dataName: string, value: any) => {
    onChange({ ...formData, [dataName]: value });
  };

  const handleSelect = ({ type, id }: { type: Type; id: string }) => {
    onChange({ ...formData, type, id });
    setOpenSelector(false);
  };

  const { t } = useTranslation();

  // Get whole config
  const { initConfig } = useInitConfig();
  // Filter by widgets by sections only
  const widgetsBySections: JsonConfig[] =
    initConfig?.config?.jsonConfig?.filter((jc) => jc.configSection.includes("components/")) ?? [];
  // Inject dummyWidget
  widgetsBySections.push(dummyWidgetSheet);
  widgetsBySections.push(dummyWidgetAttachments);
  widgetsBySections.push(dummyWidgetCustom);

  const hasWidget =
    !!type &&
    !!id &&
    widgetsBySections.find(
      (wbs) =>
        convertWidgetConfigSectionToWidgetType(wbs.configSection) === type &&
        (wbs.jsonData as any[]).some((w) => w.id === id),
    );

  const content = () => {
    if (hasWidget) {
      let widgetBySection: JsonConfig = widgetsBySections.find(
        (wbs) =>
          convertWidgetConfigSectionToWidgetType(wbs.configSection) === type &&
          (wbs.jsonData as any[]).some((w) => w.id === id),
      ) as JsonConfig;
      widgetBySection = {
        ...widgetBySection,
        jsonData: (widgetBySection.jsonData as any[]).filter((w) => w.id === id),
      };

      return (
        <StyledCustomWidgetSelector className={clsx("CustomWidgetSelector", classes.root)}>
          <WidgetsBySection onSelect={() => setOpenSelector(true)} {...widgetBySection} />
        </StyledCustomWidgetSelector>
      );
    }
    return (
      <StyledCustomWidgetSelector
        className={clsx("CustomWidgetSelector", "CustomWidgetSelector-empty", classes.root)}
      >
        <Box className={clsx("CustomWidgetSelecto-content", classes.content)}>
          <Box className={clsx("CustomWidgetSelecto-contentTitle", classes.contentTitle)}>
            <Typography variant="subtitle2">
              {t("admin:uiWidget.custoWidgetSelector.title.label")}
            </Typography>
            <Typography variant="body2">
              {t("admin:uiWidget.custoWidgetSelector.description.label")}
            </Typography>
          </Box>
          <Box className={clsx("CustomWidgetSelector-contentAction", classes.contentAction)}>
            <KButton variant="contained" onClick={() => setOpenSelector(true)}>
              {t("admin:uiWidget.custoWidgetSelector.actionSelect.label")}
            </KButton>
          </Box>
        </Box>
      </StyledCustomWidgetSelector>
    );
  };

  return (
    <>
      {content()}
      <ModalWidgetSelector
        open={openSelector}
        onClose={() => setOpenSelector(false)}
        onSelect={handleSelect}
      />
      <ClassNamesWidgetSelector
        classNames={classNames}
        onChange={(value) => handleChange("classNames", value)}
        label={(schema?.properties?.classNames as any)?.title ?? ""}
      />
    </>
  );
};

export default CustomWidgetSelector;
