import { useRecoilState } from "recoil";

import { CurrentEditKey, frontEditCurrentEditKeysState } from "../atoms/frontEditCurrentEditKeys";
import { frontEditModalState } from "../atoms/frontEditModal";

type UseFrontEditModal = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setCurrentEditKeys: (keys: CurrentEditKey[]) => void;
  currentEditKeys: CurrentEditKey[];
};

export default function useFrontEditModal(): UseFrontEditModal {
  const [isOpen, setIsOpen] = useRecoilState(frontEditModalState);
  const [currentEditKeys, setCurrentEditKeys] = useRecoilState(frontEditCurrentEditKeysState);

  return {
    isOpen,
    setIsOpen,
    setCurrentEditKeys,
    currentEditKeys,
  };
}
