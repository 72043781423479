import React from "react";

import { Alert } from "@mui/material";
import { FieldProps, Field } from "@rjsf/utils";
import { useTranslation } from "react-i18next";

const CustomHideLabelField: Field = function (props: FieldProps) {
  const { t } = useTranslation("admin");
  if (props.formData) {
    return <Alert severity="info">{t("labels.empty.admin.label")}</Alert>;
  }
  return <></>;
};

export default CustomHideLabelField;
