import { useEffect } from "react";

import { Loadable } from "recoil";

import logger from "../lib/logger-utils";

export function useLoadableErrorLogger(loadable: Loadable<any>, loadableName?: string) {
  const name = `: ${loadableName}`;
  useEffect(() => {
    if (loadable.state === "hasError") {
      logger.error(`error during loadable${loadableName ? name : ""}`, loadable.contents);
    }
  }, [loadable.contents, loadable.state, loadableName, name]);
}
