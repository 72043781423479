import { useMemo } from "react";

import { useRecoilValueLoadable } from "recoil";

import { PublicRuntimeConfig } from "../../../lib/config/publicRuntimeConfigUtils";
import { publicRuntimeConfigSelector } from "../selectors/publicRuntimeConfig";

export type UsePublicRuntimeConfiguration = {
  loading: boolean;
  config: PublicRuntimeConfig | undefined;
};
export const usePublicRuntimeConfig = (): UsePublicRuntimeConfiguration => {
  const publicRuntimeConfigQuery = useRecoilValueLoadable(publicRuntimeConfigSelector);
  const config = useMemo(() => {
    if (publicRuntimeConfigQuery.state === "hasValue") {
      return publicRuntimeConfigQuery.contents;
    }
  }, [publicRuntimeConfigQuery.contents, publicRuntimeConfigQuery.state]);
  return {
    loading: publicRuntimeConfigQuery.state === "loading",
    config,
  };
};
