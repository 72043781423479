import { useMemo } from "react";

import { useRouter } from "next/router";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { Global } from "../../../models/configuration/global";
import { useInitConfig } from "./init";

import { useConfiguration } from "./index";

/**
 * Allows you to obtain the list of interface languages configured
 * on this refront (taking into account the configuration of the refront admin and/or the customer)
 */
export const useUILocals = (): Array<string> => {
  const { locales } = useRouter();
  const { loading } = useInitConfig();
  const global = useConfiguration<Global>(CONFIGURATION_SECTION_PATH.GLOBAL);

  // Show only locales that are configured into refront-customers and/or admin
  return useMemo(() => {
    if (!loading && !global) {
      return locales || [];
    }
    // During global configuration loading, dont show any switch to avoid flash of unwanted locales
    if (loading && !global) {
      return [];
    }
    const adminLocales = global?.pages?.locales;
    // If we have a configuration, the configuration take over in accord with Next configuration
    if (adminLocales && adminLocales.length > 0) {
      return adminLocales.filter((l) => locales?.includes(l));
    }
    // Else fallback to Next configuration
    return locales || [];
  }, [global, loading, locales]);
};
