/* eslint-disable @next/next/no-img-element */
import React from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Box } from "@mui/material";
import clsx from "clsx";

import { KVideoWidget } from "../../../../../models/configuration/components/kVideoWidget";

const WidgetCardSelectorVideo = function ({
  testId = "WidgetCardSelectorVideo",
  className,
  poster,
  posterResource,
  src,
  resource,
  loop,
  autoPlay,
  id,
}: KVideoWidget & BaseComponentProps) {
  let videoSrc = src;
  if (resource) {
    videoSrc = resource.url;
  }
  let posterSrc = poster;
  if (posterResource) {
    posterSrc = posterResource.url;
  }
  return (
    <Box
      className={clsx("WidgetCardSelectorVideo", className)}
      style={{ height: "100%", width: "100%" }}
      data-testid={testId}
      title={id}
    >
      {!!videoSrc && (
        <video
          // Need a key here to reload video component on video url change (can happen during a change from the frontedit)
          key={`${videoSrc}-${posterSrc}-${loop}-${autoPlay}`}
          src={videoSrc}
          poster={posterSrc}
          style={{ height: "100%", width: "100%" }}
          muted
          loop={loop}
          autoPlay={autoPlay}
        />
      )}
    </Box>
  );
};

export default WidgetCardSelectorVideo;
