import { MailLinkData } from "../components/MailLink";

export const convertToMailToFormat = (data: MailLinkData): string => {
  const initialMailToUrl = `mailto:${data.recipients
    ?.map((value) => encodeURIComponent(value))
    .join(",")}`;
  const mailToUrl = new URL(initialMailToUrl);
  if (data.body && data.body.trim().length > 0) {
    mailToUrl.searchParams.append("body", data.body);
  }
  if (data.subject && data.subject.trim().length > 0) {
    mailToUrl.searchParams.append("subject", data.subject);
  }
  return mailToUrl.href;
};

export const extractToMailLinkData = (hrefMailTo?: string): MailLinkData => {
  if (!hrefMailTo) {
    return {};
  }

  try {
    const url = new URL(hrefMailTo);
    return {
      ...(url.searchParams.has("body") && { body: url.searchParams.get("body") || "" }),
      ...(url.searchParams.has("subject") && { subject: url.searchParams.get("subject") || "" }),
      ...(url.pathname.trim().length > 0 && {
        recipients: url.pathname
          .trim()
          .split(",")
          .map((value) => decodeURIComponent(value)),
      }),
    };
  } catch (error) {
    console.debug("extractToMailLinkData", error);
    return {};
  }
};
