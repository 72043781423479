import { FRONT_EDIT_SIDE_BAR_TAB } from "@keepeek/refront-components";
import { atom } from "recoil";

export const frontEditCurrentRouterPageState = atom<string>({
  key: "FrontEditCurrentRouterPageState",
  default: "",
});

export const frontEditSideBarContextState = atom<FRONT_EDIT_SIDE_BAR_TAB>({
  key: "FrontEditSideBarContextState",
  default: FRONT_EDIT_SIDE_BAR_TAB.PAGES,
});
