import { BaseComponentProps } from "@keepeek/commons";
import { SanitizeHtml } from "@keepeek/refront-components";
import { Box } from "@mui/material";
import clsx from "clsx";

import { KSectionTitleWidget } from "../../../../../models/configuration/components/kSectionTitleWidget";
import { useCurrentLocale } from "../../../../../providers/i18n/hooks";

const WidgetCardSelectorSectionTitle = function ({
  testId = "WidgetCardSelectorSectionTitle",
  backgroundColor,
  content,
  className,
  id,
}: KSectionTitleWidget & BaseComponentProps) {
  const locale = useCurrentLocale();
  const translatedContent = content?.find((u) => u.language === locale)?.value;

  return (
    <Box
      className={clsx("WidgetCardSelectorNewsColumn", className)}
      sx={{
        height: "100%",
        width: "100%",
        backgroundColor,
        position: "relative",
      }}
      data-testid={testId}
      title={id}
    >
      <Box style={{ position: "relative", width: "100%" }}>
        {!!translatedContent && <SanitizeHtml html={translatedContent} />}
      </Box>
    </Box>
  );
};

export default WidgetCardSelectorSectionTitle;
