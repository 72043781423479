import { useEffect } from "react";

import { FetcherMode, fetcherModeAtom, fetcherTokenIdAtom } from "@keepeek/commons";
import { useRouter } from "next/router";
import { useSetRecoilState } from "recoil";

import { parseStringParameter } from "../../lib/query-util";

export const useFetchers = (): void => {
  const router = useRouter();

  const setFetcherModeAtom = useSetRecoilState(fetcherModeAtom);
  const setFetcherTokenIdAtom = useSetRecoilState(fetcherTokenIdAtom);

  useEffect(() => {
    if (router.pathname.startsWith("/share")) {
      setFetcherTokenIdAtom(parseStringParameter(router.query.tokenId) || "");
      setFetcherModeAtom(FetcherMode.SHARE);
    } else {
      setFetcherTokenIdAtom("");
      setFetcherModeAtom(FetcherMode.LOGGED);
    }
  }, [router.pathname, router.query.tokenId, setFetcherModeAtom, setFetcherTokenIdAtom]);
};
