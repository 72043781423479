import { getFirstWidgetFromTemplateByType } from "../../containers/widgets/utils/getWidgetFromTemplateByType";
import { Type } from "../../models/configuration/definitions/widget";
import { Search, TemplateSchema, WidgetSchema } from "../../models/configuration/pages/search";

export const getDefaultBasketHeaderTemplates = (
  searchPageConfig: Search | undefined,
): TemplateSchema => {
  const headerWidgetFromSearch: WidgetSchema | undefined = getFirstWidgetFromTemplateByType({
    template: searchPageConfig?.templates,
    widgetType: Type.Header,
  });
  // Header
  return headerWidgetFromSearch
    ? {
        columns: [
          {
            widgets: [headerWidgetFromSearch],
          },
        ],
      }
    : {};
};

export const getDefaultBasketFooterTemplates = (
  searchPageConfig: Search | undefined,
): TemplateSchema => {
  const footerWidgetFromSearch: WidgetSchema | undefined = getFirstWidgetFromTemplateByType({
    template: searchPageConfig?.templatesFooter,
    widgetType: Type.Footer,
  });
  // Footer
  return footerWidgetFromSearch
    ? {
        columns: [
          {
            widgets: [footerWidgetFromSearch],
          },
        ],
      }
    : {};
};
