import { KMenuElementPosition } from "@keepeek/refront-components";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { JsonConfig } from "../../../lib/commonPagePropsUtil";
import { getAdminConfigSectionData } from "../../../lib/config/customer-config-utils";
import logger from "../../../lib/logger-utils";
import { KMenuWidget } from "../../../models/configuration/components/kMenuWidget";
import { Type } from "../../../models/configuration/definitions/widget";

import { MenuWidgetProps } from ".";

export function getMenuConfiguration(
  customerSectionConfiguration: JsonConfig[],
  widgetId: string,
): MenuWidgetProps | undefined {
  const menus = getAdminConfigSectionData<KMenuWidget[]>(
    customerSectionConfiguration,
    CONFIGURATION_SECTION_PATH.COMPONENTS_MENU_WIDGET,
  );

  if (menus?.length === undefined || menus.length === 0) {
    logger.debug(`getMenuConfiguration - menus is empty or undefined`);
    return;
  }

  const menu = menus?.find((m) => m.id === widgetId);
  if (!menu) {
    logger.debug(`getMenuConfiguration - can't find menu widget id ${widgetId}`);
    return;
  }

  return {
    widgetId: menu.id,
    linksByLocale: menu.linksByLocale || [],
    container: menu.container,
    backgroundColor: menu.backgroundColor,
    borderColor: menu.borderColor,
    color: menu.color,
    menuColorOnHover: menu.menuColorOnHover,
    menuHeight: menu.menuHeight,
    linksPosition:
      (menu.linksPosition as unknown as KMenuElementPosition) || KMenuElementPosition.CENTER,
    horizontalLinksPadding: menu.horizontalLinksPadding,
    linksSize: menu.linksSize,
    type: Type.Menu,
  };
}
