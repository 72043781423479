import React, { ReactNode, useCallback } from "react";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import WidgetCard, { WidgetCardProps } from "../../../containers/FrontEdit/WidgetCard";
import { EditoWidgetProps } from "../../../containers/widgets/EditoWidget";
import { KFooterWidgetProps } from "../../../containers/widgets/KFooter";
import { KHeaderWidgetProps } from "../../../containers/widgets/KHeaderWidget";
import { KImageWidgetProps } from "../../../containers/widgets/KImageWidget";
import { MenuWidgetProps } from "../../../containers/widgets/KMenuWidget";
import { KMosaicWidgetProps } from "../../../containers/widgets/KMosaicWidget";
import { KNewsWidgetProps } from "../../../containers/widgets/KNewsWidget";
import { ReportWidgetProps } from "../../../containers/widgets/KReportWidget";
import { KSearchWidgetProps } from "../../../containers/widgets/KSearchWidget";
import { KSectionTitleWidgetProps } from "../../../containers/widgets/KSectionTitleWidget";
import { KVideoWidgetProps } from "../../../containers/widgets/KVideoWidget";
import { WidgetConfiguration } from "../../../containers/widgets/models";
import { getWidgetConfigurationsByTemplates } from "../../../containers/widgets/utils/getWidgetConfigurations";
import { Template, Type } from "../../../models/configuration/definitions/template";
import { useInitConfig } from "../../config/hooks/init";

type UseFrontEditWidgetList = {
  getWidgetsFromTemplate: (template: Template[]) => ReactNode[];
  getWidgetsFromTemplates: (templates: Template[][]) => ReactNode[];
};

export default function useFrontEditWidgetList(): UseFrontEditWidgetList {
  const { initConfig } = useInitConfig();

  const getWidgetsFromTemplate = useCallback(
    (templates: Template[]): ReactNode[] => {
      const widgetList: ReactNode[] = [];
      const widgetConfigurations = getWidgetConfigurationsByTemplates({
        configSections: initConfig?.config?.jsonConfig,
        templates,
      });

      for (const key in widgetConfigurations) {
        const widgetConfiguration: WidgetConfiguration = widgetConfigurations[key];
        let widgetProps: WidgetCardProps | undefined = undefined;
        if (widgetConfiguration.type === Type.Edito) {
          const config = widgetConfiguration as EditoWidgetProps;
          widgetProps = {
            widgetId: config.widgetId,
            widgetConfig: config,
            widgetType: config.type,
            configurationPath: CONFIGURATION_SECTION_PATH.COMPONENTS_EDITO_WIDGET,
          };
        }
        if (widgetConfiguration.type === Type.Footer) {
          const config = widgetConfiguration as KFooterWidgetProps;
          widgetProps = {
            widgetId: config.widgetId,
            widgetConfig: config,
            widgetType: config.type,
            configurationPath: CONFIGURATION_SECTION_PATH.COMPONENTS_FOOTER_WIDGET,
          };
        }
        if (widgetConfiguration.type === Type.Image) {
          const config = widgetConfiguration as KImageWidgetProps;
          widgetProps = {
            widgetId: config.widgetId,
            widgetConfig: config,
            widgetType: config.type,
            configurationPath: CONFIGURATION_SECTION_PATH.COMPONENTS_K_IMAGE_WIDGET,
          };
        }
        if (widgetConfiguration.type === Type.Video) {
          const config = widgetConfiguration as KVideoWidgetProps;
          widgetProps = {
            widgetId: config.widgetId,
            widgetConfig: config,
            widgetType: config.type,
            configurationPath: CONFIGURATION_SECTION_PATH.COMPONENTS_K_VIDEO_WIDGET,
          };
        }
        if (widgetConfiguration.type === Type.Mosaic) {
          const config = widgetConfiguration as KMosaicWidgetProps;
          widgetProps = {
            widgetId: config.widgetId,
            widgetConfig: config,
            widgetType: config.type,
            configurationPath: CONFIGURATION_SECTION_PATH.COMPONENTS_MOSAIC,
          };
        }
        if (widgetConfiguration.type === Type.Header) {
          const config = widgetConfiguration as KHeaderWidgetProps;
          widgetProps = {
            widgetId: config.widgetId,
            widgetConfig: config,
            widgetType: config.type,
            configurationPath: CONFIGURATION_SECTION_PATH.COMPONENTS_K_HEADER_WIDGET,
          };
        }
        if (widgetConfiguration.type === Type.Search) {
          const config = widgetConfiguration as KSearchWidgetProps;
          widgetProps = {
            widgetId: config.widgetId,
            widgetConfig: config,
            widgetType: config.type,
            configurationPath: CONFIGURATION_SECTION_PATH.COMPONENTS_SEARCH_WIDGET,
          };
        }
        if (widgetConfiguration.type === Type.News) {
          const config = widgetConfiguration as KNewsWidgetProps;
          widgetProps = {
            widgetId: config.widgetId,
            widgetConfig: config,
            widgetType: config.type,
            configurationPath: CONFIGURATION_SECTION_PATH.COMPONENTS_NEWS_WIDGET,
          };
        }
        if (widgetConfiguration.type === Type.SectionTitle) {
          const config = widgetConfiguration as KSectionTitleWidgetProps;
          widgetProps = {
            widgetId: config.widgetId,
            widgetConfig: config,
            widgetType: config.type,
            configurationPath: CONFIGURATION_SECTION_PATH.COMPONENTS_SECTION_TITLE_WIDGET,
          };
        }
        if (widgetConfiguration.type === Type.Menu) {
          const config = widgetConfiguration as MenuWidgetProps;
          const configWithId = {
            ...config,
            id: config.widgetId,
          };
          widgetProps = {
            widgetId: config.widgetId,
            widgetConfig: configWithId,
            widgetType: config.type,
            configurationPath: CONFIGURATION_SECTION_PATH.COMPONENTS_MENU_WIDGET,
          };
        }
        if (widgetConfiguration.type === Type.Report) {
          const config = widgetConfiguration as ReportWidgetProps;
          widgetProps = {
            widgetId: config.widgetId,
            widgetConfig: config,
            widgetType: config.type,
            configurationPath: CONFIGURATION_SECTION_PATH.COMPONENTS_REPORT_WIDGET,
          };
        }
        if (widgetProps) {
          widgetList.push(<WidgetCard key={widgetProps.widgetId} {...widgetProps} />);
        }
      }
      return widgetList;
    },
    [initConfig?.config?.jsonConfig],
  );

  const getWidgetsFromTemplates = (pageTemplates: Template[][]): ReactNode[] => {
    const pageWidgetList: ReactNode[] = [];
    pageTemplates.map((pageTemplate) => {
      pageWidgetList.push(...getWidgetsFromTemplate(pageTemplate));
    });
    return pageWidgetList;
  };

  return {
    getWidgetsFromTemplate,
    getWidgetsFromTemplates,
  };
}
