import React from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Icon, IconProps } from "@mui/material";
import clsx from "clsx";

export type KFaIconProps = { baseClassName?: string; iconKey: string } & IconProps &
  BaseComponentProps;

/**
 * Keepeek Font Awesome icon
 */
export const KFaIcon = function ({
  baseClassName = "fas",
  iconKey,
  className,
  testId = "KFaIcon",
  ...rest
}: KFaIconProps) {
  return (
    <Icon
      baseClassName={baseClassName}
      className={clsx("KFaIcon", className, iconKey)}
      sx={{ overflow: "unset" }}
      data-testid={testId}
      {...rest}
    />
  );
};
