import React from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Box, Chip, useTheme } from "@mui/material";
import clsx from "clsx";

import { CustomWidget } from "../../../../../models/configuration/components/customWidget";

const WidgetCardSelectorCustom = function ({
  testId = "WidgetCardSelectorCustom",
  className,
  id,
}: CustomWidget & BaseComponentProps) {
  const theme = useTheme();
  return (
    <Box
      className={clsx("WidgetCardSelectorCustom", className)}
      data-testid={testId}
      title={id}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        flexShrink: 1,
      }}
    >
      <Box
        style={{
          backgroundColor: theme.palette.primary.main,
          height: theme.spacing(5),
          width: "80%",
          borderRadius: "0 0 .25rem .25rem",
        }}
      />
      <Box
        style={{
          backgroundColor: theme.palette.grey[400],
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: theme.spacing(1),
          flexGrow: 1,
        }}
      >
        <Chip label={`id : ${id}`} />
      </Box>
      <Box
        style={{
          backgroundColor: theme.palette.primary.main,
          height: theme.spacing(5),
          width: "80%",
          borderRadius: ".25rem .25rem 0 0",
          flexShrink: 1,
        }}
      />
    </Box>
  );
};

export default WidgetCardSelectorCustom;
