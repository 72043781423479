import React, { ComponentType } from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Box, Button, IconProps } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { genericMemo, TreeEditOption } from "./types";

import { TreeEditProps } from ".";

export type TreeAddProps<T> = {
  KIcon: ComponentType<{} & IconProps>;
  onAddItem?: TreeEditProps<T>["onAddItem"];
  onAddParentItem?: TreeEditProps<T>["onAddParentItem"];
  parentItem?: TreeEditOption<T>;
  previousItemIndex?: number;
} & BaseComponentProps;

export const TreeAdd = genericMemo(function TreeAdd<T>({
  id,
  className,
  sx,
  testId = "TreeAdd",
  KIcon,
  previousItemIndex,
  parentItem,
  onAddItem,
  onAddParentItem,
}: React.PropsWithChildren<TreeAddProps<T>>) {
  const { t } = useTranslation("refront-components");
  return (
    <Box
      className={clsx("TreeAdd", className)}
      id={id}
      data-testid={testId}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: "10px",
        mb: "10px",
        ":hover": {
          cursor: "pointer",
          ".ActionButtons": { display: "flex" },
          ".fa-square-plus": {
            display: "none",
          },
        },
        ...sx,
      }}
    >
      <Box
        component="hr"
        sx={{
          width: "100%",
          marginRight: (theme) => theme.spacing(1),
          border: (theme) => `1px solid ${theme.palette.primary.light}`,
        }}
      />
      <KIcon
        sx={{ color: (theme) => theme.palette.primary.light }}
        fontSize="small"
        baseClassName="fas"
        className="fa-square-plus"
      />
      <Box className="ActionButtons" sx={{ display: "none", height: "20px" }}>
        {onAddItem && (
          <Button
            variant="outlined"
            onClick={() => {
              parentItem
                ? onAddItem(parseInt(parentItem.id), previousItemIndex)
                : onAddItem(previousItemIndex, undefined);
            }}
          >
            {t("components.field.tree-edit.action.add.filter.label")}
          </Button>
        )}
        {onAddParentItem && (
          <Button
            variant="outlined"
            onClick={() => {
              parentItem
                ? onAddParentItem(parseInt(parentItem.id))
                : onAddParentItem(previousItemIndex);
            }}
          >
            {t("components.field.tree-edit.action.add.section.label")}
          </Button>
        )}
      </Box>
      <Box
        component="hr"
        sx={{
          width: "100%",
          marginLeft: (theme) => theme.spacing(1),
          border: (theme) => `1px solid ${theme.palette.primary.light}`,
        }}
      />
      <hr />
    </Box>
  );
});
