import { KeycloakConfig } from "keycloak-js";
import { useRecoilValueLoadable } from "recoil";

import { keycloakConfigSelector } from "../selectors/keycloakConfig";

type UseKeycloakConfig = {
  loading: boolean;
  keycloakConfig: KeycloakConfig | undefined;
};

export const useKeycloakConfig = (): UseKeycloakConfig => {
  const keycloakConfigQuery = useRecoilValueLoadable(keycloakConfigSelector);
  const loading = keycloakConfigQuery.state === "loading";
  const keycloakConfig =
    keycloakConfigQuery.state === "hasValue" ? keycloakConfigQuery.contents : undefined;
  return {
    loading,
    keycloakConfig,
  };
};
