import React, { useState } from "react";

import { SelectChangeEvent, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Field, FieldProps } from "@rjsf/utils";
import { useTranslation } from "react-i18next";

import CustomSelectResource from "../CustomSelectResource";

export enum UploadType {
  File = "FILE",
  Url = "URL",
}

const CustomFontUploadField: Field = function (props: FieldProps) {
  const { t } = useTranslation("admin");
  const [uploadType, setUploadType] = useState<UploadType | null>(
    props.formData?.type ? (props.formData.type as UploadType) : UploadType.File,
  );

  const { ObjectField } = props.registry.fields;

  const handleOnChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<any>,
  ) => {
    props.onChange({ ...event, type: uploadType });
  };

  return (
    <>
      <ToggleButtonGroup
        sx={{
          marginBottom: (theme) => theme.spacing(2),
          alignSelf: "center",
          alignItems: "center",
          ".MuiToggleButton-root": {
            backgroundColor: (theme) => `${theme.palette.grey[200]} !important`,
            color: (theme) => `${theme.palette.primary.main} !important`,
          },
          ".Mui-selected": {
            backgroundColor: (theme) => `${theme.palette.primary.main} !important`,
            color: (theme) => `${theme.palette.common.white} !important`,
          },
        }}
        color="primary"
        value={uploadType}
        exclusive
        onChange={(_event, value) => {
          setUploadType(value);
        }}
      >
        <ToggleButton
          value={UploadType.File}
          sx={{ borderRadius: "4px !important", marginRight: (theme) => theme.spacing(1) }}
        >
          {t("theme.fonts.resources.fontResource.file.label")}
        </ToggleButton>
        <p>{t("theme.fonts.resources.fontResource.or.label")}</p>
        <ToggleButton
          value={UploadType.Url}
          sx={{
            borderRadius: "4px !important",
            borderLeft: (theme) => `1px solid ${theme.palette.grey[300]} !important`,
            marginLeft: (theme) => `${theme.spacing(1)} !important`,
          }}
        >
          {t("theme.fonts.resources.fontResource.url.label")}
        </ToggleButton>
      </ToggleButtonGroup>
      {uploadType === UploadType.File && (
        <CustomSelectResource {...props} onChange={(event) => handleOnChange(event)} />
      )}
      {uploadType === UploadType.Url && (
        <ObjectField {...props} onChange={(event) => handleOnChange(event)} />
      )}
    </>
  );
};

export default CustomFontUploadField;
