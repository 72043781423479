import { translationNamespaces } from "@keepeek/refront-components";
import i18next, { i18n } from "i18next";
import Backend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
// @ts-ignore : webpack alias. See "next.config.js"
import backend from "I18nextBackend";
import { initReactI18next } from "react-i18next";

import { BUILD_TIMESTAMP } from "../../../build-timestamp";
import logger from "../../../lib/logger-utils";

export type Translations = { [key: string]: string };
// this cookie is used to save selected locale from LanguageSwitcher component
// (see https://nextjs.org/docs/advanced-features/i18n-routing#leveraging-the-next_locale-cookie)
export const LOCALE_COOKIE_NAME = "NEXT_LOCALE";

// Whatever we do, start initializing immediately.
const instance = i18next.createInstance();
export const I18NEXT_SESSION_STORAGE_PREFIX_CACHE = "i18next_res_";
const instanceInitialization = instance
  .use(initReactI18next)
  .use(Backend)
  .init(
    {
      backend: {
        backends: [
          LocalStorageBackend, // primary backend
          backend, // fallback backend
        ],
        backendOptions: [
          {
            /* options for primary backend */
            /**
             * it is based on the build timestamp and not the version in order to ensure that the cache
             * is invalidated with each new build.
             * Use case: the integration needs a version of the refront-core 1.2.3
             * and has override a translation in the refront-customer
             * => the core version remains in 1.2.3 and the cache must be invalidated to get translation from front-customer.
             */
            defaultVersion: BUILD_TIMESTAMP,
            prefix: I18NEXT_SESSION_STORAGE_PREFIX_CACHE,
            store: typeof window !== "undefined" ? window.sessionStorage : null,
          },
          {
            /* options for secondary backend */
          },
        ],
      },
      fallbackLng: false,
      ns: ["common", "admin", ...translationNamespaces],
      defaultNS: "common",
      keySeparator: false, // We use the json flat format for translations to make it easier to override by customers.
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: false,
      },
    },
    () => logger.info("i18n initialized"),
  );

/**
 * Returns a promise that resolves to the i18next instance once it's initialized and the given language is loaded.
 */
export async function getInstance(language: string): Promise<i18n> {
  if (!instance.isInitialized) {
    await instanceInitialization;
  }

  await instance.changeLanguage(language, () => {
    logger.info("i18n locale loaded", language);
  });

  return instance;
}

/**
 * Get the i18next instance immediately, even if it's not initialized
 */
export function getInstanceImmediate(): i18n {
  return instance;
}
