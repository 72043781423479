/* eslint-disable @next/next/no-img-element */
import React from "react";

import { BaseComponentProps, SanitizeHtml } from "@keepeek/refront-components";
import { Box, Chip, Icon, useTheme } from "@mui/material";
import clsx from "clsx";

import { SearchWidget } from "../../../../../models/configuration/components/searchWidget";
import { useCurrentLocale } from "../../../../../providers/i18n/hooks";

const WidgetCardSelectorSearch = function ({
  testId = "WidgetCardSelectorSearch",
  backgroundColor,
  backgroundUrl,
  backgroundUrlResource,
  showBackgroundUrl,
  content,
  className,
  fields,
  id,
}: SearchWidget & BaseComponentProps) {
  const locale = useCurrentLocale();
  const title = content?.find((u) => u.language === locale)?.value;
  const theme = useTheme();
  let bgSrc = backgroundUrl;
  if (backgroundUrlResource) {
    bgSrc = backgroundUrlResource.url;
  }
  return (
    <Box
      className={clsx("WidgetCardSelectorSearch", className)}
      style={{
        height: "100%",
        width: "100%",
        backgroundColor,
        position: "relative",
        padding: theme.spacing(1),
        display: "flex",
        alignItems: "center",
      }}
      data-testid={testId}
      title={id}
    >
      {!!bgSrc && showBackgroundUrl && (
        <img
          src={bgSrc}
          alt="image"
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        />
      )}
      <Box style={{ position: "relative", zIndex: 2, width: "100%" }}>
        {!!title && <SanitizeHtml html={title} />}
        <Box
          style={{
            backgroundColor: "white",
            borderRadius: theme.shape.borderRadius,
            border: "1px solid",
            borderColor: theme.palette.divider,
            minHeight: theme.spacing(4),
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Chip size="small" label={`Fields: ${fields?.length ?? 1}`} />
          <Icon baseClassName="fas" className="fa-magnifying-glass" />
        </Box>
        {!!fields && (
          <Box
            style={{
              marginTop: theme.spacing(1),
              display: "flex",
              flexWrap: "wrap",
              gap: theme.spacing(1),
            }}
          >
            {fields.map((f) => (
              <Chip size="small" key={f} label={f} />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WidgetCardSelectorSearch;
