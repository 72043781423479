import React, { FC } from "react";

import { useRecoilValue } from "recoil";

import { frontEditState } from "../../providers/frontEdit/atoms/frontEdit";
import FrontEditLayout from "./FrontEditLayout";

/**
 * Wrapper arround front edit to avoid extra renders.
 */
const FrontEdit: FC<React.PropsWithChildren<unknown>> = function ({ children }) {
  const frontEdit = useRecoilValue(frontEditState);

  if (frontEdit) {
    return <FrontEditLayout>{children}</FrontEditLayout>;
  }
  return <>{children}</>;
};

export default FrontEdit;
