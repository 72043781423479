import { atom } from "recoil";

export const statsSearchValueForTextAtom = atom<string | undefined>({
  key: "StatsSearchValueForTextAtom",
  default: undefined,
});

export const statsFolderIdForFolderHit = atom<number | undefined>({
  key: "statsFolderIdForFolderHit",
  default: undefined,
});
