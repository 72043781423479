import { useEffect } from "react";

import { useCurrentUser } from "@keepeek/commons";
import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";

import { usePublicRuntimeConfig } from "../../config/hooks/usePublicRuntimeConfig";
import { useCurrentLocale } from "../../i18n/hooks";
import { useDidomi } from "../didomi/hooks";
import { DidomiVendorId, isVendorAllowed } from "../didomi/utils";

export const useSentry = () => {
  const { config, loading } = usePublicRuntimeConfig();
  // Check if we can activate Sentry from Didomi feedback
  const { CWT, didomi } = useDidomi();

  useEffect(() => {
    if (!(loading || !config)) {
      Sentry.setTags({ environmentColor: config.environment });
    }
  }, [config, loading]);

  // Track router
  const router = useRouter();
  useEffect(() => {
    Sentry.addBreadcrumb({
      category: "pathname",
      message: router.pathname,
      level: "info",
      data: router,
    });
  }, [router]);

  // Track locale
  const locale = useCurrentLocale();
  useEffect(() => {
    Sentry.addBreadcrumb({
      category: "locale",
      message: locale,
      level: "info",
    });
  }, [locale]);

  // Track current user
  const { currentUser } = useCurrentUser();
  useEffect(() => {
    // Be sure to track the user only when we have consent #doublecheck
    if (currentUser.id && isVendorAllowed(didomi, DidomiVendorId.Sentry)) {
      // fix(RF-2482): send only useful information to sentry (user id)
      const sentryUser: Sentry.User = {
        id: `${currentUser.id}`,
      };
      Sentry.setUser(sentryUser);
    } else {
      // Flush user when not authenticated
      Sentry.configureScope((scope) => scope.setUser(null));
    }
    // Listen to CWT because it will reload the check when the user give consent or remove it
  }, [currentUser, CWT, didomi]);
};
