import { Template } from "../../../models/configuration/definitions/template";
import { Widget } from "../../../models/configuration/definitions/widget";

export function getWidgetsByTemplates(templates: Template[] | undefined): Widget[] {
  const ret: Widget[] = [];
  if (!templates) {
    return ret;
  }
  templates.forEach((template) => {
    template.columns?.forEach((col) => {
      col.widgets?.forEach((widget) => ret.push(widget));
    });
  });
  return ret;
}
