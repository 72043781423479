import { createRef, RefObject } from "react";

import { FrontEditPageSection } from "@keepeek/refront-components";
import Form from "@rjsf/core";
import { RJSFSchema } from "@rjsf/utils";

import FrontEditEditionForm from "../../../containers/FrontEdit/FrontEditEditionForm";
import { CurrentEditKey } from "../atoms/frontEditCurrentEditKeys";

type UseFrontEditContentList = {
  contentFromAdminSections: FrontEditPageSection[];
};

export const useFrontEditContentList = ({
  keys,
}: {
  keys: CurrentEditKey[];
}): UseFrontEditContentList => {
  const formRef: RefObject<Form<any, RJSFSchema, any>> = createRef();
  const contentFromAdminSections: FrontEditPageSection[] = [
    {
      editableContents: [
        <FrontEditEditionForm key="1" keys={keys} displayButton={false} formRef={formRef} />,
      ],
    },
  ];
  return {
    contentFromAdminSections,
  };
};
