import { Theme } from "@mui/material";

export const useThemeTemplates = (adminTheme: Theme) => {
  const themeTemplates = {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          // -- GLOBAL OVERRIDES --
          // CSS Overrides that impact all templates and doesn't need to specify a specific css class

          // Commented for now because it cause visual regression on existing refront
          /* ".KButton:hover": {
            backgroundColor: adminTheme.palette.action.hover
              ? `${adminTheme.palette.action.hover} !important`
              : "inherit",
          }, */

          // -- SEARCH WIDGETS --
          // Widget with color
          ".search-color": {
            "& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot": {
              paddingTop: 0,
              paddingBottom: 0,
              paddingRight: adminTheme.spacing(0.4),
              paddingLeft: adminTheme.spacing(0.4),
            },
            "& fieldset": {
              borderColor: adminTheme.palette.primary.main,
            },
            "& .KSplitButton": {
              paddingTop: adminTheme.spacing(1),
              paddingBottom: adminTheme.spacing(1),
              "& button": {
                color: adminTheme.palette.primary.main,
                textTransform: "capitalize",
                fontWeight: "bold",
                fontSize: adminTheme.spacing(2),
              },
            },
            "& .KSearchInput-serchTextField .MuiOutlinedInput-input.MuiAutocomplete-input": {
              padding: adminTheme.spacing(2, 0.5, 2, 0.75),
              borderColor: adminTheme.palette.primary.main,
            },
            "& .KSearchInput .MuiInputAdornment-root button": {
              backgroundColor: adminTheme.palette.primary.main,
              color: "#FFFFFF",
              borderColor: adminTheme.palette.primary.main,
              "&:hover": {
                filter: "brightness(85%)",
              },
            },
          },
          // Widget with no color
          ".search-no-color": {
            "& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot": {
              paddingTop: 0,
              paddingBottom: 0,
            },
            "& fieldset": {
              borderColor: adminTheme.palette.primary.main,
            },
            "& .KSplitButton": {
              paddingTop: adminTheme.spacing(1),
              paddingBottom: adminTheme.spacing(1),
              "& button": {
                color: adminTheme.palette.primary.main,
                textTransform: "capitalize",
                fontWeight: "bold",
                fontSize: adminTheme.spacing(2),
              },
            },
            "& .KSearchInput-serchTextField .MuiOutlinedInput-input.MuiAutocomplete-input": {
              padding: adminTheme.spacing(2, 0.5, 2, 0.75),
              borderColor: adminTheme.palette.primary.main,
            },
            "& .KSearchInput .MuiInputAdornment-root button": {
              border: "none",
              "&:hover": {
                backgroundColor: adminTheme.palette.action.hover,
              },
            },
          },
        },
      },
    },
  };

  return {
    themeTemplates,
  };
};
