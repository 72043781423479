import { atom } from "recoil";

import { Resource } from "../types";

export const resourcesAtom = atom<{
  resources: Resource[] | undefined;
  totalCount: number | undefined;
}>({
  key: "Resources",
  default: { resources: undefined, totalCount: undefined },
});
