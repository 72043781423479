import { DataViewKey, removeUndefinedDeep } from "@keepeek/commons";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { JsonConfig } from "../../../lib/commonPagePropsUtil";
import { getAdminConfigSectionData } from "../../../lib/config/customer-config-utils";
import logger from "../../../lib/logger-utils";
import { KHeaderWidget } from "../../../models/configuration/components/kHeaderWidget";
import { Type } from "../../../models/configuration/definitions/widget";

import { KHeaderWidgetProps } from ".";

export function translateKHeaderWidgetConfiguration(
  kHeaderWidget: KHeaderWidget,
): KHeaderWidgetProps {
  // For SSR, do not return any object undefined cause it can't be used in JSON
  const config: KHeaderWidgetProps = {
    widgetId: kHeaderWidget.id,
    ...kHeaderWidget,
    type: Type.Header,
    dataViewKey: DataViewKey.SEARCH,
  };
  // Simply spread kHeaderWidget then remove id from it
  delete config.id;

  return removeUndefinedDeep(config);
}

export function getKHeaderWidgetConfiguration(
  customerSectionConfiguration: JsonConfig[],
  widgetId: KHeaderWidget["id"],
): KHeaderWidgetProps | undefined {
  const kHeaderWidgets = getAdminConfigSectionData<KHeaderWidget[]>(
    customerSectionConfiguration,
    CONFIGURATION_SECTION_PATH.COMPONENTS_K_HEADER_WIDGET,
  );

  if (kHeaderWidgets?.length === undefined || kHeaderWidgets.length === 0) {
    logger.debug(`getKHeaderWidgetConfiguration - kHeaderWidgets is empty or undefined`);
    return;
  }

  const kHeaderWidget: KHeaderWidget | undefined = kHeaderWidgets?.find(
    (khw) => khw.id === widgetId,
  );

  if (!kHeaderWidget) {
    logger.debug(`getKHeaderWidgetConfiguration - can't find kHeaderWidget id ${widgetId}`);
    return;
  }

  return translateKHeaderWidgetConfiguration(kHeaderWidget);
}
