import { PageName, PagesPath } from "../../../containers/App/utils";

export const mapPathNameToPageName = (pathName: string): PageName => {
  if (pathName.includes(`${PagesPath.BASKETS}/`)) {
    return PageName.BASKET_ELEMENT_PAGE;
  }
  switch (pathName) {
    case PagesPath.HOME_PAGE:
      return PageName.HOME_PAGE;
    case PagesPath.SEARCH_PAGE:
      return PageName.SEARCH_PAGE;
    case PagesPath.LANDING_PAGE:
      return PageName.LANDING_PAGE;
    case PagesPath.ELEMENT_PAGE:
      return PageName.ELEMENT_PAGE;
    case PagesPath.SHARE_SEARCH_PAGE:
      return PageName.SHARE_SEARCH_PAGE;
    case PagesPath.SHARE_ELEMENT_PAGE:
      return PageName.SHARE_ELEMENT_PAGE;
    case PagesPath.BASKETS:
      return PageName.BASKETS;
    default:
      return PageName.HOME_PAGE;
  }
};
