import { Popper } from "@mui/material";
import { styled } from "@mui/material/styles";

import { BusinessFilterFilter } from "../../../../../models/configuration/components/businessFilter";

export const POPPER_WIDTH = 450;
export const StyledPopper = styled(Popper, {
  shouldForwardProp: (prop) => prop !== "businessFilterFilter",
})<{
  businessFilterFilter?: BusinessFilterFilter;
}>(({ businessFilterFilter, theme }) => ({
  // due to an initial rendering problem
  // which does not position the popper
  // correctly the first time, we are forced
  // to keep the popper in the DOM and hide it with "display:none :
  ...(!businessFilterFilter && { display: "none" }),

  width: `${POPPER_WIDTH}px`,
  backgroundColor: theme.palette.common.white,
  zIndex: 1,
  '&[data-popper-placement="right"] .icon': {
    left: 0,
    marginLeft: "-1.35em",
    "& .MuiIcon-root": {
      color: theme.palette.primary.light,
      fontSize: "30px",
    },
  },
}));
