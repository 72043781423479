/* eslint-disable @next/next/no-img-element */
import React from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Box } from "@mui/material";
import clsx from "clsx";

import { KImageWidgetProps } from "../../../../../containers/widgets/KImageWidget";
import { KImageWidget } from "../../../../../models/configuration/components/kImageWidget";
import { useCurrentLocale } from "../../../../../providers/i18n/hooks";

const WidgetCardSelectorImage = function ({
  testId = "WidgetCardSelectorImage",
  backgroundColor,
  className,
  kImageWidgetimage,
  image,
  id,
}: KImageWidget & BaseComponentProps & Pick<KImageWidgetProps, "image">) {
  const locale = useCurrentLocale();
  const imageConfig = kImageWidgetimage ? kImageWidgetimage : image;
  const srcByLocale = imageConfig?.url?.find((u) => u.language === locale);
  let src = srcByLocale?.value;
  if (srcByLocale?.resource) {
    src = srcByLocale?.resource.url;
  }
  const altByLocale = imageConfig?.alt?.find((u) => u.language === locale);
  const alt = altByLocale?.value;
  return (
    <Box
      className={clsx("WidgetCardSelectorImage", className)}
      style={{ height: "100%", width: "100%", backgroundColor }}
      data-testid={testId}
      title={id}
    >
      {!!src && (
        <img src={src} alt={alt} style={{ height: "100%", width: "100%", objectFit: "cover" }} />
      )}
    </Box>
  );
};

export default WidgetCardSelectorImage;
