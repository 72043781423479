export interface Theme {
    action?:             Action;
    assets?:             Asset[];
    background?:         Background;
    borderRadius?:       number;
    error?:              Error;
    extendThemeOptions?: string;
    favicon?:            Favicon;
    fonts?:              Fonts;
    logoLoadingAuth?:    Favicon;
    logoMain?:           Favicon;
    primary?:            Primary;
    secondary?:          Secondary;
    text?:               Text;
}

export interface Action {
    hover?: string;
}

export interface Asset {
    key?:          Key;
    values?:       string[];
    keyCustom?:    string;
    valuesCustom?: string[];
}

export enum Key {
    Font = "FONT",
    ImageBackgroundLogin = "IMAGE_BACKGROUND_LOGIN",
    ImageBackgroundSearchWidget = "IMAGE_BACKGROUND_SEARCH_WIDGET",
    ImageFavicon = "IMAGE_FAVICON",
    ImageLogoLogin = "IMAGE_LOGO_LOGIN",
    ImageLogoMain = "IMAGE_LOGO_MAIN",
}

export interface Background {
    default?: string;
    paper?:   string;
}

export interface Error {
    contrastText?: string;
    dark?:         string;
    light?:        string;
    main?:         string;
}

export interface Favicon {
    id?:  string;
    url?: string;
}

export interface Fonts {
    assignment?: Assignment;
    resources?:  Resource[];
}

export interface Assignment {
    global?: string;
    h1?:     string;
    h2?:     string;
    h3?:     string;
    h4?:     string;
    h5?:     string;
    h6?:     string;
}

export interface Resource {
    fontResource?: FontResource;
    name:          string;
}

export interface FontResource {
    id?:   string;
    type?: string;
    url?:  string;
}

export interface Primary {
    contrastText?: string;
    dark?:         string;
    light?:        string;
    main?:         string;
}

export interface Secondary {
    contrastText?: string;
    dark?:         string;
    light?:        string;
    main?:         string;
}

export interface Text {
    disabled?:  string;
    hint?:      string;
    primary?:   string;
    secondary?: string;
}
