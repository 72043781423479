import {
  translationLanguages,
  translationNamespaces as componentTranslationNamespaces,
} from "@keepeek/refront-components";
import { selectorFamily } from "recoil";

import { Translations } from "../../../lib/i18n/i18next/i18next-instance";
import { Namespace } from "../../../models/configuration/labels";

export const getOriginalTranslationByLanguage = selectorFamily<
  Translations,
  { lng: string; namespace?: string }
>({
  key: "GetOriginalTranslationByLanguage",
  get:
    ({ lng, namespace }) =>
    async () => {
      const np = namespace ? namespace : Namespace.Common;

      let originalTranslation: Translations;

      // If the namespace is from the components library, get the translations from the components package
      if (componentTranslationNamespaces.includes(np)) {
        originalTranslation = translationLanguages[lng][np] as Translations;
      } else {
        originalTranslation = await import(`../../../../public/locales/${lng}/${np}.json`);
      }

      return originalTranslation;
    },
});
