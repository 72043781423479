import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import { CONFIGURATION_SECTION_PATH } from "../../components/layout/adminMenu";
import { Key, Theme } from "../../models/configuration/theme";
import { useConfiguration } from "../config/hooks";
import { configSectionThemeAssetSelector } from "../config/selectors";
import { frontEditState } from "../frontEdit/atoms/frontEdit";
import { getCurrentContentEditsSelector } from "../frontEdit/selectors/currentContentEdits";
import { getFaviconFormat } from "./utils";

export const useFavicon = () => {
  const deprecatedFaviconQuery = useRecoilValueLoadable(
    configSectionThemeAssetSelector(Key.ImageFavicon),
  );
  const deprecatedFavicon =
    deprecatedFaviconQuery.state === "hasValue" ? deprecatedFaviconQuery.contents : null;
  const configTheme = useConfiguration<Theme>(CONFIGURATION_SECTION_PATH.THEME);
  const isFrontEdit = useRecoilValue(frontEditState);
  const frontEditData = useRecoilValue(
    getCurrentContentEditsSelector({
      endpoint: `${CONFIGURATION_SECTION_PATH.THEME}`,
    }),
  );
  const frontEditFavicon = frontEditData?.data?.favicon?.url;
  let databaseFavicon: string | undefined;
  // first, check in deprecated configuration :
  if (deprecatedFavicon && deprecatedFavicon.length > 0 && deprecatedFavicon.length[0]) {
    databaseFavicon = deprecatedFavicon[0];
  } else if (configTheme && configTheme.favicon && configTheme.favicon.url) {
    // not found in deprecated => check in new configuration :
    databaseFavicon = configTheme.favicon.url;
  }
  let favicon32Src: string | undefined;
  let favicon192Src: string | undefined;
  let faviconFormat: string | undefined;
  if (isFrontEdit && frontEditFavicon) {
    // we do not use /api/favicon during front edit mode... not terrible but for now we stay like this...
    favicon32Src = frontEditFavicon;
    faviconFormat = getFaviconFormat(favicon32Src);
  } else if (databaseFavicon) {
    // we use /api/favicon and add extra param to cancel browser cache
    favicon32Src = `/api/favicon?w=32&cacheVersion=${encodeURIComponent(btoa(databaseFavicon))}`;
    favicon192Src = `/api/favicon?w=192&cacheVersion=${encodeURIComponent(btoa(databaseFavicon))}`;
    faviconFormat = getFaviconFormat(databaseFavicon);
  }
  return {
    favicon32Src,
    favicon192Src,
    faviconFormat,
  };
};
