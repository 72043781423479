import { useMemo } from "react";

import { Box, FormLabel, MenuItem, Paper, Select, TextField } from "@mui/material";
import { Widget, WidgetProps } from "@rjsf/utils";

enum CSSUnit {
  PX = "px",
  VH = "vh",
  EM = "em",
  REM = "rem",
  CH = "ch",
  PERCENT = "%",
}

const CustomCSSUnit: Widget = function ({ value, label, onChange }: WidgetProps) {
  const selectedUnit: CSSUnit = useMemo(() => {
    let selected: CSSUnit = CSSUnit.PX;
    if (!value) {
      return selected;
    }
    for (const unit of Object.values(CSSUnit)) {
      if (String(value).includes(unit)) {
        selected = unit;
      }
    }
    return selected;
  }, [value]);
  const selectedValue: number | string | null = useMemo(() => {
    let selected: number | string | null = value?.split(selectedUnit)?.[0];

    /* Conversion of string value in number
     * We do not convert if string is empty (in that case we want to save a null value)
     */
    if (typeof selected === "string" && selected !== "") {
      selected = Number(selected);
    }

    selected = Number.isNaN(selected) ? null : selected;
    return selected;
  }, [selectedUnit, value]);

  return (
    <Box>
      <FormLabel>{label}</FormLabel>
      <Paper elevation={0} sx={{ display: "flex" }}>
        <TextField
          sx={{ flexGrow: 1 }}
          type="number"
          value={selectedValue}
          onChange={({ target }) => onChange(target.value ? target.value + selectedUnit : "")}
        />
        <Select
          value={selectedUnit}
          onChange={({ target }) => onChange(selectedValue ? selectedValue + target.value : "")}
        >
          {Object.values(CSSUnit).map((value) => {
            return (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
      </Paper>
    </Box>
  );
};

export default CustomCSSUnit;
