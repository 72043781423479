import { dayjs, useCurrentUi } from "@keepeek/commons";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Widget, WidgetProps } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";

import {
  apiStoreAtom,
  ApiStoreType,
  dataChangeState,
} from "../../../../providers/admin/config/atoms";
import {
  getDefaultValueForApiStoreData,
  addNewValueInApiStoreMap,
} from "../../../../providers/admin/config/utils";
import CustomDate from "../CustomDate";

const ApiUpdateWidget: Widget = function (props: WidgetProps) {
  const { t } = useTranslation();
  const uiSchema = props.uiSchema;
  const schema = props.schema;
  const { currentUi } = useCurrentUi();
  const key = uiSchema ? `${uiSchema["api:destination"]}:${uiSchema["api:field"]}` : "";
  const defaultValue = getDefaultValueForApiStoreData(key, currentUi);

  const setDataChange = useSetRecoilState(dataChangeState);
  const [apiStore, setApiStore] = useRecoilState<ApiStoreType>(apiStoreAtom);

  if (!uiSchema) {
    return <></>;
  }
  if (schema.type === "boolean") {
    return (
      <FormControlLabel
        control={<Checkbox value={props.value} id={props.id} name={props.name} />}
        label={<Typography variant="h6">{t(props.label)}</Typography>}
      />
    );
  }
  if (schema.type === "string") {
    if (uiSchema["api:fieldtype"] && uiSchema["api:fieldtype"] === "date") {
      if (uiSchema?.["ui:readonly"] || uiSchema?.["ui:disabled"]) {
        return (
          <TextField
            label={props.label}
            value={
              dayjs(apiStore[key] ?? defaultValue).isValid()
                ? dayjs(apiStore[key] ?? defaultValue).format("DD/MM/YYYY HH:mm")
                : t("admin:ApiUpdateWidget.date.notdefined.label")
            }
            id={props.id}
            name={props.name}
            disabled={uiSchema?.["ui:disabled"] ?? false}
          />
        );
      }

      return (
        <CustomDate
          onChange={(date) => {
            addNewValueInApiStoreMap(key, date, setApiStore, setDataChange);
          }}
          value={dayjs(apiStore[key] ?? defaultValue).utc()}
          id={props.id}
          name={props.name}
          schema={schema}
          options={props.options}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          label={props.label}
          registry={props.registry}
          readonly={uiSchema?.["ui:readonly"] ?? false}
          disabled={uiSchema?.["ui:disabled"] ?? false}
          datePrecise
        />
      );
    }
  }
  return (
    <TextField
      label={props.label}
      value={apiStore[key] ?? defaultValue}
      id={props.id}
      name={props.name}
      onChange={(e) => {
        addNewValueInApiStoreMap(key, e.target.value, setApiStore, setDataChange);
      }}
    />
  );
};
export default ApiUpdateWidget;
