import { ParsedUrlQuery } from "querystring";

import { useMemo } from "react";

import { sanitizeUrl } from "@braintree/sanitize-url";
import { isServerSide, parseStringParameter, removeHash } from "@keepeek/commons";
import { useRouter } from "next/router";
import sanitize from "sanitize-html";

import { PagesPath } from "../../../containers/App/utils";
import { verifyPath } from "../utils";

const getAuthorizedQueryParams = (query: ParsedUrlQuery) =>
  Object.keys(query).reduce((acc, key) => {
    return {
      ...acc,
      [sanitizeUrl(sanitize(key))]: sanitizeUrl(sanitize(parseStringParameter(query[key]) || "")),
    };
  }, {});

const getRedirectPath = (
  isServer: boolean,
  asPath: string,
  authorizedQueryParams: string | string[][] | Record<string, string> | URLSearchParams | undefined,
  pathRedirect: string | undefined,
  pathname: string,
) => {
  if (isServer) return PagesPath.LANDING_PAGE;

  const queryParams = new URLSearchParams(authorizedQueryParams);
  if (pathRedirect) {
    return queryParams.toString() ? `${removeHash(pathRedirect)}` : pathRedirect;
  }
  if (pathname === PagesPath.LANDING_PAGE) {
    return getLandingPageRedirect(queryParams);
  }
  return queryParams.toString() ? `${removeHash(asPath)}` : asPath;
};

const getLandingPageRedirect = (queryParams: URLSearchParams) => {
  if (queryParams.toString()) {
    if (queryParams.get("path")) {
      return `${PagesPath.REDIRECT}?path=${queryParams.get("path")}`;
    }
    return `${PagesPath.REDIRECT}?${queryParams.toString()}`;
  }
  return PagesPath.REDIRECT;
};

const getRedirectUri = (
  isServer: boolean,
  locale: string | undefined,
  pathRedirect: string | undefined,
  pathname: string,
  redirectPath: string,
) => {
  if (isServer) return PagesPath.LANDING_PAGE;

  if (pathRedirect) {
    return `/${locale}/redirect?path=${encodeURIComponent(redirectPath)}`;
  }
  if (pathname === "/") {
    return getAuthRedirect(locale, redirectPath);
  }
  if (pathname === "/redirect") {
    return getAvoidRedirectUri(locale, redirectPath);
  }

  return `/${locale}/redirect?path=${encodeURIComponent(redirectPath)}`;
};

const getAuthRedirect = (locale: string | undefined, redirectPath: string) => {
  let redirectUri = `/${locale}`;
  if (redirectPath) {
    const path = redirectPath.startsWith("/") ? redirectPath.substring(1) : redirectPath;
    redirectUri += `/${path}`;
  }
  return redirectUri;
};

const getAvoidRedirectUri = (locale: any, redirectPath: string) => {
  let redirectUri = `/${locale}`;
  if (redirectPath) {
    const path = redirectPath.startsWith("/") ? redirectPath.substring(1) : redirectPath;
    redirectUri += `/${path}`;
    if (path.includes(PagesPath.REDIRECT.substring(1))) {
      return PagesPath.LANDING_PAGE;
    }
  }
  return redirectUri;
};

export function useAuthRedirectUri() {
  const { locale, pathname, asPath, query } = useRouter();
  const pathRedirect = useMemo(
    () => verifyPath(parseStringParameter(query.pathRedirect)),
    [query.pathRedirect],
  );
  const authorizedQueryParams = useMemo(() => getAuthorizedQueryParams(query), [query]);
  const redirectPath = useMemo(
    () => getRedirectPath(isServerSide(), asPath, authorizedQueryParams, pathRedirect, pathname),
    [asPath, authorizedQueryParams, pathRedirect, pathname],
  );
  const redirectUri = useMemo(
    () => getRedirectUri(isServerSide(), locale, pathRedirect, pathname, redirectPath),
    [locale, pathRedirect, pathname, redirectPath],
  );

  return {
    redirectPath: redirectPath,
    redirectUri: redirectUri,
    authorizedQueryParams,
  };
}
