export interface KMenuWidget {
    backgroundColor?:        string;
    borderColor?:            string;
    color?:                  string;
    container?:              boolean;
    horizontalLinksPadding?: string;
    id:                      string;
    linksByLocale?:          LinksByLocale[];
    linksPosition?:          LinksPosition;
    linksSize?:              string;
    menuColorOnHover?:       string;
    menuHeight?:             string;
}

export interface LinksByLocale {
    links?:  Link[];
    locale?: Locale;
}

export interface Link {
    folderId?:     number;
    href?:         string;
    label:         string;
    linkType?:     LinkType;
    openInNewTab?: boolean;
}

export enum LinkType {
    Didomi = "DIDOMI",
    Folder = "FOLDER",
    Mail = "MAIL",
    URL = "URL",
}

export enum Locale {
    De = "de",
    En = "en",
    Es = "es",
    Fr = "fr",
    Hu = "hu",
    It = "it",
    Nl = "nl",
    Pl = "pl",
    Pt = "pt",
    Ro = "ro",
}

export enum LinksPosition {
    Center = "CENTER",
    Left = "LEFT",
    Right = "RIGHT",
}
