import React, { FC, ReactNode } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

export type ApplicationExampleProps = {
  children: ReactNode;
};

const ApplicationExample: FC<ApplicationExampleProps> = function ({ children }) {
  const { t } = useTranslation("admin");
  return (
    <Box
      sx={{
        marginTop: (theme) => theme.spacing(1),
        border: (theme) => `1px solid  ${theme.palette.grey[400]}`,
        borderRadius: "4px",
        maxWidth: 560,
        padding: (theme) => theme.spacing(1),
      }}
    >
      <span>{t("theme.applicationExample.label")}</span>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          rowGap: "1rem",
          columnGap: "3rem",
          marginTop: (theme) => theme.spacing(1),
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ApplicationExample;
