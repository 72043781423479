import React from "react";

import { useThemeColors } from "@keepeek/refront-components";
import {
  Box,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Widget, WidgetProps } from "@rjsf/utils";
import { colord, extend } from "colord";
import namesPlugin from "colord/plugins/names";
import { RgbaStringColorPicker } from "react-colorful";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line jest/require-hook
extend([namesPlugin]);

export enum ColorType {
  Free = "FREE",
  Theme = "THEME",
}

/**
 * Custom Theme Color picker
 */
const CustomThemeColorPicker: Widget = function ({
  onChange,
  value,
  label,
  formContext,
}: WidgetProps) {
  const { t } = useTranslation("admin");
  const { themeColorList, convertColor } = useThemeColors();
  const convertedValue = convertColor(value);
  const colorRGB =
    convertedValue && colord(convertedValue).isValid()
      ? colord(convertedValue).toRgbString()
      : convertedValue;

  const [pickerAnchorEl, setPickerAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const SELECT_THEME_COLOR_PREVIEW_CLASS = "CustomThemeColorPicker__ColorTypeSelect__ColorPreview";

  const colorType = themeColorList.some((c) => c.value === value)
    ? ColorType.Theme
    : ColorType.Free;

  const handlePickerOpen = (event: React.MouseEvent<HTMLDivElement>) =>
    setPickerAnchorEl(event.currentTarget);
  const handlePickerClose = () => setPickerAnchorEl(null);
  const pickerOpen = Boolean(pickerAnchorEl);
  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<any>,
  ) => {
    // we do not control here what the user enters to give him time to write his color code (#FFF or red or etc...)
    // the control of the validity of the field will be carried out with the "blur" (=> handleInputBlur)
    onChange(event.target.value);
  };

  const handleColorTypeChange = (newColorType: ColorType) => {
    if (newColorType === ColorType.Theme && themeColorList && themeColorList.length > 0) {
      // try to find color from theme :
      const foundedColor = themeColorList.find((c) => c.color === convertedValue);
      onChange(foundedColor ? foundedColor.value.toString() : themeColorList[0].value.toString());
    } else {
      onChange(convertedValue);
    }
  };

  const handlePickerChange = (newColor: string) => onChange(colord(newColor).toHex());

  const handleInputBlur = () => {
    if (convertedValue && colord(convertedValue).isValid()) {
      // we must convert the color into a
      // format recognized by MUI (for example, if the user enters "orange",
      // we must convert into hex code before saving the value in the database)
      onChange(colord(convertedValue).toHex());
    } else {
      onChange("");
    }
  };

  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(1),
        width: "500px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Popover
        open={pickerOpen}
        anchorEl={pickerAnchorEl}
        onClose={handlePickerClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        disableScrollLock={formContext && formContext.isFrontEdit}
      >
        <Box
          sx={{
            margin: 2,
          }}
        >
          <RgbaStringColorPicker color={colorRGB} onChange={handlePickerChange} />
        </Box>
      </Popover>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant={"subtitle2"}>{t(label)}</Typography>
        <ToggleButtonGroup
          sx={{
            marginBottom: (theme) => theme.spacing(2),
            height: "1em",
            alignSelf: "end",
            ".Mui-selected": {
              backgroundColor: (theme) => `${theme.palette.primary.main} !important`,
              color: (theme) => `${theme.palette.common.white} !important`,
            },
          }}
          color="primary"
          value={colorType}
          exclusive
          onChange={(_event, value) => handleColorTypeChange(value)}
        >
          <ToggleButton value={ColorType.Free}>
            {t("colorSelection.colorType.free.label")}
          </ToggleButton>
          <ToggleButton value={ColorType.Theme}>
            {t("colorSelection.colorType.theme.label")}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <Box
          sx={{
            ...(convertedValue && { backgroundColor: convertedValue }),
            minWidth: 56,
            height: 56,
            mr: 0.5,
            borderRadius: 1,
            boxShadow: "rgb(50 50 93 / 11%) 0px 4px 6px, rgb(0 0 0 / 8%) 0px 1px 3px",
            border: "1px solid rgb(192 192 192)",
            cursor: "pointer",
          }}
          onClick={handlePickerOpen}
        />

        {colorType === ColorType.Free && (
          <TextField
            value={convertedValue}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            sx={{
              width: 500,
            }}
          />
        )}
        {colorType === ColorType.Theme && (
          <Select
            sx={{
              width: 500,
              // we don't want to display the color preview of the theme in the input (only in the select list)
              [`.${SELECT_THEME_COLOR_PREVIEW_CLASS}`]: {
                display: "none",
              },
            }}
            onChange={(event) => handleInputChange(event)}
            value={value}
          >
            {themeColorList.map((themeColor) => {
              if (themeColor.value !== undefined) {
                return (
                  <MenuItem key={themeColor.value.toString()} value={themeColor.value.toString()}>
                    {convertColor(themeColor.value) && (
                      <Box
                        className={SELECT_THEME_COLOR_PREVIEW_CLASS}
                        component={"span"}
                        sx={{
                          backgroundColor: convertColor(themeColor.value),
                          width: 20,
                          height: 20,
                          mr: 0.5,
                          borderRadius: 1,
                          border: "1px solid rgb(241 241 241)",
                        }}
                      />
                    )}
                    {themeColor.label}
                  </MenuItem>
                );
              }
            })}
          </Select>
        )}
      </Box>
    </Box>
  );
};

export default CustomThemeColorPicker;
