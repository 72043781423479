import path from "path";

import $RefParser from "@apidevtools/json-schema-ref-parser";
import { isClientSide, validateJSONSchema } from "@keepeek/commons";
import { UiSchema } from "@rjsf/utils";
import { Options } from "ajv";
import glob from "glob";
import { JSONSchema7Definition } from "json-schema";

import { CONFIGURATION_SECTION_PATH } from "../components/layout/adminMenu";
import logger from "./logger-utils";
import Problem from "./problems/Problem";

export const SCHEMA_KEYWORDS_TO_ADD = [
  "i18n-title",
  "i18n-default",
  "i18n-description",
  "fieldOrSection",
  "enumNames",
];
export const SCHEMA_FORMATS_TO_ADD = [
  {
    name: "data-url",
    format: (data) => {
      return data.length < 20000;
    },
  },
];

/**
 * Check for config section name
 *
 * @param section section
 * @returns corresponding json schema
 */
export async function getConfigSchema(
  section?: CONFIGURATION_SECTION_PATH,
): Promise<JSONSchema7Definition> {
  if (isClientSide()) {
    throw new Error("getConfigSchema can't be call client side");
  }
  if (section) {
    // we validate that the schema file exists on the system file (in order to guard against a malicious include)
    const filePath = `${path.resolve("./src")}/lib/schema/**/*Schema.json`;
    const files = glob.sync(filePath.replaceAll("\\", "/"), {});
    if (!files.some((f) => f.includes(`lib/schema/${section}Schema.json`))) {
      throw new Problem(
        "/problems/admin/config/config-schema-not-found",
        "Could not find the config schema you specified",
        404,
        `The schema ${section} doesn't exist or failed to load.`,
      );
    }
  }
  try {
    const jsonSchemaModule: any = await import(`../lib/schema/allRefrontConfSchema.json`);
    // need to resolve all JSON Schema reference (shared definitions) :
    if (section) {
      return (await $RefParser.dereference(
        jsonSchemaModule.items.anyOf.find((e) => e.properties.configSection.const === section)
          .properties.jsonData,
      )) as JSONSchema7Definition;
    }
    return (await $RefParser.dereference(jsonSchemaModule)) as JSONSchema7Definition;
  } catch (e) {
    if (section) {
      throw new Problem(
        "/problems/admin/config/config-schema-not-found",
        "Could not find the config schema you specified",
        404,
        `The schema ${section} doesn't exist or failed to load. ${e}`,
        e,
      );
    } else {
      throw new Problem(
        "/problems/admin/config/config-schema-not-found",
        "Could not find the config schema you specified",
        404,
        `The schema for all refront doesn't exist or failed to load. ${e}`,
        e,
      );
    }
  }
}

export enum GET_CONFIG_UI_SCHEMA_TYPE {
  FRONT_EDIT = "frontEdit",
  DEFAULT = "default",
}

/**
 * Check for config UI section name
 *
 * @param section section
 * @param type type of UISchema
 * @returns corresponding json UI schema
 */
export async function getConfigUiSchema(
  section: CONFIGURATION_SECTION_PATH,
  type?: GET_CONFIG_UI_SCHEMA_TYPE,
): Promise<UiSchema> {
  const choosenType = type || GET_CONFIG_UI_SCHEMA_TYPE.DEFAULT;
  if (isClientSide()) {
    throw new Error("getConfigSchema can't be called client side");
  }
  // we validate that the schema file exists on the system file (in order to guard against a malicious include)
  const filePath = `${path.resolve("./src")}/lib/schema/**/*UiSchema.json`;
  const files: string[] = glob.sync(filePath.replaceAll("\\", "/"), {});
  if (!files.some((f) => f.includes(`lib/schema/${section}UiSchema.json`))) {
    throw new Problem(
      "/problems/admin/config/config-schema-not-found",
      "Could not find the config schema you specified",
      404,
      `The schema ${section} doesn't exist or failed to load.`,
    );
  }
  if (choosenType === GET_CONFIG_UI_SCHEMA_TYPE.FRONT_EDIT) {
    try {
      const moduleSchema = await import(`../lib/schema/${section}FrontEditUiSchema.json`);
      return moduleSchema.default;
    } catch (e) {
      throw new Problem(
        "/problems/admin/config/config-ui-schema-not-found",
        "Could not find the config UI schema you specified",
        404,
        `The UI schema ${section} doesn't exist or failed to load.`,
        e,
      );
    }
  } else {
    try {
      const moduleSchema = await import(`../lib/schema/${section}UiSchema.json`);
      return moduleSchema.default;
    } catch (e) {
      throw new Problem(
        "/problems/admin/config/config-ui-schema-not-found",
        "Could not find the config UI schema you specified",
        404,
        `The UI schema ${section} doesn't exist or failed to load.`,
        e,
      );
    }
  }
}

/**
 * Check json data compliance with json schema
 *
 * @param section configuration section
 * @param schema json schema
 * @param body json data
 */
export function checkBody<T = any>(
  section: CONFIGURATION_SECTION_PATH,
  schema: JSONSchema7Definition,
  body: T,
  ajvOptions?: Options,
): void {
  const validateErrors = validateJSONSchema(
    schema,
    body,
    SCHEMA_KEYWORDS_TO_ADD,
    SCHEMA_FORMATS_TO_ADD,
    ajvOptions,
  );
  if (validateErrors) {
    logger.info(
      `Error while validating data for section %s and body %j error is %j`,
      section,
      body,
      validateErrors,
    );
    throw new Problem(
      "/problems/admin/config/invalid-config-data",
      "The data you sent to the server is invalid",
      400,
      "Please fix the attached errors and try again.",
      undefined,
      { errors: validateErrors, section },
    );
  }
}
