import React, { FC, useState } from "react";

import { MenuItem, Select, Typography } from "@mui/material";
import { FieldProps } from "@rjsf/utils";
import { useTranslation } from "react-i18next";

export enum MosaicMode {
  Folder = "folder",
  Hybrid = "hybrid",
}

export const CustomMosaicWrapper: FC<React.PropsWithChildren<FieldProps>> = function (props) {
  const { t } = useTranslation("admin");
  const { registry, onChange, formData } = props;

  const [mosaicMode, setMosaicMode] = useState(formData.mosaicMode ?? MosaicMode.Folder);

  const { ObjectField } = registry.fields;

  const uiSchema = props.uiSchema;
  const uiSchemaWithoutMosaicElements = {
    ...uiSchema,
    mosaicElements: { "ui:widget": "hidden" },
  };

  const uiSchemaWithoutFolderIds = {
    ...uiSchema,
    folderIds: { "ui:widget": "hidden" },
  };

  return (
    <>
      <Typography>{t("components.mosaic.type.label")}</Typography>
      <Select
        sx={{ marginBottom: (theme) => theme.spacing(2) }}
        value={mosaicMode}
        onChange={(e) => {
          setMosaicMode(e.target.value as MosaicMode);
        }}
      >
        <MenuItem value={MosaicMode.Folder}>{t("components.mosaic.type.folder.label")}</MenuItem>
        <MenuItem value={MosaicMode.Hybrid}>{t("components.mosaic.type.hybrid.label")}</MenuItem>
      </Select>
      {mosaicMode === MosaicMode.Folder && (
        <ObjectField
          {...props}
          uiSchema={uiSchemaWithoutMosaicElements}
          onChange={(e) => {
            onChange({ ...e, mosaicMode, mosaicElements: [] });
          }}
        />
      )}
      {mosaicMode === MosaicMode.Hybrid && (
        <>
          <ObjectField
            {...props}
            uiSchema={uiSchemaWithoutFolderIds}
            onChange={(e) => {
              onChange({ ...e, mosaicMode, folderIds: [] });
            }}
          />
        </>
      )}
    </>
  );
};
