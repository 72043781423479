import { KpkApiUi, updateUi, KpkApiPutUiParams } from "@keepeek/api-client";

import { getAxiosClientInstance } from "../../../lib/axios/axios-utils";
import { ApiStoreType } from "../../../providers/admin/config/atoms";

export async function updateDataApi(apiStore: ApiStoreType, currentUi?: KpkApiUi): Promise<void> {
  if (!currentUi || !currentUi.id) {
    return;
  }
  const axios = getAxiosClientInstance();
  let paramUiApi: KpkApiPutUiParams = {
    uiId: currentUi.id,
    gdprCurrentResources: currentUi.gdprCurrentResources,
    gdprUpdateDate: currentUi.gdprUpdateDate,
  };
  for (const key in apiStore) {
    if (key.includes("ui:")) {
      if (key.includes(":gdprUpdateDate")) {
        paramUiApi = { ...paramUiApi, gdprUpdateDate: new Date(apiStore[key]) };
      } else if (key.includes(":gdprCurrentResources")) {
        paramUiApi = { ...paramUiApi, gdprCurrentResources: apiStore[key] };
      }
    }
  }
  await updateUi(axios, paramUiApi);
}
