import { Widget, WidgetProps } from "@rjsf/utils";

import CustomFieldsSelectWidget from "../CustomFieldsSelectWidget";

// This widget will remove non-valid fields for plan-text search (like checkboxes)
const CustomFieldsValidForSearchSelectWidget: Widget = function ({ ...props }: WidgetProps) {
  return <CustomFieldsSelectWidget {...props} hideNonValidFieldsForPlainTextSearch={true} />;
};

export default CustomFieldsValidForSearchSelectWidget;
