import React, { FC } from "react";

import { BaseComponentProps, KpkMedia } from "@keepeek/commons";
import { Box, Typography } from "@mui/material";

import { CONFIGURATION_SECTION_PATH } from "../../components/layout/adminMenu";
import WidgetCardSelector from "../../lib/admin/react-json-schema/CustomWidgetSelector/WidgetCardSelector";
import { EditoWidget } from "../../models/configuration/components/editoWidget";
import { FooterWidget } from "../../models/configuration/components/footerWidget";
import { KHeaderWidget } from "../../models/configuration/components/kHeaderWidget";
import { KImageWidget } from "../../models/configuration/components/kImageWidget";
import { KMenuWidget } from "../../models/configuration/components/kMenuWidget";
import { KNewsWidget } from "../../models/configuration/components/kNewsWidget";
import { KReportWidget } from "../../models/configuration/components/kReportWidget";
import { KSectionTitleWidget } from "../../models/configuration/components/kSectionTitleWidget";
import { KVideoWidget } from "../../models/configuration/components/kVideoWidget";
import { Mosaic } from "../../models/configuration/components/mosaic";
import { SearchWidget } from "../../models/configuration/components/searchWidget";
import useFrontEditModal from "../../providers/frontEdit/hooks/useFrontEditModal";
import { KWidgetProps } from "../widgets/models";

export type WidgetCardProps = {
  widgetId: string;
  widgetConfig:
    | EditoWidget
    | FooterWidget
    | KImageWidget
    | KVideoWidget
    | Mosaic
    | KHeaderWidget
    | SearchWidget
    | KMenuWidget
    | KNewsWidget
    | KReportWidget
    | KSectionTitleWidget;
  widgetType: KWidgetProps["type"];
  configurationPath: CONFIGURATION_SECTION_PATH;
  element?: KpkMedia;
} & BaseComponentProps;

const WidgetCard: FC<React.PropsWithChildren<WidgetCardProps>> = function ({
  widgetId,
  widgetConfig,
  widgetType,
  configurationPath,
}) {
  const { setIsOpen, setCurrentEditKeys } = useFrontEditModal();

  if (widgetType === "NOT_A_WIDGET_YET") {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: (theme) => theme.spacing(2, 0),
      }}
    >
      <Typography
        component={"span"}
        sx={{
          textTransform: "uppercase",
          fontWeight: "bold",
          fontSize: (theme) => theme.typography.fontSize,
          color: (theme) => theme.palette.grey[600],
        }}
      >
        {widgetType}
      </Typography>
      <WidgetCardSelector
        onClick={() => {
          setCurrentEditKeys([{ widgetId, configSection: configurationPath }]);
          setIsOpen(true);
        }}
        type={widgetType}
        id={widgetId}
        ui={widgetConfig}
      />
    </Box>
  );
};

export default WidgetCard;
