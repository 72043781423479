import { removeUndefinedDeep } from "@keepeek/commons";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { JsonConfig } from "../../../lib/commonPagePropsUtil";
import { getAdminConfigSectionData } from "../../../lib/config/customer-config-utils";
import logger from "../../../lib/logger-utils";
import { SearchWidget } from "../../../models/configuration/components/searchWidget";
import { Type } from "../../../models/configuration/definitions/widget";
import { KSearchWidgetProps } from "../KSearchWidget";
export function getSearchWidgetConfiguration(
  customerSectionConfiguration: JsonConfig[],
  widgetId: string,
): KSearchWidgetProps | undefined {
  const searchWidgets = getAdminConfigSectionData<SearchWidget[]>(
    customerSectionConfiguration,
    CONFIGURATION_SECTION_PATH.COMPONENTS_SEARCH_WIDGET,
  );

  if (searchWidgets?.length === undefined || searchWidgets.length === 0) {
    logger.debug(`getSearchWidgetConfiguration - searchWidgets is empty or undefined`);
    return;
  }

  const searchWidget: SearchWidget | undefined = searchWidgets?.find((m) => m.id === widgetId);
  if (!searchWidget) {
    logger.debug(`getSearchWidgetConfiguration - can't find search widget id ${widgetId}`);
    return;
  }
  // For SSR, do not return any object undefined cause it can't be used in JSON
  const config: KSearchWidgetProps = {
    widgetId: searchWidget.id,
    height: searchWidget.height || "0",
    showBackgroundUrl: searchWidget.showBackgroundUrl || false,
    backgroundColor: searchWidget.backgroundColor || "",
    fields: searchWidget.fields || [],
    backgroundUrl: searchWidget?.backgroundUrl,
    backgroundUrlResource: searchWidget?.backgroundUrlResource,
    inputBorderRadius: searchWidget?.inputBorderRadius,
    borderRadius: searchWidget?.borderRadius,
    content: searchWidget?.content,
    autoFocus: searchWidget?.autoFocus,
    searchBarBackgroundColor: searchWidget?.searchBarBackgroundColor,
    searchBarTextColor: searchWidget?.searchBarTextColor,
    type: Type.Search,
  };
  // Include only when defined
  return removeUndefinedDeep(config);
}
