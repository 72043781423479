import { BackContext, localStorageEffect } from "@keepeek/commons";
import { atom } from "recoil";

import { initBackContextState } from "./constants";

export const backContextState = atom<BackContext>({
  key: "BackContext",
  default: initBackContextState,
  effects: [localStorageEffect("BackContext")],
});
